import React from 'react'
import { TabView, TabPanel } from 'primereact/tabview';
import { GeneralSurvey } from './GeneralSurvey'
import { Layout } from '../Layouts/Layout'

import { CreationSurvey } from './CreationSurvey';
export const Encuestas = () => {

  return (
    <Layout>
   
        <TabView>
            <TabPanel header="Mis encuestas">
                <CreationSurvey />
            </TabPanel>
            <TabPanel header="Encuestas y reportes">
                <GeneralSurvey />
            </TabPanel>
        </TabView>
    </Layout>
  )
}




          {/* Encuestas por departamento
        <TableInfinityFixed columnsParam={[]}>

        </TableInfinityFixed>
          Encuestas por grupo de personas
        <TableInfinityFixed columnsParam={[]}>

        </TableInfinityFixed>
          Encuestas por persona
        <TableInfinityFixed columnsParam={[]}>

        </TableInfinityFixed> */}