import React, { Fragment, useEffect, useState } from "react";
import { Layout } from "../Layouts/Layout";
import { TableInfinityFixed } from "../../components/TableInfinityFixed";
import { useAppContext } from "../../Context/AppContext";
import Request from "../../utils/http";
import { ModalPermisions } from "./ModalPermisions";
import { SimpleModal } from "../../components/ReactModal/SimpleModal";
import Select from "react-select";
import { ToastComponent } from "../../components/ToastComponent";
import { LoadingData } from "../../components/LoadingData";
import Modal from "react-modal";
const request = new Request();
const header = [
  { label: "Nombre de Usuario" },
  { label: "Código de Usuario" },
  { label: "Contraseña" },
  { label: "Reestablecer password" },
];
export const Permisions = () => {
  const { user, getInfo } = useAppContext();
  const [data, setData] = useState([]);
  const [usuario, setUsuario] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [message, setMessage] = useState('')
const [isopen,setisopen] = useState(false);
  const [type, setType] = useState('success')
  const [linePassword,setline] = useState(null)
 const [load, setLoad] = useState(false)
 const [isOpenPermision, setIsOpenPermisions]=useState(false)



  useEffect(() => {
    if (user == "tengo estado") {
      getInfo();
    } else {
      getData(user);
    }
  }, [user]);

  const getData = async (user) => {
    setisLoading(true);
    const response = await request.post("users/controlAccess");
    
  
    if (response && response.statusCode === 200) {
      setData(response.result.error);
    }

    setisLoading(false);
  };

  const HandleopenModal = (posicion) =>{
    setIsOpenPermisions(true)
    setUsuario(data[posicion]);
  }

  const reload = () => {
    getData();
  };
  const handleRestore = async (linea) => {
   setLoad(true)
   const response = await request.post('users/restoreCredentials', linea)
   if (response.statusCode === 200) {
     setType('success')
     setMessage('Guardado con exito!')
     
    }else{
      setType('error')
      setMessage('Guardado con exito!')
      
    }
    setLoad(false)
    getData(1)
    setTimeout(() => {
      clearToast()
    }, 2000);
  };
const clearToast = () => {
  setType('')
  setMessage('')
}
const handleClic=(linea) => {
  setisopen(true)
  setline(linea)
}
  return (
    <Layout>
      <ModalShowPassword {...{isopen,setisopen,linePassword,setline}}/>

      <ToastComponent {...{ message, type }} />
      <ModalAddUser {...{ reload }} />
      <TableInfinityFixed
        columnsParam={header}
        aditionalClass="table-striped clickeable"
      >
        {data.map((linea, i) => (
          <tr key={i}>
            <td
              onClick={(e) => HandleopenModal(i)}
              
            >
              {linea.name}
            </td>
            <td
              onClick={(e) => HandleopenModal(i)}
              
            >
              {linea.email}
            </td>
            <td  onClick={e=>handleClic(linea)} >
             ********* <i class="fa-solid fa-unlock-keyhole"></i>
            </td>
            <td className="input-group-sm">
              <button
                className="btn btn-danger"
                onClick={(e) => handleRestore(linea)}
              >
                <i className={load?'fa fa-spinner fa-spin font-15':"font-15 fa-solid fa-envelope"}></i>&nbsp;
               {load?'Espere ...' :'reestablecer'}
              </button>
            </td>
          </tr>
        ))}
        <LoadingData loading={isLoading} colspan={4} quantity={data.length} />
      </TableInfinityFixed>

      <ModalPermisions user={usuario} reload={reload} isOpenPermision={isOpenPermision} setIsOpenPermisions={setIsOpenPermisions}/>
    </Layout>
  );
};

const ModalAddUser = ({ reload }) => {
  const [colaborators, setColaborators] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('')
  const [type, setType] = useState('success')

  const [user, setUser] = useState({
    id_personal: {
      label: "Selecciona un colaborador para crear su usuario",
      value: 0,
      email: "",
    },
  });

  
  useEffect(() => {
    getInfo();
  }, []);
  const getInfo = async () => {
    try {
      const response = await request.get("information/departments/personal", [] );

      if (response && response.result.success === true) {
        let personas = [];
        response.result.datos.personal.forEach((item) => {
          personas.push({
            label: `${item.name} ${item.lastName}`,
            value: item.id,
            email: item.email,
          });
        });
        setColaborators(personas);
      }
    } catch (error) {
      console.log("getData -> error", error);
    }
  };
  const handleSubmit = async () => {
    setLoading(true);
    let data = {idPersona: user.id_personal.value,email: user.id_personal.email};
    let response = await request.post("users/createUser", data);
    if (response && response.statusCode == 200) {
      setType('success')
      setMessage('Guardado con exito!')
      setUser({ id_personal: {label: "Selecciona un colaborador para crear su usuario",value: 0,email: "" } });

    } else {
      setType('error')
      setMessage('Guardado con exito!')
    }
    reload();
    setLoading(false);
    setTimeout(() => {
      clearToast()
    }, 2000);
  };
  const handleEmailChange = (email) => {
    let copy = { ...user };
    copy.id_personal.email = email;
    setUser(copy);
  };
  const clearToast = () => {
    setType('')
    setMessage('')
  }
  return (
    <>
    <ToastComponent {...{ message, type }} />
      <div className="d-flex justify-content-end">
        <button
          className="btn bg bg-gradient-dark btn-sm"
          data-bs-toggle="modal"
          data-bs-target={`#ModalAddUser`}
        >
          <i className="fa fa-plus font-15"></i> Nuevo usuario
        </button>
      </div>

      <SimpleModal identifier="ModalAddUser" heightProp="250" size={500}>
        <div className="p-3">
          <h6 className="text-xs"> usuario de plataforma sil83.com</h6>
          <Select
            options={colaborators}
            value={user.id_personal}
            onChange={(value) => setUser({ ...user, id_personal: value })}
          />
          <label>Correo electronico</label>
          <input
            type="text"
            value={user.id_personal.email}
            onChange={(e) => handleEmailChange(e.target.value)}
            className="form-control"
            style={{ width: "450px" }}
          />

          <div className="d-flex mt-3 justify-content-end">
            <button
              className="btn btn-dark"
              disabled={loading || user.id_personal.email == ""}
              onClick={handleSubmit}
            >
              <i
                className={`fa fa-${loading ? "spin fa-spinner" : "save"}`}
              ></i>{" "}
              Guardar
            </button>
          </div>
        </div>
      </SimpleModal>
    </>
  );
};

const ModalShowPassword=({isopen,setisopen,linePassword,setline}) => {
 
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "-35%",
      width: "23%",
      height: "28%",
      transform: "translate(-50%, -50%)",
    },
  };

  return(
    <Modal style={customStyles} isOpen={isopen} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true} onRequestClose={e=>setisopen(false)} >
    <div className="d-flex justify-content-center">
     <h1>Password:</h1>
    </div>
      <input type="text" disabled className="form-control" defaultValue={linePassword !=null ?linePassword.passwordLetter:'No especificada'} />
      <small className="text-danger">No compartas esta informacion con nadie</small>

    </Modal>
  )
}