import React, { useState } from 'react'
import { ContactList } from './ContactList'
import { MessagesChat } from './MessagesChat'
import { LayoutMovile } from '../Layouts/LayoutMovile'
import { GeneralChat } from './MessagesChat/GeneralChat'
import { GroupChat } from './MessagesChat/GroupChat'
import { IndividualChat } from './MessagesChat/IndividualChat'

export const Messages = () => {
    const [idChat,setIdChat] =useState(0)
    const [chatWhith,setChatWith]=useState('')
    const [typeOfChat,setTypeOfChat] = useState(0)
    const [lastDate,setLastDate] = useState('')
    const setTypeOfChatIndividual =(type,id,participante='',lastDate='')=>{
      setTypeOfChat(type)
      setIdChat(id)
      setChatWith(participante)
      setLastDate(lastDate)
    }
  return (
     <LayoutMovile showTabs={false} coverPick={false}>

      {typeOfChat ==0 && <ContactList setTypeOfChat={setTypeOfChat} setTypeOfChatIndividual={setTypeOfChatIndividual}/>}
      {typeOfChat ==1 && <GeneralChat setTypeOfChat={setTypeOfChat} />}
      {typeOfChat ==2 && <GroupChat setTypeOfChat={setTypeOfChat} />}
      {typeOfChat ==3 && <IndividualChat setTypeOfChat={setTypeOfChat} idChat={idChat} chatWhith={chatWhith} lastDate={lastDate} />}
     
    {/* {viewChat?<MessagesChat typeOfChat={typeOfChat}  handleDetails={handleDetails} />: } */}
    </LayoutMovile>
  )
}
