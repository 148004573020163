import React, { useEffect, useState } from 'react'
import {Layout} from '../Layouts/Layout'
import { TableInfinityFixed } from '../../components/TableInfinityFixed'
import VACATIONSDAYSPERYEARS from '../../utils/vacationsDays'
import { useAppContext } from '../../Context/AppContext'
import Request from '../../utils/http'
import moment from 'moment-timezone'
import { VerticalTabs } from '../../components/VerticalTabs'
import { LoadingData } from '../../components/LoadingData'
const request = new Request()

export const VacationsDeptConfig = () => {
  const [showDetails,setShowDetails] =useState(false)
  const [dataedit,setDataEdit] = useState([])

  const handleDetails=(info) => {
    setDataEdit(info)
  }
  const renderTabs = [ 
    {
        icono:'fa fa-ship',
        titulo:'Pendientes',
        id:'Container',
        renderComponent:()=><PrincipalView {...{VACATIONSDAYSPERYEARS}} />,
    },
    {
        icono:'fa fa-ship',
        titulo:'Historial',
        id:'Container2',
        renderComponent:()=><HistoryView />,
    },
    
]

  return (
    <Layout title='Configuración de dias de vacaciones JEFE DE DEPTO'>
      <div className="card p-3">
      
        {showDetails?null:<VerticalTabs renderTabs={renderTabs} />}
      </div>

    </Layout>
  )
}

const header=[
    {label:'Años',classColumn:'text-center'},
    {label:'Dias',classColumn:'text-center'}
]
const personal=[
    {label:'Colaborador',classColumn:'text-center'},
    {label:'Inicio del periodo',classColumn:'text-center'},
    {label:'Fin del periodo',classColumn:'text-center'},
    {label:'Dia de presentación',classColumn:'text-center'},
    {label:'Estatus de aprobación',classColumn:'text-center'},
]

const PrincipalView=({VACATIONSDAYSPERYEARS})=>{
    const {user,getInfo} = useAppContext()
    const [dataUser,setDataUser]=useState({})
    const [solicitudes,setSolicitudes]=useState([])
    const [loading,setLoading]=useState(false)
  

    useEffect(() => {
      if (user=='tengo estado') {
        getInformationUser()
      }else{
        setDataUser(user)  
        getDataUser(user)  
      }
    },[user]) 
    const getInformationUser=async() => {
      let iduser= await getInfo()
     setDataUser(iduser)
      getDataUser(iduser)
    }
    
    const getDataUser=async(user) => {
      setLoading(true)
      const response = await request.get(`events/getSolicituddept/${user.id_personal}`)
      if (response && response.statusCode === 200) {
       
        setSolicitudes(response.result.data)
      }
      setLoading(false)
    }
    const handleAprobate=(value,pos)=>{
      let copy = JSON.parse(JSON.stringify(solicitudes))
      copy[pos]['areaboss']=value
      setSolicitudes(copy)
    }
    const handleSubmit=async(areaboss,id) => {

      
      const response = await request.put(`events/updateSolicituddept/${id}?areaboss=${areaboss}`)
    
      if (response && response.statusCode === 200)  {
        getDataUser(user)

      }
    }
    const handleEdit=(value,pos)=>{
      let copy = JSON.parse(JSON.stringify(solicitudes))
      copy[pos]['edit']=value
      setSolicitudes(copy)
    }
    

    return(
        <>
      
        <div className="card p-3 mt-2">
            <TableInfinityFixed columnsParam={personal} aditionalClass='table-striped ' >
                {solicitudes.map((solicitud,key)=>{
                  const {nombre,start,end,presentated_day,edit,areaboss,id} = solicitud
                  return(
                    <tr key={key}>
                      <td className='text-center'>{nombre}</td>
                      <td className='text-center'>{moment(start).format('DD/MM/YYYY')}</td>
                      <td className='text-center'>{moment(end).format('DD/MM/YYYY')}</td>
                      <td className='text-center'>{moment(presentated_day).format('DD/MM/YYYY')}</td>
                      <td className='text-center d-flex'>
                        <div>
                        <select className='form-control' 
                          style={{width:'150px'}} 
                          value={areaboss}
                          onChange={e=>handleAprobate(e.target.value,key)}
                          disabled={!edit}
                          >
                          <option value="0">Pendiente</option>
                          <option value="1">En revisión</option>
                          <option value="2" >Aprobado</option>
                          <option value="3" >Rechazado</option>
                        </select>

                        </div>
                        <div className=''>

                        {edit?
                          <button onClick={e=>handleSubmit(areaboss,id)} 
                              className='btn p-0 ml-2'>
                                
                              <i className='text-success font-25 fa fa-save'></i>
                          </button>:
                          <button className='btn p-0 ml-2' onClick={e=>handleEdit(true,key)}>
                            <i className='  font-25 fa fa-edit'></i>
                          </button>}
                        </div>
                      </td>
                    </tr>
                  )
                })}
                <LoadingData loading={loading} quantity={solicitudes.length} colspan={5}/>
                
            </TableInfinityFixed>
        </div>
        </>
    )
}
const HistoryView=({VACATIONSDAYSPERYEARS})=>{
    const {user,getInfo} = useAppContext()
    const [dataUser,setDataUser]=useState({})
    const [solicitudes,setSolicitudes]=useState([])
    const [loading,setLoading]=useState(false)
  

    useEffect(() => {
      if (user=='tengo estado') {
        getInformationUser()
      }else{
        setDataUser(user)  
        getDataUser(user)  
      }
    },[user]) 
    const getInformationUser=async() => {
      let iduser= await getInfo()
     setDataUser(iduser)
      getDataUser(iduser)
    }
    
    const getDataUser=async(user) => {
      setLoading(true)
      const response = await request.get(`events/getSolicitudHistorydept/${user.id_personal}`)
      if (response && response.statusCode === 200) {
        setSolicitudes(response.result.data)
      }
      setLoading(false)
    }
   
    return(
        <>
      
        <div className="card p-3 mt-2">
            <TableInfinityFixed columnsParam={personal} aditionalClass='table-striped '>
                {solicitudes.map((solicitud,key)=>{
                  const {nombre,start,end,presentated_day,edit,inmboss,id} = solicitud
                  return(
                    <tr key={key}>
                      <td className='text-center'>{nombre}</td>
                      <td className='text-center'>{moment(start).format('DD/MM/YYYY')}</td>
                      <td className='text-center'>{moment(end).format('DD/MM/YYYY')}</td>
                      <td className='text-center'>{moment(presentated_day).format('DD/MM/YYYY')}</td>
                      <td className='text-center d-flex'>
                        <div>
                        <select className='form-control' 
                          style={{width:'150px'}} 
                          value={inmboss}
                         
                          disabled={!edit}
                          >
                          <option value="0">Pendiente</option>
                          <option value="1">En revisión</option>
                          <option value="2" >Aprobado</option>
                          <option value="3" >Rechazado</option>
                        </select>

                        </div>
                        

                       
                      </td>
                    </tr>
                  )
                })}
                  <LoadingData loading={loading} quantity={solicitudes.length} colspan={5}/>
                
            </TableInfinityFixed>
        </div>
        </>
    )
}