import React, { useEffect, useState } from 'react'

export const TableInfinityFixed = ({children,columnsParam,aditionalClass='',fixed='table-fixed',height = 700,hiddenX = '',refill=false,cols=5,rows=5}) => {

    const [columns,setColumns]=useState([])

    useEffect(() => {
        if (columnsParam) {
            setColumns(columnsParam)
        }
    },[])


  return (
    <div className="table-responsive table-container " style={{ height: height, overflowX: hiddenX }}>
          <table className={`table align-items-center mb-0 ${fixed} ${aditionalClass} `}>
            <thead className='shadow'>
              <tr>
                {columns.map((column,key) =>{
                    const {classColumn,label} = column
                    return(
                        <th 
                          key={key} 
                          className={`${classColumn} `}
                          >
                            <h6 className={'mb-0 text-xs text-white'}>{label}</h6>  
                        </th>
                        )
                    })}
               
              </tr>
            </thead>
            <tbody>
                {children}
        {refill&&(
           Array.from({ length: cols }, (_, i) => i + 1).map((i) => (
            <tr key={i}>
              <td colSpan={rows} style={{height:'40px'}}>{}</td>
            
            </tr>
          ))
        )}
            </tbody>
        </table>
    </div>
  )
}
