
import React, { Fragment, useEffect, useState, useRef, useCallback } from 'react';
import { collection, addDoc, query, orderBy, limit, onSnapshot, startAfter ,getDocs,Timestamp} from 'firebase/firestore';
import { db,storage } from '../../../../firebaseConfig';
import { useAppContext } from '../../../../Context/AppContext';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Modal from 'react-modal'

export const GroupChat = ({ setTypeOfChat }) => {
    function removeAccents(text) {
        return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      }
    const { user, getInfo } = useAppContext();
    const chatContainerRef = useRef(null);
    const [chats, setChats] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [wArea, setWArea] = useState('');
    const [sender, setSender] = useState({
        id: 0,
        name: ''
    });
    const [lastMessage, setLastMessage] = useState('');
    const [firstVisible, setFirstVisible] = useState(null);

    const messagesEndRef = useRef(null);
    const batchSize = 15; // Tamaño de la página
    const [img,setImg] = useState('')
    const [openModal,setOpenModal] = useState(false)// Tamaño de la página
  
    const fetchOlderChats = useCallback(async (wArea, firstVisibleDoc = null) => {
        try {
            if (wArea !== '') {
                const chatCollection = collection(db, wArea);
                let chatQuery = query(chatCollection, orderBy('timestamp', 'desc'), limit(batchSize));

                if (firstVisibleDoc) {
                    chatQuery = query(chatCollection, orderBy('timestamp', 'desc'), startAfter(firstVisibleDoc), limit(batchSize));
                }

                const chatSnapshot = await getDocs(chatQuery);
                const chatList = chatSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
               

                if (chatList.length > 0) {
                    setChats(prevChats => [...chatList.reverse(), ...prevChats]); // Insertar al inicio y mantener el orden
                    setFirstVisible(chatSnapshot.docs[chatSnapshot.docs.length - 1]); // Actualizar el primer visible
                    setLastMessage(chatList[0].timestamp.toDate()); // Actualizar el último mensaje (primero en el nuevo batch)
                }
            }
        } catch (error) {
            console.error('Error fetching chats: ', error);
        }
    }, []);

    useEffect(() => {
        if (user !== 'tengo estado') {
            const wAreaCleanedWs = user.personalData.wArea.replace(/ /g, "");
            const wAreaCleaned=removeAccents(wAreaCleanedWs)

            setWArea(wAreaCleaned);
            setSender({
                id: user.personalData.id,
                name: `${user.personalData.name} ${user.personalData.lastName}`
            });

            const chatCollection = collection(db, wAreaCleaned);
            const chatQuery = query(chatCollection, orderBy('timestamp', 'desc'), limit(batchSize));

            const unsubscribe = onSnapshot(chatQuery, (snapshot) => {
                const chatList = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
               

                if (chatList.length > 0) {
                    setChats(chatList.reverse());
                    setFirstVisible(snapshot.docs[snapshot.docs.length - 1]);
                    setLastMessage(chatList[chatList.length-1].timestamp.toDate());
                }
            });

            return () => {
                unsubscribe(); // Limpiar la suscripción cuando el componente se desmonte
            };
        } else {
            getInfo();
        }
    }, [user]);

    useEffect(() => {
        // Desplaza la vista hacia el final cuando se actualice la lista de chats
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chats]);

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (newMessage.trim()) {
            try {
                await addDoc(collection(db, wArea), {
                    sender,
                    message: newMessage,
                    timestamp: new Date(),
                    type:'text',
                    content:'',
                    id_user: sender.id // Añade un timestamp si lo deseas
                });
                setNewMessage('');
            } catch (error) {
                console.error('Error adding message: ', error);
            }
        }
    };

    const handleScroll = () => {
        if (chatContainerRef.current) {
            const { scrollTop } = chatContainerRef.current;
            if (scrollTop === 0) {
                fetchOlderChats(wArea, firstVisible); // Cargar más mensajes cuando el usuario se desplaza hacia arriba
            }
        }
    };
    const handleUploadFile = async (e) => {
        const file = e.target.files[0];
        let timestamp =new Date()
        if (file) {
            const chatId =wArea;  // Reemplaza esto con el chatId real
            const storageRef = ref(storage, `chatFiles/${chatId}/${file.name}`);
            const snapshot = await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(snapshot.ref);
            
            const messagesRef = collection(db, chatId);
            await addDoc(messagesRef, {
                sender,
                timestamp: timestamp,
                type: file.type.split('/')[0], // "image", "video", "audio", etc.
                content: downloadURL,
                fileName: file.name,
                message:'',
                id_user: sender.id
            });
        }
    };
    const handleImg=(url)=>{
        setOpenModal(true)
        setImg(url)
    }
    const handleClose=()=>{
        setOpenModal(false)
        setImg('')
    }

    return (
        <div className="col-12">
            <button className="btn p-2 btn-dark" onClick={e => setTypeOfChat(0)}>
                <i className="fa fa-reply"></i>&nbsp;regresar
            </button>
            <ModalImages open={openModal} handleClose={handleClose} imageUrls={[img]}  />
            <div className="card blur shadow-blur max-height-vh-80">
                <div className="card-header shadow-lg">
                    <div className="row">
                        <div className="col-md-10">
                            <div className="d-flex align-items-center">
                                <img alt="Image" src="https://th.bing.com/th/id/R.c33d127fc827b9c519bd6cfe6d937e30?rik=TyLeJpJeJc9rdA&pid=ImgRaw&r=0" className="avatar" />
                                <div className="ms-3">
                                    <h6 className="mb-0 d-block font-12">Chat de {wArea}</h6>
                                    <span className="text-xs text-dark opacity-8">Ultimo mensaje {lastMessage.toLocaleString()}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body overflow-auto overflow-x-hidden"  ref={chatContainerRef} onScroll={handleScroll}>
                    {chats.length > 0 && (
                        chats.map((chat, key) => {
                            let fecha = chat.timestamp.toDate();

                            return (
                                <Fragment key={chat.id}>
                                    {chat.id_user === sender.id ? <SelfMessage handleImg={handleImg} message={chat.message} timestamp={fecha.toLocaleString()} chat={chat}/> :
                                        <GenarlMessage handleImg={handleImg} message={chat.message} timestamp={fecha.toLocaleString()} name={chat.sender.name} chat={chat}/>}
                                </Fragment>
                            );
                        })
                    )}
                    <div ref={messagesEndRef} />
                </div>
                <div className="card-footer d-block">
                    <form className="">
                        <div className="d-flex">
                            <div className="input-group" style={{width: '250px'}}>
                                <textarea type="text" rows={2} cols={1}  value={newMessage} onChange={e => setNewMessage(e.target.value)} className="form-control inputtextmessage" placeholder="Escribe aqui ..." aria-label="Message example input" />
                            </div>
                        <div>
                           <button className="btn bg-gradient-info p-2 btn-rounded" disabled={newMessage.length<1?true:false} onClick={e => handleSendMessage(e)}>
                                <i className="font-15 ni ni-send"></i>
                            </button>
                           </div>
                            <div class="file-input-container">
                                <label for="file-upload" class="file-label"><i className="fa-solid fa-paperclip font-15"></i></label>
                                <input type="file" id="file-upload"   class="file-input " onChange={handleUploadFile}/>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}


const SelfMessage = ({ message, timestamp, chat,handleImg }) => {
    
    const renderContent = () => {
        if (message != '') {
            return <p className="mb-0">{message}</p>;
        } else if (chat.type === 'image') {
            return (
                <div className="col-12 p-0">
                    <img 
                     onClick={e=>handleImg(chat.content)}
                        src={chat.content} 
                        style={{ width: '200px' }} 
                        alt="Rounded uploadimage" 
                        className="img-fluid mb-2 border-radius-lg" 
                    />
                </div>
            );
        } else {
            return (
                <div className="col-12 p-0">
                        <p>{chat.fileName} <i className="fa fa-file"></i></p>
                    <a href={chat.content} download className="btn btn-link">
                        <i className="fa fa-download"></i> Descargar archivo
                    </a>
                </div>
            );
        }
    };

    return (
        <div className="row justify-content-end text-right mb-4">
            <div className="col-auto">
                <div className="card bg-gray-200">
                    <div className="card-body py-2 px-3">
                        {renderContent()}
                        <div className="d-flex align-items-center justify-content-end text-sm opacity-6">
                            <i className="ni ni-check-bold text-sm me-1"></i>
                            <small>{timestamp}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelfMessage;

const GenarlMessage = ({ message, timestamp, name,chat,handleImg }) => {
        
    const renderContent = () => {
        if (message != '') {
            return <p className="mb-0">{message}</p>;
        } else if (chat.type === 'image') {
            return (
                <div className="col-12 p-0">
                    <img 
                     onClick={e=>handleImg(chat.content)}
                        src={chat.content} 
                        style={{ width: '200px' }} 
                        alt="Rounded uploadimage" 
                        className="img-fluid mb-2 border-radius-lg" 
                    />
                </div>
            );
        } else {
            return (
                <div className="col-12 p-0">
                        <p>{chat.fileName} <i className="fa fa-file"></i></p>
                    <a href={chat.content} download className="btn btn-link">
                        <i className="fa fa-download"></i> Descargar archivo
                    </a>
                </div>
            );
        }
    };
    return (
        <div className="row justify-content-start mb-4">
            <div className="col-auto">
                <div className="card">
                    <div className="card-body py-2 px-3">
                    {renderContent()}
                        
                        <div className="d-flex align-items-center text-sm opacity-6">
                            <i className="ni ni-check-bold text-sm me-1"></i>
                            <small>{name} {timestamp}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const ModalImages=({imageUrls,open,handleClose})=>{
    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "-35%",
            width: "90%",
            height: "55%",
            transform: "translate(-50%, -50%)",
          },
    }
    return(
       <Modal style={customStyles} isOpen={open} onRequestClose={handleClose} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true}>
         <div className="col-md-8 mx-auto">
        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-indicators">
          {imageUrls.map((imageUrl,key)=>{
            return(
              <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={key} className="active" aria-current="true" aria-label="Slide 1"></button>
            )
          })}
          </div>
        
          <div className="carousel-inner">
          {imageUrls.map((imageUrl) =>{
        
            return(
            <div className="carousel-item active">
              <img src={imageUrl} className="d-block w-100" alt="..."/>
            </div>
              
            )
          })}
          </div>
        
      
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
        </div>
      </div>
       </Modal>
    )
}