import { useEffect, useState } from "react";
import { TableInfinityFixed } from "../../components/TableInfinityFixed";
import { Layout } from "../Layouts/Layout";
import Request from "../../utils/http";
import groupByProperty from "../../Hooks/groupByProperty";
import { TabView, TabPanel } from 'primereact/tabview';

import { TableInactivos } from "./TableInactivos";
import { TableActivos } from "./TableActivos";
import Dognuts from "./Dognuts";
const request = new Request()

function RotacionPersonal() {

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    
    const [data,setData]=useState([])
    const [keysData,setKeysData]=useState([])
    const [dataactivos,setDataactivos]=useState([])
    const [keysDataactivos,setKeysDataactivos]=useState([])
    const [inactivosCharts,setInactivosCharts]=useState([])
    const [activosCharts,setActivosCharts]=useState([])
    const [dataAnnio,setDataAnnio]=useState([])
    const [datakeysannios,setdatakeysannios]=useState([])
    const [annioselected,setannioselected]=useState(null)

    useEffect(()=>{
        getData()
    },[])

    const getData =async()=>{
        const response = await request.get('rotacionpersonal')
        
        if (response && response.statusCode === 200) {
            setInactivosCharts(response.result.inActivosConcentrado)
            setActivosCharts(response.result.activosConcentrado)

            let items = groupByProperty(response.result.inactivos,'wArea')
            let keys = Object.keys(items)
            setData(items)
            setKeysData(keys)

            let itemsActivos = groupByProperty(response.result.activos,'wArea')
           
            let keysActivos = Object.keys(itemsActivos)
            setDataactivos(itemsActivos)
            
            setKeysDataactivos(keysActivos)

            let annios=groupByProperty(response.result.activos,'year')
            setDataAnnio(annios)
            let keysAnnios = Object.keys(annios)
            setannioselected(keysAnnios[keysAnnios.length-1])
            setdatakeysannios(keysAnnios)
           
        }
    }

    const returnMonts=(items) => {
       
        let mes=[0,0,0,0,0,0,0,0,0,0,0,0]
        items.forEach(element => {
           
            mes[element.month-1] = element.records.length

            
        });
        return mes
    }
    return ( 
        <Layout >

           
           <Dognuts inactivosCharts={inactivosCharts} activosCharts={activosCharts}/>
          <div className="mt-3">
          <TabView>
                <TabPanel header="Bajas de colaboradores" >
                    <TableInactivos {...{returnMonts,keysData,data,inactivosCharts}} />
                </TabPanel>
                <TabPanel header="Altas de colaboradores">
                    <label>Selecciona el año de ingreso</label>
                    <select className="form-control" style={{width:'250px'}} value={annioselected} onChange={e=>setannioselected(e.target.value)}>
                        {datakeysannios.map((line,i)=>{
                            return(
                                <option key={i} value={line}>{line}</option>
                            )
                        })}     
                    </select>
                       <TableActivos {...{dataAnnio,annioselected,activosCharts}}/>
                </TabPanel>

            
            </TabView>
          </div>
            
        </Layout>
    );
}

export { RotacionPersonal };