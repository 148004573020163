import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import Request from '../../../../utils/http'
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { calcularDistanciaEnMetros } from '../../../../Hooks/calcularDistanciaEnMetros';
import { calcularDiferenciaHoras, calcularRetardo, calculateTime, sumarHoras } from '../../../../Hooks/timeTransform';
import { buscarPorId } from '../../../../Hooks/buscarEnObjeto';

const request = new Request()

export const AsistencesReports = () => {
    
 
    const [data,setData]=useState([])
    const [dateStart,setDate] = useState(moment().format('YYYY-MM-DD'))
    const [dateEnd,setdateEnd] = useState(moment().format('YYYY-MM-DD'))

   
  return (
    <>
     <div class="dropdown">
          <a href="javascript:;" class="btn bg-gradient-dark font-12 dropdown-toggle " data-bs-toggle="dropdown" id="navbarDropdownMenuLink2">
              Descargar reportes de asistencias
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink2">
              <li className="p-2">
                inicio
                    <input type="date" className='form-control' value={dateStart} onChange={e=>setDate(e.target.value)} />
                 fin
                    <input type="date" className='form-control' value={dateEnd}onChange={e=>setdateEnd(e.target.value)} />
                    {/* <button className="btn btn-info mt-2" onClick={e=>getData(dateStart,dateEnd)}><i className='fa fa-search'></i>&nbsp;buscar</button> */}
              </li>
              <li>
                  <a class="dropdown-item" href="javascript:;">
                   {/* <CSVDownloadButton classContainer='input-group-sm' csvData={dataToExport} buttonText="  general" filename="locations.csv" className=" " /> */}
                  <ExportExcel {...{dateStart,dateEnd}} />
                  </a>
              </li>
            
             
          </ul>
        </div>
    </>
  )
}

const ExportExcel = ({dateStart,dateEnd}) => {
    const baseLatitud = 20.6208046
    const baseLongitud = -87.080208;
    const getData = async(inicio,fin) =>{
        const response = await request.get(`asistences/getChecksReport?dateStart=${inicio}&dateEnd=${fin}`)
        if (response && response.statusCode === 200) {  
          
            transformData(response.result.data,response.result.fechas)
            // modifyData(response.result.data)
        }
    }

    const transformData=(personal,fechas) => {
        let asistencias=[]
        let inasistencia=[]
        let retardos = []
        let general=[]
        let resumen = []
    
        personal.forEach(element => {
            resumen.push({
               colaborador: element.name+' '+element.lastName,
               retardos:element.retardos.length,
               faltas:element.faltas.length,
               permiso_gose_sueldo:element.consueldo.length,
               permiso_sin_sueldo:element.sinsueldo.length,
               incapacidades:element.incapacidades.length,
    
            })
           resumen.push({
                colaborador:'',
                retardos:'',
                faltas:'RETARDOS',
                permiso_gose_sueldo:'',
                permiso_sin_sueldo:'',
                incapacidades:'',
            })
            element.retardos.forEach(retardo => {
                resumen.push({
                    colaborador:'',
                    retardos:retardo.fecha,
                    faltas:retardo.justificantes[0].time,
                    permiso_gose_sueldo:calcularDiferenciaHoras('08:55:59',moment(retardo.justificantes[0].time).format('HH:mm:ss')),
                    permiso_sin_sueldo:'',
                    incapacidades:'',
                })
            });
            resumen.push({
                colaborador:'',
                retardos:'',
                faltas:'FALTAS',
                permiso_gose_sueldo:'',
                permiso_sin_sueldo:'',
                incapacidades:'',
            })
            element.faltas.forEach(falta => {
                resumen.push({
                    colaborador:'',
                    retardos:falta.fecha,
                    faltas:falta.justificantes.length>0?falta.justificantes[0].file_url:'Sin justificante',
                    permiso_gose_sueldo:'',
                    permiso_sin_sueldo:'',
                    incapacidades:'',
                })
            });
            resumen.push({
                colaborador:'',
                retardos:'',
                faltas:'CON GOSE DE SUELDO',
                permiso_gose_sueldo:'',
                permiso_sin_sueldo:'',
                incapacidades:'',
            })
            element.consueldo.forEach(falta => {
                resumen.push({
                    colaborador:'',
                    retardos:falta.fecha,
                    faltas:falta.justificantes.length>0?falta.justificantes[0].file_url:'Sin justificante',
                    permiso_gose_sueldo:'',
                    permiso_sin_sueldo:'',
                    incapacidades:'',
                })
            });
            resumen.push({
                colaborador:'',
                retardos:'',
                faltas:'SIN GOSE DE SUELDO',
                permiso_gose_sueldo:'',
                permiso_sin_sueldo:'',
                incapacidades:'',
            })
            element.sinsueldo.forEach(falta => {
                resumen.push({
                    colaborador:'',
                    retardos:falta.fecha,
                    faltas:falta.justificantes.length>0?falta.justificantes[0].file_url:'Sin justificante',
                    permiso_gose_sueldo:'',
                    permiso_sin_sueldo:'',
                    incapacidades:'',
                })
            });
            resumen.push({
                colaborador:'',
                retardos:'',
                faltas:'INCAPACIDAD',
                permiso_gose_sueldo:'',
                permiso_sin_sueldo:'',
                incapacidades:'',
            })
            element.incapacidades.forEach(falta => {
                resumen.push({
                    colaborador:'',
                    retardos:falta.fecha,
                    faltas:falta.justificantes.length>0?falta.justificantes[0].file_url:'Sin justificante',
                    permiso_gose_sueldo:'',
                    permiso_sin_sueldo:'',
                    incapacidades:'',
                })
            });
        })
      
    
        fechas.forEach(fecha => {
            if (!esFinDeSemana(fecha))
                {
                    asistencias.push({ dia:fecha,estatus:'',colaborador:'',horaEntrada:'',latitud:'',longitud:'',distanciaOficina:'',horaSalida:'', tiempo:'',comentarios:''})
                    inasistencia.push({ dia:fecha,estatus:'',colaborador:'',horaEntrada:'',latitud:'',longitud:'',distanciaOficina:'',horaSalida:'', tiempo:'',justificante:''})
                    retardos.push({ dia:fecha,estatus:'',colaborador:'',horaEntrada:'',latitud:'',longitud:'',distanciaOficina:'',horaSalida:'', tiempo:'',justificante:''})
                   
                    personal.forEach(element => {
                        element.checks.forEach(check => {
                            if (check.fecha ==fecha) {
                                if(check.registros.length >0){
                                    check.registros.forEach(registro => {
                                        registro.colaborador=element.name+' '+element.lastName
                                        general.push(registro)
                                        
                                    });
                                    let distancia = calcularDistanciaEnMetros(baseLatitud, baseLongitud, check.registros[0].latitude, check.registros[0].longitude);
                                    asistencias.push({
                                        dia:'',
                                        estatus:isRetardo(check.registros[0].time),
                                        colaborador:element.name+' '+element.lastName,
                                        horaEntrada:moment(check.registros[0].time).format('HH:mm:ss'),
                                        latitud:check.registros[0].latitude,
                                        longitud:check.registros[0].longitude,
                                        distanciaOficina:parseFloat(distancia).toFixed(2) +' mts de la oficina',
                                        horaSalida:moment(check.registros[check.registros.length-1].time).format('HH:mm:ss'),
                                        tiempo:calcularDiferenciaHoras(moment(check.registros[0].time).format('HH:mm:ss'),moment(check.registros[check.registros.length-1].time).format('HH:mm:ss')),
                                        comentarios:check.registros[0].comments,
                                    })
                                    let retardo = isRetardo(check.registros[0].time)
                                    if (retardo=='Retardo') {
                                        retardos.push({
                                            dia:'',
                                            estatus:isRetardo(check.registros[0].time),
                                            colaborador:element.name+' '+element.lastName,
                                            horaEntrada:moment(check.registros[0].time).format('HH:mm:ss'),
                                            latitud:check.registros[0].latitude,
                                            longitud:check.registros[0].longitude,
                                            distanciaOficina:parseFloat(distancia).toFixed(2) +' mts de la oficina',
                                            horaSalida:moment(check.registros[check.registros.length-1].time).format('HH:mm:ss'),
                                            tiempo:calcularDiferenciaHoras(moment(check.registros[0].time).format('HH:mm:ss'),moment(check.registros[check.registros.length-1].time).format('HH:mm:ss')),
                                            comentarios:check.registros[0].comments,
                                        })
                                    }
                                    
                                }else{
                                   let asistencia='No justificada'
                                   let url=''
                                    check.justificantes.forEach(element => {
                                        if (element.date==fecha) {
                                           
                                            asistencia=element.type==1?'justificada':element.type==2?'Permiso con gose de sueldo':'Permiso sin gose de sueldo'
                                            url=element.type==1?element.file_url:''
                                        }
                                    });
                                    
                                    inasistencia.push({
                                        dia:'',
                                        estatus:asistencia,
                                        colaborador:element.name+' '+element.lastName,
                                        horaEntrada:'',
                                        latitud:'',
                                        longitud:'',
                                        distanciaOficina:0 +' mts de la oficina',
                                        horaSalida:'',
                                        tiempo:'00:00:00',
                                        justificante:url
                                    })
                                }
                                
                            }
                        })
                                   
                    });
                }
                    
        });
        exportToExcel(asistencias,inasistencia,retardos,general,resumen)
               
        
    }

    const modifyData=(info) => {
       
        const registros=[]
        info.forEach(element => {
            let horasTotales = []
            registros.push({ colaborador:`${element.name} ${element.lastName}`,hora_entrada_max:' ',hora_entrada_registrada:'',tiempo_diferencia_entrada:'',statusLLegada:'',salida_hora_comida:'',
                horas_trabajadas_primerperiodo:'',regreso_hora_comida:'',tiempo_comida:'hora de entrada: 08:45:59',observacionesComida:'',hora_salida:'', tiempo_diferencia_entrada:'',observacionesSalida:'',total_horas_trabajadas:'' })
            element.checks.forEach(line => {
                if (line.registros.length>0) {
                    let observacionesComida=''
                    let registro =buscarPorId(1,line.registros,'type')
                    let entrada = registro?moment(registro.time).format('HH:mm:ss'):'00:00:00'
                    let diferencia_entrada= calcularRetardo(entrada,'08:55:59')

                    let salidaComida =buscarPorId(2,line.registros,'type')

                    if (salidaComida) {
                        
                        salidaComida = moment(salidaComida.time).format('HH:mm:ss')
                    }else{
                        
                        salidaComida = '00:00:00'
                       
                        
                        observacionesComida='NSC'
                    }

                    let horasInicio=''

                    if(diferencia_entrada.status=='a tiempo'){

                        horasInicio = calcularDiferenciaHoras('08:45:00',salidaComida)
                    }else{

                        horasInicio = calcularDiferenciaHoras(entrada,salidaComida)
                    }

                    let entradaComida = buscarPorId(3,line.registros,'type')
                    if (entradaComida) {
                        entradaComida=moment(entradaComida.time).format('HH:mm:ss')
                    } else {
                        entradaComida='15:00:00'
                        observacionesComida =observacionesComida +'NEC'
                    }
                    
                      let diferencia_entradaComida=calcularDiferenciaHoras(salidaComida,entradaComida)  
                      
                      let salida = buscarPorId(4,line.registros,'type')
                      let observacionesSalida=''
                      if (salida) {
                        salida=moment(salida.time).format('HH:mm:ss')
                      }else{

                          salida = '18:00:00'
                          observacionesSalida='No se registro hora de salida'
                      }
                      let diferencia_horas_salida=calcularDiferenciaHoras(entradaComida,salida) 

                   let total = sumarHoras([horasInicio,diferencia_horas_salida])   
                   horasTotales.push(total)
                    registros.push({ 
                        colaborador:`${line.fecha}`,
                        hora_entrada_max:'08:55:59',
                        hora_entrada_registrada:entrada,
                        tiempo_diferencia_entrada:diferencia_entrada.tiempo,
                        statusLLegada:diferencia_entrada.status,
                        salida_hora_comida:salidaComida,
                        horas_trabajadas_primerperiodo:horasInicio,
                        regreso_hora_comida:entradaComida,
                        tiempo_comida:diferencia_entradaComida,
                        observacionesComida:observacionesComida,
                        hora_salida:salida, 
                        diferencia_salida:diferencia_horas_salida,
                        observacionesSalida:observacionesSalida,
                        total_horas_trabajadas:total
                     })


                   
                }else{

                    registros.push({ colaborador:`${line.fecha}`,hora_entrada_max:'08:55:59',hora_entrada_registrada:'00:00:00',tiempo_diferencia_entrada:'0',statusLLegada:'Falta',salida_hora_comida:'00:00:00',horas_trabajadas_primerperiodo:'',regreso_hora_comida:'00:00:00',
                        tiempo_comida:'00:00:00',observacionesComida:'',hora_salida:'00:00:00', tiempo_diferencia_entrada:'0',observacionesSalida:'',total_horas_trabajadas:'0' })
                }
    
                // console.log(registros);
            })
            
        });
        exportToExcel(registros)
     
        
    }



    const convertToSheetData = (data) => {
        const keys = Object.keys(data[0]);
        const sheetData = [keys];
        data.forEach(obj => {
            sheetData.push(keys.map(key => obj[key]));
        });
        return sheetData;
    };

    // const exportToExcel = (registros) => {
      
    //     const workbook = XLSX.utils.book_new();

    //     // Convertir datos a formato adecuado para xlsx
    //     const sheetData1 = convertToSheetData(registros);
       

    //     // Crear las hojas de cálculo
    //     const worksheet1 = XLSX.utils.aoa_to_sheet(sheetData1);
    //     XLSX.utils.book_append_sheet(workbook, worksheet1, "Asistencias");

    //     // Generar el archivo XLSX
    //     const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    //     // Guardar el archivo
    //     const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    //     saveAs(data, 'multisheet.xlsx');
    // }


    function esFinDeSemana(fecha) {
        // Convertir la fecha a un objeto Date
        let date = new Date(fecha);
    
        // Obtener el día de la semana (0 = Domingo, 6 = Sábado)
        let diaSemana = date.getDay();
   
        // Verificar si es sábado o domingo
        return diaSemana === 5 || diaSemana === 6;
    }
    const isRetardo=(hora) => {
  
        let horaLimite = moment('08:55:00 AM', 'hh:mm:ss A');
          let horaLimiteString = horaLimite.format('HH:mm:ss');
          
          let entradaStatus = ''
          
          let entrada=moment(hora).format('HH:mm:ss')
          
          if (entrada>horaLimiteString) {
              entradaStatus ="Retardo"
              
              } else if (entrada==horaLimiteString) {
                entradaStatus ="Puntual"
                  
              } else {
                entradaStatus ="Temprano"
            }

          
            return entradaStatus;
          
      }
    

    // const convertToSheetData = (data) => {
    //     const keys = Object.keys(data[0]);
    //     const sheetData = [keys];
    //     data.forEach(obj => {
    //         sheetData.push(keys.map(key => obj[key]));
    //     });
    //     return sheetData;
    // };

    const exportToExcel = (asistencias,inasistencia,retardos,general,resumen) => {
      
        const workbook = XLSX.utils.book_new();

        // Convertir datos a formato adecuado para xlsx
        const sheetData1 = convertToSheetData(asistencias);
        const sheetData2 = convertToSheetData(inasistencia);
        const sheetData3 = convertToSheetData(retardos);
        const sheetData4 = convertToSheetData(general);
        const sheetData5 = convertToSheetData(resumen);

        // Crear las hojas de cálculo
        const worksheet1 = XLSX.utils.aoa_to_sheet(sheetData1);
        XLSX.utils.book_append_sheet(workbook, worksheet1, "Asistencias");

        const worksheet2 = XLSX.utils.aoa_to_sheet(sheetData2);
        XLSX.utils.book_append_sheet(workbook, worksheet2, "Inasistencias");

        const worksheet3 = XLSX.utils.aoa_to_sheet(sheetData3);
        XLSX.utils.book_append_sheet(workbook, worksheet3, "Retardos");

        const worksheet4 = XLSX.utils.aoa_to_sheet(sheetData4);
        XLSX.utils.book_append_sheet(workbook, worksheet4, "Todos los registros");
        const worksheet5 = XLSX.utils.aoa_to_sheet(sheetData5);
        XLSX.utils.book_append_sheet(workbook, worksheet5, "Resumen");

        // Generar el archivo XLSX
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        // Guardar el archivo
        const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(data, 'multisheet.xlsx');
    }
    return (
        <button onClick={e=>getData(dateStart,dateEnd)}>
            Export to Excel
        </button>
    );
};