import React,{useState,useEffect} from 'react'

export const SimpleModal = ({children,identifier,size='700',handleCloseFunction=()=>{}, heightProp='700'}) => {
    // data-bs-toggle="modal" data-bs-target={`#${identifier}`}

    // document.addEventListener('DOMContentLoaded', function () {
     
    //   var miModal = document.getElementById(identifier);
     
    //   miModal.addEventListener('hidden.bs.modal', function () {
    //     handleCloseFunction()
    //   });
    // });

    const handleClose=()=>{
      handleCloseFunction()
    }
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
      const miModal = document.getElementById(identifier);
  
      if (miModal) {
        const handleHidden = () => {
          setIsModalOpen(false);
          handleCloseFunction();
        };
        miModal.addEventListener('hidden.bs.modal', handleHidden);
        return () => { miModal.removeEventListener('hidden.bs.modal', handleHidden); };
      }
    }, [identifier, handleCloseFunction]);
  
    const handleModalShow = () => {
      setIsModalOpen(true);
    };
  

  return (
    <div>
      <div className="modal fade" id={identifier} tabIndex="-1" role="dialog" aria-labelledby="modal-default" aria-hidden="true"  onShow={handleModalShow}>
        <div className="modal-dialog modal- modal-dialog-centered modal-" role="document" >
          <div className="modal-content" style={{maxWidth:size+'px',height:heightProp+'px'}}>
              {children}
          </div>
        </div>
      </div>
    </div>
  )
}
