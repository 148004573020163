import React, { useEffect, useState } from "react";
import { LayoutMovile } from "../../Layouts/LayoutMovile";
import { TabOne } from "./TabOne";
import { TabTwo } from "./TabTwo";
import { TabTree } from "./TabTree";
import { decryptString } from "../../../utils/encrypt";
import Request from "../../../utils/http"
import { AddPost } from "./AddPost";
const request = new Request();

export const HomePage = () => {
  const [renderTab,setRenderTab]=useState(1)
  const [useData, setUser] = useState(0);
  const [useDataInfo, setUserInfo] = useState(0);
  const [userName, setUserName] = useState('')
  useEffect(() => {
    let userstorage = sessionStorage.getItem("user");
    userstorage = JSON.parse(decryptString(userstorage));
   
    setUser(userstorage.id_personal);
    setUserName(`${userstorage.personalData.name} ${userstorage.personalData.lastName}`)
    setUserInfo(userstorage)
    checkLoggedIn();

    // Configurar un intervalo para llamar a checkLoggedIn cada minuto
    const interval = setInterval(checkLoggedIn, 60000);

    // Limpiar el intervalo al desmontar el componente para evitar fugas de memoria
    return () => clearInterval(interval);
  
  }, []);

  const [loggedIn, setLoggedIn] = useState(false); // Estado para almacenar si el usuario está logueado



  const checkLoggedIn = async () => {
    try {
      // Realizar una llamada al servidor para comprobar check

      let userstorage = sessionStorage.getItem("user");
      userstorage = JSON.parse(decryptString(userstorage));
    
      const response = await request.get(`locations/getCheckToDay/${userstorage.id_personal}`);
      if (response && response.statusCode==200) {
       
        setLoggedIn(response.result.data); // Actualizar el estado según la respuesta del servidor
      }
    } catch (error) {
      console.error('Error al comprobar si el usuario está logueado:', error);
    }
  };

  return (
    <>
    
      
      <AddPost useData={useDataInfo} userName={userName}/>
      <LayoutMovile showTabs={true} handleRender={setRenderTab} >
        {renderTab == 1 && <TabOne {...{loggedIn,useDataInfo}} />}
        {/* {renderTab == 2 && <TabTwo />} */}
        {/*  {renderTab == 3 && <TabTree />} */}
      </LayoutMovile>
    </>
  );
};
