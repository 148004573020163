import React, { useEffect, useState } from 'react'
import { LayoutMovile } from '../Layouts/LayoutMovile'
import { Link, useParams } from 'react-router-dom'
import { decryptString } from '../../utils/encrypt'
import Request from '../../utils/http'
import moment from 'moment-timezone'
import nodata from '../../static/nodata.jpg'
const request = new Request()
export const CheckOfTheDays = () => {
    const [checks,setChecks]=useState([])
    const { urlToBack } = useParams();

    useEffect(()=>{
        getData()
    },[])
    const getData = async() =>{
        let userstorage = sessionStorage.getItem("user");
            userstorage = JSON.parse(decryptString(userstorage));
       
        const response = await request.get(`getChecksOfTheDay/${userstorage.id_personal}`)
        if (response && response.statusCode === 200) {
            setChecks(response.result.data)
        }
       
    }

  return (
    <LayoutMovile showTabs={false}>
       <div className="p-0 input-group-sm d-flex ">
       <Link to={`/${urlToBack}`} className='btn btn-danger btn-sm'> <i className='fa fa-reply font-15'></i></Link>
       <div className="col-12 text-center">
       <h2>Mis visitas del dia</h2>
       </div>
       </div>
        <div className="row mt-3">
      {checks.length==0 && <div className="col-md-12 shadow">
            <img src={nodata} alt="" className='' style={{width:'90%', height:'90%'}} />
       </div>}
            {checks.map((check, i) =>{
                const {type,time}=check;
                let checkFlag='fa-arrow-right-to-bracket text-success'
                let flag='Entrada'

                switch (type) {
                  case 1:
                    checkFlag='fa-arrow-right-to-bracket text-success'
                    flag='Entrada'
                    break;
                  case 2:
                    checkFlag='fa-solid fa-arrow-right-from-bracket text-danger'
                    flag='Salida'
                    break;
                  case 3:
                    checkFlag='fa-arrow-right-to-bracket text-success'
                    flag='Entrada'
                    break;
                  case 4:
                    checkFlag='fa-solid fa-arrow-right-from-bracket text-danger'
                    flag='Salida'
                    break;
                
                  default:
                    break;
                }
                return(
                    <div className="col-md-4 p-2" key={i} >
                        <div className=" col-md-12 col-sm-12 col-lg-12 col-xl-12 text-center   shadow" 
                            style={{backgroundColor:'#ffff',marginRight:'10px',borderRadius:'10px',height:'60px',borderRadius:'5px'}}>
                            <div  className="col-10 d-flex justify-content-between pr-3">
                                
                                    <h6 className="mb-1  ml-2 mt-2 text-not-dark "> {flag}  </h6>
                                    <h6 className="mb-1  ml-2 mt-2 text-not-dark "> {moment(time).format('DD/MM/YYYY HH:mm:ss')}  </h6>
                                    &nbsp;<i class={`mt-2 fa-solid ${checkFlag} font-30  `}></i>
                            </div>
                            </div>
                    </div>
                )
            })}

           


  {/* <div className="col-md-4 p-2"  >
       <div className=" col-md-12 col-sm-12 col-lg-12 col-xl-12 text-center   shadow" 
           style={{backgroundColor:'#ffff',marginRight:'10px',borderRadius:'10px',height:'60px'}}>
           <div  className="col-10 d-flex justify-content-between pr-3">
              
                 <h6 className="mb-1 text-gradient ml-2 mt-2   "> {`Entrada `}  </h6>
                 <h6 className="mb-1 text-gradient ml-2 mt-2   "> {`07:45:25 a.m `}  </h6>
                 <i class="mt-2 fa-solid fa-arrow-right-to-bracket font-30  text-success"></i>
           </div>
         </div>
  </div>
  <div className="col-md-4 p-2"  >
       <div className=" col-md-12 col-sm-12 col-lg-12 col-xl-12 text-center   shadow" 
           style={{backgroundColor:'#ffff',marginRight:'10px',borderRadius:'10px',height:'60px'}}>
           <div  className="col-10 d-flex justify-content-between pr-3">
              
                 <h6 className="mb-1 text-gradient ml-2 mt-2   "> {`Salida `}  </h6>
                 <h6 className="mb-1 text-gradient ml-2 mt-2   "> {`13:05:25 a.m `}  </h6>
                 <i class="mt-2  font-30 "></i>
           </div>
         </div>
  </div>
  <div className="col-md-4 p-2"  >
       <div className=" col-md-12 col-sm-12 col-lg-12 col-xl-12 text-center   shadow" 
           style={{backgroundColor:'#ffff',marginRight:'10px',borderRadius:'10px',height:'60px'}}>
           <div  className="col-10 d-flex justify-content-between pr-3">
              
                 <h6 className="mb-1 text-gradient ml-2 mt-2   "> {`Entrada `}  </h6>
                 <h6 className="mb-1 text-gradient ml-2 mt-2   "> {`14:05:25 a.m `}  </h6>
                 <i class="mt-2 fa-solid fa-arrow-right-to-bracket font-30 text-success"></i>
           </div>
         </div>
  </div>
  <div className="col-md-4 p-2"  >
       <div className=" col-md-12 col-sm-12 col-lg-12 col-xl-12 text-center   shadow" 
           style={{backgroundColor:'#ffff',marginRight:'10px',borderRadius:'10px',height:'60px'}}>
           <div  className="col-10 d-flex justify-content-between pr-3">
              
                 <h6 className="mb-1 text-gradient ml-2 mt-2   "> {`Salida `}  </h6>
                 <h6 className="mb-1 text-gradient ml-2 mt-2   "> {`18:05:25 a.m `}  </h6>
                 <i class="mt-2 fa-solid fa-arrow-right-from-bracket font-30 text-danger"></i>
           </div>
         </div>
  </div> */}
  </div>
    </LayoutMovile>
  )
}
