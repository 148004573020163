import React, { useEffect, useState } from "react";
import { TableUploads } from "./TableUploads";
import moment from "moment-timezone";
import Request from "../../../../../utils/http"
import { numeroFormateado } from "../../../../../utils/currencyFormat";
import {SimpleModal} from '../../../../../components/ReactModal/SimpleModal'
import { Toast } from "../../../../../components/Toast";
const request = new Request();

export const DeletePersonal = ({ user }) => {
    
  return (
    <>
      <CalcInformation {...{user}}/>
      <TableUploads user={user} />
    </>
  );
};
const CalcInformation=({user}) => {
  const [userToDelete,setUserToDelete]=useState({
    id:0,
    userName:'',
    puesto:'',
    ingreso:'',
    egreso:'',
    ppvacaciones:0,
    ppaguinaldo:0,
    nss:'',
    annios:'0',
    yearsDiff:0,
    salaryPerDays:0,              
    salaryIntegrated:1,
    daysPerDays:1,
    daysAguinaldo:0,              
    daysvacations:0,    
    startDays:'' ,
    vacationsDays:12 ,
    vacationspercent:25,  
    faltasPeriodo:0,
    primaAnt:0,   
    percepcionesAgravadas:0,
    gratificacion:0,
    vales:0,



  })
 
  useEffect(()=>{
    getSalaries(user)
  },[user])
  
  
  const getSalaries=async(user) => {
    const yearsDiff = user.egreso?moment(user.egreso).diff(moment(user.ingreso), 'years'):0;
    const daysDiff = user.egreso?moment(user.egreso).diff(moment(user.ingreso), 'day'):0;
    let ppdays=yearsDiff<1? (daysDiff/1):(daysDiff/yearsDiff)
    
     
    const response = await request.get(`sueldos/obtenerSueldos/${user.id}`)
    let salary=0
      if (response && response.statusCode==200) {
        response.result.data.forEach((sueldo)=>{
          if (sueldo.current==1 )
          salary=salary+sueldo.sueldo;
        })
      }
  
    setUserToDelete({
      ...userToDelete,
      id: user.id,
      userName:`${user.name} ${user.lastName}`,
      puesto:user.puesto,
      ingreso:user.ingreso,
      nss:user.nss,
      ppvacaciones:ppdays+1,
      ppaguinaldo:ppdays+1,
      yearsDiff:yearsDiff,
      salaryPerDays:salary>0?parseFloat(salary/30).toFixed(2):0,
      primaDominical:salary*0.25
    })
      
  }
  const handleChange=(event)=>{
    if (event.target.name=='egreso') {
      const yearsDiff = moment(event.target.value).diff(moment(user.ingreso), 'years');
      const daysDiff = moment(event.target.value).diff(moment(user.ingreso), 'day');
      let ppdays=yearsDiff<1? (daysDiff/1):(daysDiff/yearsDiff)
      setUserToDelete({
        ...userToDelete,
        
        ppvacaciones:ppdays,
        ppaguinaldo:ppdays,
        yearsDiff:yearsDiff,
        egreso:event.target.value
      })
    }else{

      setUserToDelete({...userToDelete,[event.target.name]:event.target.value})
    }
  }
  const recalDay =(value)=>{
    const yearsDiff = moment(value).diff(moment(user.ingreso), 'years');
    const daysDiff = moment(value).diff(moment(user.ingreso), 'day');
    let ppdays=yearsDiff<1? (daysDiff/1):(daysDiff/yearsDiff)
    setUserToDelete({
      ...userToDelete,
      ppvacaciones:ppdays+1,
      ppaguinaldo:ppdays+1,
      egreso:value
    })
  }

  return(
    <>
    <GeneralData  {...{userToDelete,handleChange,recalDay}} />
    <DatosPerceptions {...{ userToDelete,handleChange}} />
    <PerceptionConcepts {...{ userToDelete,handleChange}} />
    </>
  )
}
const GeneralData=({ userToDelete,handleChange,recalDay})=>{

 
  return(
    <div className="row p-3">
      <div className="col-md-12">
      <h4>DATOS GENERALES</h4>
      </div>
      <div className="col-md-3 input-group-sm">
        <h6 className="text-xs">Colaborador</h6>
        <input disabled type="text" value={userToDelete.userName} readOnly className="form-control input-sm" />
      </div>
      <div className="col-md-3 input-group-sm">
        <h6 className="text-xs">Puesto</h6>
        <input type="text" disabled value={userToDelete.puesto} readOnly className="form-control input-sm" />
      </div>
      <div className="col-md-3 input-group-sm">
        <h6 className="text-xs">Fecha de ingreso</h6>
        <input type="date" value={userToDelete.ingreso} disabled  className="form-control input-sm" />
      </div>
      <div className="col-md-3 input-group-sm">
        <h6 className="text-xs">Fecha de baja</h6>
        <input type="date" value={userToDelete.egreso} name='egreso' onChange={e=>recalDay(e.target.value)}  style={{borderColor:'red'}}  className="form-control input-sm" />
      </div>
      <div className="col-md-3 input-group-sm">
        <h6 className="text-xs">Dias pendientes de pago vacaciones </h6>
        <input type="number" value={userToDelete.ppvacaciones} name='ppvacaciones' onChange={handleChange}  className="form-control input-sm" />
      </div>
      <div className="col-md-4 input-group-sm">
        <h6 className="text-xs">Dias pendientes de pago aguinaldo </h6>
        <input type="number" value={userToDelete.ppaguinaldo} name='ppaguinaldo'  onChange={handleChange}  className="form-control input-sm" />
      </div>
      <div className="col-md-3 input-group-sm">
        <h6 className="text-xs">NSS</h6>
        <input type="number" disabled value={userToDelete.nss} onChange={handleChange} name='nss' placeholder='0000'  className="form-control input-sm" />
      </div>
      <div className="col-md-2 input-group-sm">
        <h6 className="text-xs">Años laborados</h6>
        <input type="number" value={userToDelete.yearsDiff} name='annios' disabled  className="form-control input-sm" />
      </div>
    </div>
  )
}

const DatosPerceptions=({userToDelete,handleChange})=>{
  return(
    <div className="row p-3">
        <div className="col-md-12">
          <h4>DATOS DE PERCEPCIONES</h4>
        </div>
        <div className="col-md-6 input-group-sm">
            <h6 className="text-xs">Salario Diario</h6>
            <input type="number" name="salaryPerDays" value={userToDelete.salaryPerDays} onChange={handleChange} className="form-control" />
        </div>
        <div className="col-md-6 input-group-sm">
            <h6 className="text-xs">Salario Diario Integrado</h6>
            <input type="number" name="salaryIntegrated" value={userToDelete.salaryIntegrated} onChange={handleChange} className="form-control" />
        </div>
        <div className="col-md-6 input-group-sm">
            <h6 className="text-xs">Días de salario por pagar</h6>
            <input type="number" name="daysPerDays" value={userToDelete.daysPerDays} onChange={handleChange} className="form-control" />
        </div>
        <div className="col-md-6 input-group-sm">
            <h6 className="text-xs">Días de Aguinaldo</h6>
            <input type="number" name="daysAguinaldo" value={userToDelete.daysAguinaldo} onChange={handleChange} className="form-control" />
        </div>
        <div className="col-md-6 input-group-sm">
            <h6 className="text-xs">Dias Vacaciones pendientes anteriores</h6>
            <input type="number" name="daysvacations" value={userToDelete.daysvacations} onChange={handleChange} className="form-control" />
        </div>
        <div className="col-md-6 input-group-sm">
            <h6 className="text-xs">Faltas del periodo</h6>
            <input type="number" name="faltasPeriodo" value={userToDelete.faltasPeriodo} onChange={handleChange} className="form-control" />
        </div>
        <div className="col-md-6 input-group-sm">
            <h6 className="text-xs">Inicio del ejercicio</h6>
            <input type="date" name="startDays" value={userToDelete.startDays} onChange={handleChange}  className="form-control" />
        </div>
        <div className="col-md-6">
            <h6 className="text-xs">Días de vacaciones</h6>
            <input type="number" name="vacationsDays" value={userToDelete.vacationsDays} onChange={handleChange}  className="form-control" />
        </div>
        <div className="col-md-6">
            <h6 className="text-xs">Porcentaje Prima Vacacional %</h6>
            <input type="number" name="vacationspercent" value={userToDelete.vacationspercent} onChange={handleChange}  className="form-control" />
        </div>

    </div>
  )
}
const PerceptionConcepts=({userToDelete,handleChange}) => {
  
  const [dataToSubmit,setDataToSubmit]=useState([])
  function truncarADosDecimales(numero) {
    return Math.trunc(numero * 100) / 100;
}




  const salaries=parseFloat(userToDelete.salaryPerDays *userToDelete.daysPerDays)
  const vacaciones=parseFloat(((userToDelete.vacationsDays/365) * userToDelete.ppvacaciones )* userToDelete.salaryPerDays)
  const aguinaldo =parseFloat(((userToDelete.daysAguinaldo/365) * userToDelete.ppaguinaldo )* userToDelete.salaryPerDays)
  const vacpend=parseFloat(userToDelete.daysvacations*userToDelete.salaryPerDays)
  const indemn=parseFloat(userToDelete.salaryPerDays*90)
  const primaVca=parseFloat(vacaciones*0.25).toFixed(2)

  let total=salaries+truncarADosDecimales(vacaciones)+truncarADosDecimales(vacaciones*0.25)+aguinaldo+truncarADosDecimales(userToDelete.primaDominical)+vacpend+indemn
            +truncarADosDecimales(userToDelete.primaAnt)+truncarADosDecimales(userToDelete.percepcionesAgravadas)+truncarADosDecimales(userToDelete.gratificacion)+truncarADosDecimales(userToDelete.vales)

  const handleData=()=>{

    setDataToSubmit([{
      id_user:userToDelete.id,
      userName:userToDelete.userName,
      puesto:userToDelete.puesto,
      ingreso:userToDelete.ingreso,
      egreso:userToDelete.egreso,
      ppvacaciones:userToDelete.ppvacaciones,
      ppaguinaldo:userToDelete.ppaguinaldo,
      nss:userToDelete.nss,
      annios:userToDelete.annios,
      yearsDiff:userToDelete.yearsDiff,
      salaryPerDays:userToDelete.salaryPerDays,
      salaryIntegrated:userToDelete.salaryIntegrated,
      daysPerDays:userToDelete.daysPerDays,
      daysAguinaldo:userToDelete.daysAguinaldo,
      daysvacations:userToDelete.daysvacations,
      startDays:userToDelete.startDays,
      vacationsDays:userToDelete.vacationsDays,
      vacationspercent:userToDelete.vacationspercent,
      faltasPeriodo:userToDelete.faltasPeriodo,
      primaAnt:userToDelete.primaAnt,
      percepcionesAgravadas:userToDelete.percepcionesAgravadas,
      gratificacion:userToDelete.gratificacion,
      vales:userToDelete.vales,
      primaDominical:userToDelete.primaDominical,
      salaries:salaries,
      vacations:vacaciones.toFixed(2),
      primaVca:primaVca,
      aguinaldo:aguinaldo.toFixed(2),
      vacpend:vacpend,
      indemn:indemn,
      total:numeroFormateado(total)
      
    }])
  }
  return(
    <div className="row p-3">
      <div className="col-md-12">
        <h4>PERCEPCIONES DEL COLABORADOR</h4>
      </div>
      <div className="col-md-6">
     
      <div className="col-md-12 input-group-sm">
        <h6 className="text-xs">Sueldos y Salarios</h6>
        <input type="number" value={salaries} disabled className="form-control" />
      </div>
      <div className="col-md-12 input-group-sm">
        <h6 className="text-xs">Vacaciones</h6>
        <input type="number" disabled value={vacaciones.toFixed(2)} className="form-control" />
      </div>
      <div className="col-md-12 input-group-sm">
        <h6 className="text-xs">Prima vacacional</h6>
        <input type="number" disabled value={primaVca}  className="form-control" />
      </div>
      <div className="col-md-12 input-group-sm">
        <h6 className="text-xs">Aguinaldo</h6>
        <input type="number"disabled value={aguinaldo.toFixed(2)} className="form-control" />
      </div>
      <div className="col-md-12 input-group-sm">
        <h6 className="text-xs text-danger">Prima dominical</h6>
        <input type="number" value={(userToDelete.primaDominical)} style={{borderColor:'red'}} name='primaDominical' onChange={handleChange} className="form-control" />
      </div>
      <div className="col-md-12 input-group-sm">
        <h6 className="text-xs">Vacaciones pendientes</h6>
        <input type="number" disabled value={vacpend} className="form-control" />
      </div>
    
      </div>
      <div className="col-md-6">
      <div className="col-md-12 input-group-sm">
        <h6 className="text-xs">Indemnización</h6>
        <input type="number" disabled value={indemn} className="form-control" />
      </div>
      <div className="col-md-12 input-group-sm">
        <h6 className="text-xs">Prima de Antigüedad</h6>
        <input type="number" value={userToDelete.primaAnt} onChange={handleChange} name='primaAnt' className="form-control" />
      </div>
      <div className="col-md-12 input-group-sm">
        <h6 className="text-xs">Otras percepciones gravadas</h6>
        <input type="number" value={userToDelete.percepcionesAgravadas} onChange={handleChange} name='percepcionesAgravadas' className="form-control" />
      </div>
      <div className="col-md-12 input-group-sm">
        <h6 className="text-xs">Gratificacion </h6>
        <input type="number" value={userToDelete.gratificacion}  onChange={handleChange} name='gratificacion' className="form-control" />
      </div>
      <div className="col-md-12 input-group-sm">
        <h6 className="text-xs">Vales de despensa </h6>
        <input type="number" value={userToDelete.vales} onChange={handleChange} name='vales' className="form-control" />
      </div>
      <div className="col-md-12 input-group-sm">
        <h6 className="text-xs">Total percepciones</h6>
        <h2 className="text-dark">{numeroFormateado(total)}</h2>
       
      </div>
      </div>
    
        <button className="btn btn-dark mt-3 btn-block" onClick={handleData} data-bs-toggle="modal" data-bs-target={`#modalconfirm`}>Generar documento de baja <i className="fa fa-document-o"></i></button>
        <ModalConfirm dataToSubmit={dataToSubmit}/>
    </div>
  )
}

const ModalConfirm=({dataToSubmit}) => {
  const [showToastProp,setShowToast]=useState({
    showToast:false,
    labelHeader:'Éxito',
    message:'Operacion exitosa',
    type:'success'
  })
   const handleSubmit = async() => {
    
    const response = await request.post(`deletepersonal/generateConstance/${dataToSubmit.id_user}`,dataToSubmit[0])
    if (response && response.statusCode==200) {
      setShowToast({...showToastProp,type:'success',showToast:true,message:'Guardado con exito!!'})
     
  }else{
      setShowToast({...showToastProp,type:'danger',showToast:true,labelHeader:'Error, intentelo de nuevo',message:'Favor de notificar a sistemas'})
      
  }
  setTimeout(() => {
      setShowToast({...showToastProp,showToast:false})
      
  }, 3000);
  }
  return(
    <SimpleModal identifier={'modalconfirm'} heightProp="150">

       <div className="text-center"> 
        <h3>¿Desea Genera el documento de finiquito?</h3>

      </div>
        <div className="text-center">
          <buttton className='btn btn-dark' onClick={handleSubmit}>Si,generar</buttton>
          <buttton className='btn btn-dark ml-2'>No,cancelar</buttton>
        </div>
        {showToastProp.showToast&&  <Toast
        showToast={showToastProp.showToast}
        labelHeader={showToastProp.labelHeader}
        message={showToastProp.message}
        type={showToastProp.type}
      
      />}
    </SimpleModal>
  )
}