import React, { useEffect, useState } from 'react'
import { TableInfinityFixed } from '../../../components/TableInfinityFixed';
import groupByProperty from '../../../Hooks/groupByProperty';
import { buscarPorId } from '../../../Hooks/buscarEnObjeto';
import randomCssRgba from '../../../Hooks/RandomColor';
import { Chart } from 'primereact/chart';
const header=[
    {label:'Tipo'},
    {label:'Departamento'},
    {label:'Enero'},
    {label:'Febrero'},
    {label:'Marzo'},
    {label:'Abril'},
    {label:'Mayo'},
    {label:'Junio'},
    {label:'Julio'},
    {label:'Agosto'},
    {label:'Septiembre'},
    {label:'Octubre'},
    {label:'Noviembre'},
    {label:'Diciembre'},

]
export const TableActivos = ({dataAnnio,annioselected,activosCharts}) => {
console.log("🚀 ~ TableActivos ~ activosCharts:", activosCharts)

const [chartData, setChartData] = useState({});
const [chartOptions, setChartOptions] = useState({});
    const [data,setData]=useState([])
    const [keys,setKeys]=useState([])
    const [dataconcentrados,setDataConcentrado]=useState([])
    useEffect(()=>{
        if (annioselected!=null) {
            let d =dataAnnio[annioselected]
            let depto = groupByProperty(d,'wArea')
            setData(depto)
            setKeys(Object.keys(depto))
           
            setDataConcentrado(activosCharts)

            processData(activosCharts)
         
        }
        const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
   
    const options = {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
            legend: {
                labels: {
                    color: textColor
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            },
            y: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            }
        }
    };
    setChartOptions(options)
    },[annioselected])

    const returnMonts=(items) => {
      
        let mes=[0,0,0,0,0,0,0,0,0,0,0,0]
        items.forEach(element => {
           
            mes[element.month-1] = element.records.length

            
        });
        return mes
    }

    const processData=(lines) => {
        let dataItems = {
            labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio','Agosto','septiembre','Octubre','Noviembre','Diciembre'],
            datasets: [ ]
            };
        lines.map((line) => {
          
            let s= buscarPorId(annioselected,line.years,'year')
           
            dataItems.datasets.push(  {
                label:line.depto,
                data:s?s.data:[0,0,0,0,0,0,0,0,0,0,0,0],
                fill: false,
                borderColor: randomCssRgba(),
                tension: 0.4
            })
            
        })
        setChartData(dataItems)

    }
  return (
        <>
         <div className="mb-2">
         <Chart type="line" height='230' data={chartData} options={chartOptions} />
         </div>
        <TableInfinityFixed columnsParam={header} height={'auto'} aditionalClass="table-striped">
            {keys.map(((line,idx)=>{
                let s = returnMonts(data[line]);
                
                return(
                    <tr key={idx}>
                        <td>alta</td>
                        <td>{line=='no_asign'?'sin asignar':line}</td>
                        <td>{s[0]}</td>
                        <td>{s[1]}</td>
                        <td>{s[2]}</td>
                        <td>{s[3]}</td>
                        <td>{s[4]}</td>
                        <td>{s[5]}</td>
                        <td>{s[6]}</td>
                        <td>{s[7]}</td>
                        <td>{s[8]}</td>
                        <td>{s[9]}</td>
                        <td>{s[10]}</td>
                        <td>{s[11]}</td>

                    </tr>
                )
            }))}

        </TableInfinityFixed>
        </>
  )
}
