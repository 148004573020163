import React, { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';

export const ToastAlert = ({toastAlertProps}) => {
    const {text='',type=''} = toastAlertProps


    useEffect(()=>{
        if (text!='') {
           
            switch (type) {
                case 'success':
                    toast.success(`${text}`);
                break;
                case 'info':
                    toast.info(`${text}`);
                break;
                case 'warning':
                    toast.warning(`${text}`);
                break;
                case 'default':
                    toast.default(`${text}`);
                break;
            
                default:
                    toast.error(`${text}`);  
                break;
            }
        }


    },[toastAlertProps])
  return (
    <ToastContainer position="top-right" theme="colored"autoClose={2000} />
  )
}


// estado: const [toastAlertProps,setToastAlert]=useState({text:'',type:''})



// funciones: (1 exito):  setToastAlert({text:'Guardado con exito',type:'success'},) (2 error):  setToastAlert({text:'Error al guardar',type:'error'},)



// componente: <ToastAlert {...{toastAlertProps}} /> */}


// Position
    //  top-left  top-right  top-center  bottom-left  bottom-right  bottom-center

// Type
    //  info     success   warning   error default

// Theme
    //  light   dark   colored