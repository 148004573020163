import { createContext, useContext, useEffect, useState } from "react";
import {loadUser} from './UserData'
const AppContext = createContext();


export const AppProvider = ({ children }) => {
  const [user,setUser]= useState('tengo estado')
  useEffect(() => {
    user=='tengo estado' && getInfo()
  }, [user]);

  const getInfo = async() => {
    let userVariable = await loadUser(); 
    if (userVariable) {
      setUser(userVariable);
    }
    return userVariable;
  }

  let data = {
    // modulos:modulos,
    user:user,
    getInfo:getInfo
    // pendientes:pendientes,
    // muestras:muestras,
    // documentsLength:documentsLength,
    // saveIdContainer:saveIdContainer,
    // saveToLabell:saveToLabell,
    // labelledBoxes:labelledBoxes,
    // getPermision:getPermision,
    // getVendedores:getVendedores,
    // getClientes:getClientes,
  }
  return (
    <AppContext.Provider value={data}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};