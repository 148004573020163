import React, { useEffect, useState } from 'react'
import { PersonalInformation } from './../PersonalInformation'
import { CompanyInformation } from './../CompanyInformation'
import { Toast } from '../../../../../components/Toast'
import Request from '../../../../../utils/http'
const request = new Request()   

export const PersonalAndProfesionalInfo=({user,department,tags,getDataAditional,tagsBoss,handleClose}) => {

    const [loading,setLoading]=useState(false)
    const [data,setData]=useState({
        id:0,
        name:'',
        lastName:'',
        phone:'',
        numExt:'',
        cp:'',
        direction:'',
        birthday:'',
        rfc:'',
        curp:'',
        nss:'',
        school:'',
        ine:'',
        alergist:'',
        personalContact:'',
        phoneContact:'',
        empresa:'',
        ingreso:'',
        inmBoss:'',
        wArea:'',
        infonavit:'',
        numCart:'',
        numCarttwo:'',
        idLicNum:'',
        id_check:'',
        id_empleado:'',
        puesto:'',
        utalla:'',
        email:'',
        emailCompany:'',
        checkCode:''
    })
    const [showToastProp,setShowToast]=useState({
        showToast:false,
        labelHeader:'Éxito',
        message:'Operacion exitosa',
        type:'success'
    })
    useEffect(()=>{
        
        if (user!=null) {
            let copy = JSON.parse(JSON.stringify(user))
           
            let tb=JSON.parse(user.inmBoss)
            // let wh=JSON.parse(user.wArea)
            copy.inmBoss=tb
            // copy.wArea=wh
            setData(copy)
        }
       
    },[user])

   

    const handleSubmit=async()=>{

        if (data.name==''||data.lastName==''||data.cp==''||data.rfc==''||data.curp==''||data.ingreso==''){
            setShowToast({...showToastProp,type:'danger',showToast:true,labelHeader:'Error, Campos incompletos',message:'Favor llenar los campos marcados con rojo'})
            setTimeout(() => {
                setShowToast({...showToastProp,showToast:false})
               
            }, 2500);
            return 0;
        }
        setLoading(true)
        let dataToSubmit = JSON.parse(JSON.stringify(data))
        dataToSubmit.inmBoss=JSON.stringify(dataToSubmit.inmBoss)
        let response=''
        if (dataToSubmit.id==0) {
            response = await request.post('user/createUser',dataToSubmit)
            
        }else{
            response = await request.put(`user/updateColaborator/${data.id}`,dataToSubmit)
        }
        
        if (response && response.statusCode==200) {
            setShowToast({...showToastProp,type:'success',showToast:true,labelHeader:'Guardado con exito!!',message:'Por favor espere'})
            
        }else{
            setShowToast({...showToastProp,type:'danger',showToast:true,labelHeader:'Error, intentelo de nuevo',message:'Favor de notificar a sistemas'})
            
        }
        setLoading(false)
        setTimeout(() => {
            setShowToast({...showToastProp,showToast:false})
            dataToSubmit.id==0&& handleClose()
        }, 2500);
    }
    return(
        <>
        
        <div className='p-3 ' >
         
        <h5>Informacion personal</h5>
        <PersonalInformation {...{data,setData}} />
        <h5>Informacion profesional</h5>
        <CompanyInformation {...{data,setData,department,tags,  tagsBoss,getDataAditional}} />
        </div>
       <div className='d-flex justify-content-end p-1 mt-1'>
        <button disabled={loading} className='btn bg bg-gradient-success' onClick={handleSubmit}><i className={`fa ${loading?'fa-spin fa-spinner':'fa-save'} font-15`}></i> &nbsp; Guardar informacion personal</button>
       
       </div>
   
      <Toast 
        showToast={showToastProp.showToast}
        labelHeader={showToastProp.labelHeader}
        message={showToastProp.message}
        type={showToastProp.type}
      
      />
     
       </>
    )
}
