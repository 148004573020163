import {useState,useEffect} from 'react'
import Modal from 'react-modal'
import Select from "react-select";
import {CreateNewTag} from '../CreateNewTag'
import Request  from '../../../../utils/http'
const request=new Request()
export const ModalLocation = ({ openModal, useData, handleSubmit ,closeModal}) => {
    const [options, setOptions] = useState([]);

    const [checking, setChecking] = useState({
      location: { label: "selecciona una opcion", value: 0 },
      type: 1,
      comments: "",
    });
    const [newItem, setNewItem] = useState(false);
    const sendedData=()=>{
      const response = handleSubmit(checking)
      if (response ) {
        setChecking({
          location: { label: "selecciona una opcion", value: 0 },
          type: 1,
          comments: "",
        })
      }
    }
    useEffect(() => {
      if (openModal) {
        useData > 0 && getData();
      }
    }, [openModal]);
    const getData = async () => {
      const response = await request.get(`tags/getTagUniversal/4`);
      if (response && response.statusCode == 200) {
        setOptions(response.result.data);
      }
    };
    const reloadTags = () => {
      setNewItem(false);
      getData();
    };
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "-35%",
        width: "97%",
        height: "59%",
        transform: "translate(-50%, -50%)",
      },
    };
    return (
      <Modal isOpen={openModal} shouldCloseOnEsc={true} onRequestClose={e=>closeModal(false)} shouldCloseOnOverlayClick={true} style={customStyles}>
        {newItem ? (
          <CreateNewTag {...{ setNewItem, newItem, reloadTags }} />
        ) : (
          <div className="card p-3 input-group-sm">
            <div className="d-flex justify-content-end input-group-sm">
              <button
                className="btn btn-sm btn-info"
                onClick={(e) => setNewItem(true)}
              >
                <i className="fa fa-plus font-15 "></i> ubicacion
              </button>
            </div>
            <label>Mis Ubicaciones</label>
            <Select options={options} value={checking.location} onChange={(value) => setChecking({ ...checking, location: value })} />
            <label htmlFor="type"></label>
            <select className="form-control" name="type" id="type" value={checking.type} onChange={(e) => setChecking({ ...checking, type: e.target.value })}>
              <option value="1">Entrada</option>
            </select>
  
            <label className="mt-2">Comentarios (opcional)</label>
            <textarea
              cols="30"
              className="form-control "
              rows="3"
              value={checking.comments}
              onChange={(e) =>
                setChecking({ ...checking, comments: e.target.value })
              }
            ></textarea>
            <button
              className="mt-3 btn btn-danger btn-sm"
              onClick={(e) => sendedData()}
            >
              <i class=" font-15 fa-solid fa-location-crosshairs"></i>registrar
            </button>
          
          </div>
        )}
      </Modal>
    );
  };