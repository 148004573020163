import React, { useEffect, useState } from "react";
import {Layout} from '../../Layouts/Layout'
// import { HorizontalTabs } from "../../../components/HorizontalTabs";

import { DaysToVacations } from "../DaysToVacations";
import { HorizontalTabs } from "../../../components/HorizontalTabs";
import { DisponibleDays } from "../DisponibleDays";



export const Gestionrrhh = () => {
 
const renderTabs = [ 
    {
        icono:'fa-regular fa-calendar-days',
        titulo:'Dias habiles y festivos',
        id:'Container',
        renderComponent:()=> <DisponibleDays  id={'diashabiles'} />,
       
    },
    {
        icono:'fa-solid fa-gears',
        titulo:'Gestion de dias de vacaciones',
        id:'Container2',
        renderComponent:()=><DaysToVacations />,
     
    },
]

  return (
    <Layout title="Gestion de Vacaciones">

    <HorizontalTabs renderTabs={renderTabs} />
    </Layout>
  );
};

