export function buscarPorId(idABuscar,arrayDeObjetos,propfind='id') {
  
    return arrayDeObjetos.find(objeto =>  objeto[propfind] == idABuscar);
  }
//   ejemplo:
//   const arrayDeUsuarios = [
//     { userId: 1, nombre: 'Juan', edad: 25 },
//     { userId: 2, nombre: 'María', edad: 30 },
//     { userId: 3, nombre: 'Pedro', edad: 22 },
//   ];
//   const usuarioEncontrado = buscarPorId(2, arrayDeUsuarios, 'userId');
// console.log(usuarioEncontrado);
// { userId: 2, nombre: 'María', edad: 30 }
