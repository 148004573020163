// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
// Tu configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDKnXpyQ21PVWYMwzUinHNL1U_ptfdycuw",
  authDomain: "chatsil83.firebaseapp.com",
  projectId: "chatsil83",
  storageBucket: "chatsil83.appspot.com",
  messagingSenderId: "909868995298",
  appId: "1:909868995298:web:e5a1f376bf8f531177c4bb",
  measurementId: "G-WJ1PLFDPX0"
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);

// Inicializa Firestore
const db = getFirestore(app);
const storage = getStorage(app);

export { db,storage};