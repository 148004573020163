import {useState,useEffect} from 'react'
import Request from '../../../../utils/http'
const request = new Request()
export const CreateNewTag = ({ reloadTags, setNewItem }) => {
    const [location, setLocation] = useState("");
  
    const handleSubmit = async () => {
      let data = {
        label: location,
        value: location,
        type: 4,
      };
      const response = await request.post("tags/createTagUniversal", data);
      if (response && response.statusCode === 200) {
        reloadTags();
      }
    };
    return (
      <>
        <div className="p-2 input-group-sm">
          <button
            className="btn btn-sm btn-danger "
            onClick={(e) => setNewItem(false)}
          >
            <i className="fa fa-reply font-15 "></i> volver
          </button>
          <div className="p-3">
            <input
              type="text"
              className="form-control"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            />
          </div>
        </div>
        <button className="btn btn-sm btn-dark m-4" onClick={handleSubmit}>
          Guardar
        </button>
      </>
    );
  };