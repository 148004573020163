
import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';

export default function Dognuts({inactivosCharts,activosCharts}) {
    console.log("🚀 ~ Dognuts ~ inactivosCharts:", inactivosCharts)

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {

        processData()
        const documentStyle = getComputedStyle(document.documentElement);
        const data = {
            labels: ['A', 'B', 'C'],
            datasets: [
                {
                    data: [540, 325, 702],
                    backgroundColor: [
                        documentStyle.getPropertyValue('--blue-500'), 
                        documentStyle.getPropertyValue('--yellow-500'), 
                        documentStyle.getPropertyValue('--green-500')
                    ],
                    hoverBackgroundColor: [
                        documentStyle.getPropertyValue('--blue-400'), 
                        documentStyle.getPropertyValue('--yellow-400'), 
                        documentStyle.getPropertyValue('--green-400')
                    ]
                }
            ]
        }
        const options = {
            plugins: {
                legend: {
                    labels: {
                        usePointStyle: true
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, [inactivosCharts]);
const processData = (data) => {
    console.log("🚀 ~ processData ~ data:", data)
    
}
    return (
        <>
            <div className="d-flex">
                <div className="card d-flex justify-content-between" style={{width:'230px'}}>
                    <Chart type="pie"  height='230' data={chartData} options={chartOptions} className="w-full md:w-10rem" />
                </div>
                <div className="card d-flex justify-content-between" style={{width:'230px'}}>
                    <Chart type="pie"  height='230' data={chartData} options={chartOptions} className="w-full md:w-10rem" />
                </div>
                <div className="card d-flex justify-content-between" style={{width:'230px'}}>
                    <Chart type="pie"  height='230' data={chartData} options={chartOptions} className="w-full md:w-10rem" />
                </div>
            </div>
        </>
    )
}
        