import { useState } from "react";
import Modal from "react-modal";
import './encuestas.css'
import { Checkbox } from "primereact/checkbox";
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from "primereact/inputtextarea";
import { FloatLabel } from "primereact/floatlabel";
import moment from 'moment-timezone'
import { collection, addDoc, doc, updateDoc, serverTimestamp, arrayUnion } from 'firebase/firestore';
import { db,storage } from '../../../../firebaseConfig';
import Request from "../../../../utils/http";
const request = new Request()

const AddEncuesta = ({ addEncuesta, setAddEncuesta,useData,setToastAlert }) => {

    const [loading,setLoading]=useState(false)
    const [preguntas, setPreguntas] = useState({
        pregunta: "",
        opciones: [],
        dateToResponse: moment().format('YYYY-MM-DD'),
        caption:'',
        multi:false
    })

    const handleAddOption = () => {
        let copy = JSON.parse(JSON.stringify(preguntas))
        copy.opciones.push({
            text: '',
            ids: [],

        })
        setPreguntas(copy)
    }
  

    const handleQuestion = (valor, posicion) => {
        let copy = JSON.parse(JSON.stringify(preguntas))
        copy.opciones[posicion].text = valor
        setPreguntas(copy)
    }

    const handleDelete=(position)=>{
        let copy = JSON.parse(JSON.stringify(preguntas))
        copy.opciones.splice(position,1)
        setPreguntas(copy)
    }
       const customStyles = {
         content: {
           top: "50%",
           left: "50%",
           right: "10%",
           bottom: "-35%",
           width: "97%",
           height: '68%',
           transform: "translate(-50%, -50%)",
         },
       };
    const handleSubmit=async()=>{
        setLoading(true)
        try {
          const postRef = await addDoc(collection(db, 'posts'), {
            userId: useData.id_personal,
            postedBy: useData.personalData.name + ' ' + useData.personalData.lastName,
            imageUrls: [], // Array de URLs de las imágenes
            caption: preguntas.caption,
            timestamp: serverTimestamp(),
            type:1,
            visibility:[{ label:"Todos",value:"0" }],
            data:preguntas,
            answered:[],
            likes: {likeNumber:0,usersIds:[],comments: [] }
          });
             setToastAlert({text:'Guardado con exito',type:'success'},)
             let data = {
                title:useData.personalData.name + ' '  + ' a creado una nueva encuesta en sil83.com',
                body: preguntas.caption
              }
              const response = await request.post('send-notification',data)
              console.log(response);
        } catch (error) {
           setToastAlert({text:'error al guardar',type:'error'},)
           console.log("🚀 ~ handleSubmit ~ error:", error)
            
        }
        setLoading(false)
    }
    return (
        <Modal style={customStyles} isOpen={addEncuesta} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true} onRequestClose={e => setAddEncuesta(false)}>
            <div className="text-center">
                <h3>Nueva encuesta</h3>
            </div>
            <div className="text-end">
            <label htmlFor={'multi'} className="ml-2">Permitir multiples respuestas &nbsp;</label>  
           <Checkbox inputId={'multi'} name="category" value={preguntas.multi} onChange={e=>setPreguntas({...preguntas,multi:e.checked})} checked={preguntas.multi} />
           </div>
            <FloatLabel className='mt-4'>
                <InputTextarea 
                    style={{borderColor:preguntas.caption==''?'red':''}}
                    value={preguntas.caption} 
                    onChange={(e) => setPreguntas({ ...preguntas, caption: e.target.value })}
                    rows={2} cols={33} />
                <label htmlFor="username">Descripcion de la encuesta </label>
            </FloatLabel>
            <label htmlFor=""> Fecha limite para responder</label>
            <input 
                type={'date'} 
                className="calendarinput form-control" 
                value={preguntas.dateToResponse} 
                onChange={(e) => setPreguntas({ ...preguntas, dateToResponse: e.target.value })} locale="es" />

            <FloatLabel className='mt-4'>
                <InputTextarea 
                    style={{borderColor:preguntas.pregunta==''?'red':''}}
                    value={preguntas.pregunta} 
                    onChange={(e) => setPreguntas({ ...preguntas, pregunta: e.target.value })}
                    rows={2} cols={33} />
                <label htmlFor="username">Escribe tu pregunta</label>
            </FloatLabel>
            
            <div className="d-flex justify-content-end  mt-2" >
        
                <button className="btn btn-primary p-1" onClick={handleAddOption}>agregar <i className="fa fa-plus font-15"></i></button>
            </div>

            {preguntas.opciones.map((opcion, k) => {
               let border = opcion.text==''? 'red':''
                return (
                    <div className="mt-2 d-flex" key={k}>
                        <InputText
                             style={{width:'90%',borderColor:border}} 
                             value={opcion.text} 
                             type="text" 
                             className="p-inputtext-sm" 
                             placeholder={`respuesta ${k + 1} `}
                             onChange={e => handleQuestion(e.target.value, k)} 
                        />
                        <button className="btn p-1" onClick={e=>handleDelete(k)}> <i className="fa fa-trash text-danger font-25"></i></button>
                    </div>
                )
            })}
                <div className="text-end mt-5">
                    <button className="btn" onClick={e => setAddEncuesta(false)}>cancelar</button>
                   { preguntas.opciones.length>0&& <button onClick={handleSubmit} className="ml-2 btn btn-dark"><i className={`${loading?'fa fa-spin fa-spinner':'fa fa-upload'} font-15`}></i>
                     {loading?' publicando...':' publicar encuesta'}
                    </button>}
                </div>
          
        </Modal>
    );
}

export default AddEncuesta;



