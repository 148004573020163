/**
 *  @version 1.0.1
 *  @author alexis
 *  @description Manejo de todas las rutas de la aplicación
 * 	@process 3
 */

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./private";
import { LoginPage } from "./imports.js";
import { DashboardHumanResourses } from "../pages/Home/DashboardHumanResourses";
import {Colaborators} from "../pages/Colaborators";
import { GroupsAndDepartments } from "../pages/GroupsAndDepartments";
import { Permisions } from "../pages/Permisions";
import { Puestos } from "../pages/Organigrama/Puestos";
import { HomePage } from "../pages/Home/HomePage/index.jsx";
import { VacationsMovilView } from "../pages/VacationsMovilView/index.jsx";
import { VacationsConfig } from "../pages/VacationsConfig/index.jsx";
import { CheckingTime } from "../pages/CheckingTime/index.jsx";
import { CheckingTimeUsers } from "../pages/CheckingTimeUsers/index.jsx";
import { VacationsDeptConfig } from "../pages/VacationsDeptConfig/index.jsx";
import { VacationshrConfig } from "../pages/VacationshrConfig/index.jsx";
import { CheckingPersonal } from "../pages/AsistencesPersonal/CheckingPersonal/index.jsx";
import { HomeDireccion } from "../pages/Home/HomeDireccion/index.jsx";
import {Gestionrrhh} from '../pages/configuration/GestionRRHH/index.jsx'
import { Apps } from "../pages/Home/apps/index.jsx";
import { CheckOfTheDays } from "../pages/CheckOfTheDays/index.jsx";
import { Faqs } from "../pages/Faqs/index.jsx";
import { Messages } from "../pages/Messages/index.jsx";
import { NotificationGeo } from "../pages/Faqs/NotificationGeo.jsx";
import { Organigrama } from "../pages/Organigrama/Organigrama.jsx";
import { Pruebas } from "../pages/Pruebas/index.jsx";
import { Encuestas } from "../pages/Encuestas/index.jsx";
import { Solicitudes } from "../pages/Solicitudes/index.jsx";
import { RotacionPersonal } from "../pages/RotacionPersonal/index.jsx";

const routes = [
  { path: "/login", element: <LoginPage /> },
  { path: "/homeDireccion", element: <HomeDireccion /> },
  { path: "/humanResourses", element: <DashboardHumanResourses /> },
  { path: "/asistencias", element: <Colaborators /> },
  { path: "/departmentandgroups", element: <GroupsAndDepartments /> },
  { path: "/userconfiguration", element: <Permisions /> },
  { path: "/puestos", element: <Puestos /> },
  { path: "/homapage", element: <HomePage /> },
  { path: "/vacationsMobile/:urlToBack", element: <VacationsMovilView /> },
  { path: "/vacations", element: <VacationsConfig /> },
  { path: "/vacationsDepartment", element: <VacationsDeptConfig /> },
  { path: "/vacationsRRHH", element: <VacationshrConfig /> },
  { path: "/checking/:urlToBack", element: <CheckingTime /> },
  { path: "/checkingUsers", element: <CheckingTimeUsers /> },
  { path: "/asistenciasoficina", element: <CheckingPersonal /> },
  { path: '/gestionrrhh', element:<Gestionrrhh />},
  { path: '/organigrama', element:<Organigrama />},
  { path: '/rotacion', element:<RotacionPersonal />},
 
  
  
];

const RoutesModule = () => (
 
    <BrowserRouter basename={"/"}>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/apps" element={<Apps />} />
          <Route path="/CheckOfTheDays/:urlToBack" element={<CheckOfTheDays />} />
          <Route path="/faq" element={<Faqs />} />
          <Route path="/NotificationGeo" element={<NotificationGeo />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/pruebas" element={<Pruebas />} />
          <Route path="/surveyManagement" element={<Encuestas />} />
          <Route path="/solicitudes/:urlToBack" element={<Solicitudes />} />
          <Route path="/" element={<PrivateRoute />} exact>
              {routes.map((route, idx) => (  <Route key={idx} path={route.path} exact element={route.element} />  ))}
          </Route>
        </Routes>
    </BrowserRouter>

);

export default RoutesModule;
