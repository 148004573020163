import React, { useEffect, useState } from "react";


export const VerticalTabs = ({ renderTabs = [] }) => {
    const [renderTab,setRenderTab]=useState([])
    const [activeTab,setActiveTab]=useState(0)
    const handleClick = (tab,i) => {
       
    
        setRenderTab([tab])
        setActiveTab(i)
    }
    useEffect(() => {
      if (renderTabs.length> 0 && renderTab.length==0) {
  
        setRenderTab([renderTabs[0]])
        setActiveTab(0)
    }
  }, [renderTabs]);
  return (
    <div className="row mb-5 mt-2" >
      <div className="col-lg-3">
        <div className="card position-sticky top-1">
          <ul className="nav flex-column bg-white border-radius-lg p-3 ">
            {renderTabs.map((tab, i) => {
              return (
                <li className={`nav-item pt-2 radius-15 ${activeTab==i?'active':''} clickeableItem`} key={i} onClick={e=>handleClick(tab,i)}>
                  <a className="nav-link text-body" data-scroll href={`#${tab.id}`}>
                    <div className="icon me-2">
                      <i className={`${tab.icono} text-dark`}></i>
                    </div>
                    <span className=" font-12">{tab.titulo}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="col-lg-9 mt-2 lg-0 ">
        {renderTab.map((tab, i) => {
          return (
            <div key={`tab${i}`} className="card " id={`tab${tab.id}`}>
                {tab.renderComponent()}
            </div>
          );
        })}
      </div>
    </div>
  );
};
//ejemplo de props
// const renderTabs = [ 
//     {
//         icono:'fa fa-ship',
//         titulo:'Contenedores',
//         id:'Container',
//         renderComponent:()=><ContainerConfiguration />,
//     },
//     {
//         icono:'fa fa-ship',
//         titulo:'Puertos',
//         id:'Container2',
//         renderComponent:()=><PortsConfiguration />,
//     },
//     {
//         icono:'fa fa-ship',
//         titulo:'Etapas',
//         id:'Container3',
//         renderComponent:()=><EtapasConfiguration />,
//     },
// ]