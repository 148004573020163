import React from 'react'
import { LayoutMovile } from '../Layouts/LayoutMovile'
import princi from '../../static/princi.png'
import pantallacheck from '../../static/pantallacheck.png'
import modcheck from '../../static/modcheck.png'
import pantoptions from '../../static/pantoptions.png'
import reg from '../../static/reg.png'
import regvisit from '../../static/regvisit.png'
import visitgeo from '../../static/visitgeo.png'
import d from '../../static/d.png'
export const Faqs = () => {
  return (
    <LayoutMovile showTabs={false}>
        <div class="accordion-1">
        <div class="container">
          <div class="row my-5">
            <div class="col-md-6 mx-auto text-center">
              <h2>Preguntas frecuentes</h2>
              <p>Aqui se listarán algunas respuestas a las preguntas mas frecuentes con respecto a la plataforma sil83 </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-10 mx-auto">
              <div class="accordion" id="accordionRental">
                <div class="accordion-item mb-3">
                  <h5 class="accordion-header" id="headingOne">
                    <button class="accordion-button border-bottom font-weight-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    como registar mi entrada y salida de la oficina?
                      <i class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                      <i class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                    </button>
                  </h5>
                  <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionRental" >
                    <div class="accordion-body text-sm opacity-8">
                      <ul>
                          <li >
                              <small class="text-dark">identificar el icono de app´s en la plataforma sil83, dar click en el icono.</small>
                            <div className='d-flex justify-content-center p-2'>
                            <img src={princi} alt="imagen principal" style={{height:'200px',width:'100px'}} />
                            </div>
                          </li>
                          <li>
                              <small>seleccionar la opcion Check In, dar click en el icono.</small>
                              <div className='d-flex justify-content-center p-2'>
                                  <img src={pantallacheck} alt="imagen principal" style={{height:'200px',width:'100px'}} />
                                  
                            </div>
                              
                          </li>
                          <li>
                              <small>sobre el modal dar click  en "registrar". </small>
                              <div className='d-flex justify-content-center p-2'>
                                  <img src={modcheck} alt="imagen principal" style={{height:'200px',width:'100px'}} />
                                  
                            </div>
                            <small>tu ingreso o salida ha quedado registrado automaticamente</small>
                            
                          </li>
                          <li>
                          <small> se pueden agregar comentarios de manera opcional. ej: salida de oficina por emergencia medica y se requiere dejar registro para RRHH. </small>
                          </li>
                        
                          <li>
                              <small className="text-danger "> Ten en cuenta que todo registro se encuentra geolocalizado y se podra saber el lugar exacto desde el que se hizo el registro</small>
                          </li>
                      </ul>
                    
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3">
                  <h5 class="accordion-header" id="headingTwo">
                    <button class="accordion-button border-bottom font-weight-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Como puedo saber si se registro correctamente mi check In?
                      <i class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                      <i class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                    </button>
                  </h5>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionRental">
                    <div class="accordion-body text-sm opacity-8">
                    <ul>
                      <li>
                          <small> Ubicar y dar clic en la opcion de "Mis checks del dia"</small>
                          <div className='d-flex justify-content-center p-2'>
                                  <img src={pantoptions} alt="imagen principal" style={{height:'200px',width:'100px'}} />
                                  
                            </div>
                      </li>
                      <li>
                          <small> Se listaran todos tus checks que se hayan hecho durante el dia</small>
                          <div className='d-flex justify-content-center p-2'>
                                  <img src={reg} alt="imagen principal" style={{height:'200px',width:'100px'}} />
                                  
                            </div>
                            <small> Si los registros se han guardado exitosamente, apareceran listados en orden cronologico</small>
                      </li>
                    </ul>
                    
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3">
                  <h5 class="accordion-header" id="headingThree">
                    <button class="accordion-button border-bottom font-weight-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      como registrar una visita a un lugar fuera de la oficina?
                      <i class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                      <i class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                    </button>
                  </h5>
                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionRental">
                    <div class="accordion-body text-sm opacity-8">
                    <ul>
                      <li>
                          <small> Ubicar y dar clic en la opcion de "Registrar Visita"</small>
                          <div className='d-flex justify-content-center p-2'>
                                  <img src={regvisit} alt="imagen principal" style={{height:'200px',width:'100px'}} />
                                  
                            </div>
                      </li>
                      <li>
                          <small> Ubicar y dar clic en la opcion de "Registrar Check In"</small>
                          <div className='d-flex justify-content-center p-2'>
                                  <img src={visitgeo} alt="imagen principal" style={{height:'200px',width:'100px'}} />
                            </div>
                      </li>
                      <li>
                          <small> seleccionar una ubicacion del apartado mis ubicaciones </small>
                          <div className='d-flex justify-content-center p-2'>
                                  <img src={d} alt="imagen principal" style={{height:'200px',width:'100px'}} />
                            </div>
                          <small> Dar click en registrar </small>
                      </li>
                      <li>
                          <small>Una vez cerrada la ventana de registro se visualizaran todos los registros que se hayan realizado: </small>
                          <div className='d-flex justify-content-center p-2'>
                                  <img src={visitgeo} alt="imagen principal" style={{height:'200px',width:'100px'}} />
                            </div>
                          
                      </li>
                    </ul>
                    
                      </div>
                  </div>
                </div>
                {/* <div class="accordion-item mb-3">
                  <h5 class="accordion-header" id="headingFour">
                    <button class="accordion-button border-bottom font-weight-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                      Can I resell the products?
                      <i class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                      <i class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                    </button>
                  </h5>
                  <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionRental">
                    <div class="accordion-body text-sm opacity-8">
                      I always felt like I could do anything. That’s the main thing people are controlled by! Thoughts- their perception of themselves! They're slowed down by their perception of themselves. If you're taught you can’t do anything, you won’t do anything. I was taught I could do everything.
                      
                      If everything I did failed - which it doesn't, it actually succeeds - just the fact that I'm willing to fail is an inspiration. People are so scared to lose that they don't even try. Like, one thing people can't say is that I'm not trying, and I'm not trying my hardest, and I'm not trying to do the best way I know how.
                    </div>
                  </div>
                </div>
                <div class="accordion-item mb-3">
                  <h5 class="accordion-header" id="headingFifth">
                    <button class="accordion-button border-bottom font-weight-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifth" aria-expanded="false" aria-controls="collapseFifth">
                      Where do I find the shipping details?
                      <i class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                      <i class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                    </button>
                  </h5>
                  <div id="collapseFifth" class="accordion-collapse collapse" aria-labelledby="headingFifth" data-bs-parent="#accordionRental">
                    <div class="accordion-body text-sm opacity-8">
                      There’s nothing I really wanted to do in life that I wasn’t able to get good at. That’s my skill. I’m not really specifically talented at anything except for the ability to learn. That’s what I do. That’s what I’m here for. Don’t be afraid to be wrong because you can’t learn anything from a compliment.
                      I always felt like I could do anything. That’s the main thing people are controlled by! Thoughts- their perception of themselves! They're slowed down by their perception of themselves. If you're taught you can’t do anything, you won’t do anything. I was taught I could do everything.
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
</div>
    </LayoutMovile>
  )
}
