import React, { useState } from 'react'
import { SimpleModal } from '../../../../../components/ReactModal/SimpleModal'
import Request from '../../../../../utils/http'
import moment from 'moment-timezone'
import Toast from '../../../../../components/Toast/Toast'
const request = new Request()
export const ModalAddVaction = ( {usrp,reload}) => {
 console.log("🚀 ~ ModalAddVaction ~ usrp:", usrp)
 
  const [toastAlertProps,setToastAlert]=useState({text:'',type:''})
  const [solicitud,setSolicitud]=useState({
    type:1,
    start:moment().format('YYYY-MM-DD'),
    end:moment().format('YYYY-MM-DD'),
    dayToComeBack:'',
    comments:'',
  })
  const [dias,setTotalDays]=useState(0)

  const handleSubmit=async()=>{
    let vacationsDays=JSON.parse(JSON.stringify(solicitud))
    let inmmBoss= JSON.parse(usrp.inmBoss)
    let dept=JSON.parse(usrp.dept)
    vacationsDays.id_inmboss=inmmBoss.value
    vacationsDays.id_workareaBoss=dept.value
    
    const response = await request.post(`events/nuevaSolicitdrrhh/${usrp.id}`,vacationsDays)
    if (response && response.statusCode==200) {
    
      reload(usrp.id)
      setSolicitud({
        type:0,
        start:'',
        end:'',
        dayToComeBack:'',
        comments:'',
      })
      setToastAlert({text:'Guardado con exito',type:'success'},)
    }
  }
const handleTime=(value,bandera) => {
 
  let  fecha1=''
  let  fecha2=''

  if (bandera=='start') {
     fecha1 = new Date(value); // Fecha inicial
     fecha2 = new Date(solicitud.end); 
  }else{
    fecha1 = new Date(solicitud.start); // Fecha inicial
    fecha2 = new Date(value); 
  }
  // Calcular la diferencia en milisegundos
  // Convertir la diferencia a días
  var diferenciaEnMilisegundos = fecha2 - fecha1;
  var diferenciaEnDias = diferenciaEnMilisegundos / (1000 * 60 * 60 * 24);
  setTotalDays( diferenciaEnDias+1)
  fecha2.setDate(fecha2.getDate() + 2);
  let dyacome=fecha2.toISOString()
  setSolicitud({...solicitud,[bandera]:value,dayToComeBack:moment(dyacome).format('YYYY-MM-DD')})
}


  return (
    <>
    <button data-bs-toggle="modal" data-bs-target={`#ModalAddVaction`} className="btn btn-dark btn-sm">Agregar vacaciones/Permisos</button>
    <SimpleModal identifier={'ModalAddVaction'}>

    <Toast {...{toastAlertProps}} />
      <div className='p-3'>
        <div>
          <label >Seleccione el tipo de solicitud</label>
          <select onChange={e=>setSolicitud({...solicitud,type:e.target.value})} value={solicitud.type} className="form-control">
            <option value="0">Selecciona una opcion</option>
            <option value="1">Vacaciones</option>
            <option value="2">Permiso con goce de sueldo</option>
            <option value="3">Permiso sin goce de sueldo</option>
          </select>
        </div>
        <div>
          <label > Inicio</label>
          <input onChange={e=>handleTime(e.target.value,'start')} value={solicitud.start} type="date" class="form-control" />
        </div>
        <div>
          <label > Fin</label>
          <input onChange={e=>handleTime(e.target.value,'end')} value={solicitud.end} type="date" class="form-control" />
        </div>
        <div>
          <label > Fecha de Presentacion</label>
          <input onChange={e=>setSolicitud({...solicitud,dayToComeBack:e.target.value})} value={solicitud.dayToComeBack} type="date" class="form-control" />
        </div>
        <div className="  font-25 text-center"> Dias solicitados: {dias} </div>
        <div>
          <label > Comentarios</label>
          <textarea onChange={e=>setSolicitud({...solicitud,comments:e.target.value})} value={solicitud.comments} type="date" class="form-control" />
        </div>
        <div className="tex-end mt-5 text-end" >
          <button className="btn bg bg-gradient-dark" onClick={handleSubmit}> registra solicitud </button>
        </div>


      </div>

    </SimpleModal>
    
    
    </>
  )
}
