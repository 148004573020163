import React, { useEffect, useState } from 'react'
import Request from '../../../../utils/http'
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../../firebaseConfig'
const request = new Request()


export const DepartamentasChats = ({setNameDepto}) => {
    function removeAccents(text) {
        return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      }
   
    const [depst,setDepst]=useState([])


    useEffect(() =>{
        getData()
    },[])

    const getData = async() =>{
        
        const response = await request.get('information/departments')
        let items=[]
        let depts = []
        if (response && response.statusCode === 200) {
            response.result.datos.Department.forEach(element => {
                
              items.push(removeAccents(element.description.replace(/ /g, "")))
              depts.push(element.description)

            });
        }
        setDepst(items)
        // fetchCollections(items)
    }
    // const fetchCollections = async (collectionNames) => {
    //     console.log("🚀 ~ fetchCollections ~ collectionNames:", collectionNames)
    //     const collectionsData = [];
    //     for (const name of collectionNames) {
    //       const colRef = collection(db, name);
    //       const snapshot = await getDocs(colRef);
    //      if (snapshot) {
    //         collectionsData[name] = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    //      }
    //     }
       
    //     setCollections(collectionsData);
    //   };
  return (
    <>
    {depst.map((line)=>{
        return(
            <div  class=" p-2 mt-2 border-radius-lg bg-gradient-info shadow" onClick={e=>setNameDepto(line)}>
                <div class="d-flex justify-content-between p-2">
                    <img alt="Image" src="https://cdn-icons-png.flaticon.com/512/748/748637.png" class="avatar shadow"/>
                    <div class="ms-2">
                    <div class="justify-content-between align-items-center">
                        <h6 class="text-white text-xs mb-0"> {line}
                        <span class="badge badge-success"></span>
                        </h6>
                        {/* <p class="text-white mb-0 text-sm">Typing...</p> */}
                    </div>
                    </div>
                    <div class="align-end">
                    <h6 className='font-25'><i class="fa-solid fa-chevron-right text-white"></i></h6>
                    </div>
                </div>
            </div>
        )
    })}
   
      
    
    </>
  )
}
