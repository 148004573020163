import React, { Fragment, useEffect, useState } from 'react'
import { TableInfinityFixed } from '../../../../../components/TableInfinityFixed'
import Request from '../../../../../utils/http'
import { UploadDocument } from '../UploadDocument'
import { SimpleModal } from '../../../../../components/ReactModal/SimpleModal'
import moment from 'moment'
const request = new Request()
export const TableDocuments=({user})=>{
    const [docs,setDocs]=useState([])
    const [typedocument,setTipe]=useState(null)
  
    useEffect(()=>{
      getData()
    },[])
  
  const handleClose = ()=>{
   
    setTipe(null)
  }

    const types=[ 
    {typeDoc:'2',label:'Acta de Nacimiento',url:''},
    {typeDoc:'22',label:'Alta IMSS',url:''},
    {typeDoc:'15',label:'Antidoping',url:''},
    {typeDoc:'11',label:'Antecedentes No Penales',url:''},
    {typeDoc:'20',label:'Aviso de Privacidad',url:''},
    {typeDoc:'5',label:'Aviso de Retencion de INFONAVIT',url:''},
    {typeDoc:'6',label:'CURP',url:''},
    {typeDoc:'14',label:'Certificado Medico',url:''},
    {typeDoc:'21',label:'Contrato Inicial 1',url:''},
    {typeDoc:'28',label:'Contrato Inicial 2',url:''},
    {typeDoc:'29',label:'Contrato Inicial 3',url:''},
    {typeDoc:'25',label:'Contrato Indeterminado',url:''},
    {typeDoc:'3',label:'Comprobante de Domicilio',url:''},
    {typeDoc:'9',label:'Cartas de Recomendacion',url:''},
    {typeDoc:'23',label:'Contrato de Tarjeta de Nomina',url:''},
    {typeDoc:'7',label:'Constancia de Situacion Fiscal',url:''},
    {typeDoc:'13',label:'Copia de Acta de Matrimonio (Si lo esta)',url:''},
    {typeDoc:'19',label:'Carta de Correo',url:''},
    {typeDoc:'27',label:'Carta de No Conflicto de Intereses',url:''},
    {typeDoc:'30',label:'Carta de Oferta Laboral',url:''},
    {typeDoc:'31',label:'Curriculum Vitae (CV)',url:''},
    {typeDoc:'8',label:'Comprobante de Estudios',url:''},
    {typeDoc:'10',label:'Fotografias',url:''},
    {typeDoc:'16',label:'Evaluacion 1er Mes',url:''},
    {typeDoc:'17',label:'Evaluacion 2do Mes',url:''},
    {typeDoc:'18',label:'Evaluacion 3er Mes',url:''},
    {typeDoc:'4',label:'Identificacion Oficial',url:''},
    {typeDoc:'12',label:'Licencia de Conducir (segun Puesto)',url:''},
    {typeDoc:'26',label:'Pruebas Psicometricas',url:''},
    {typeDoc:'1',label:'Solicitud de Empleo',url:''},
    {typeDoc:'24',label:'Vale de Uniforme',url:''},
]
  const getData=async()=>{
    let copy = JSON.parse(JSON.stringify(types))
    const response = await request.get(`getDocuments/${user.id}`)
    
    if (response && response.statusCode === 200) {
      response.result.data.forEach(element => {
        copy.forEach((cop)=>{
          cop.show=false
          if (cop.typeDoc==element.type) {
            cop.url=element.file_url
          }
        })
        
      });
    }
    setDocs(copy)
  }
  
    const header=[
     
      {label:'Documento'},
      {label:'Archivo',classColumn:'ocultarmobile'},
      {label:'Historial de documentacion',classColumn:'ocultarmobile'},
    
    ]
    const showMore=(position)=>{
      const copy=JSON.parse(JSON.stringify(docs))

      copy[position]['show']=!docs[position]['show']
      setDocs(copy)
      
    }
   
    return(
      <div className="card m-3">
        <TableInfinityFixed columnsParam={header} aditionalClass='table-striped'>
          {docs.map((type,i)=>{
  
          
            return(
              <Fragment key={i}>
              <tr >
               
                <td className='d-flex justify-content-between p-4'>
                  <h6 className='text-xs'>{type.label}</h6>
                  <button className='btn btn-dark btn-sm p-1 buttonMovile 'onClick={e=>showMore(i)} ><i className={`fa-solid fa-angle-${type.show?'down':'right'} font-15`}></i></button>
                </td>
                <td className='ocultarmobile'>
                  <UploadDocument typeDoc={type.typeDoc} url={type.url} user={user} reload={getData} typeprop={type}/>
                </td>
                <td className='input-group-sm ocultarmobile'>
                  <button className='btn btn-sm' onClick={e=>setTipe(type.typeDoc)} data-bs-toggle="modal" data-bs-target={`#modalDocumentation`}><i class="fa-solid font-15 fa-clock-rotate-left"></i> 
                     historial
                  </button>
                </td>
              </tr>
             {type.show&& <tr>
                <td className=' d-flex input-group-sm justify-content-between'>
                  <UploadDocument typeDoc={type.typeDoc} url={type.url} user={user} reload={getData} typeprop={type}/>
                  <button className='btn btn-sm' onClick={e=>setTipe(type.typeDoc)} data-bs-toggle="modal" data-bs-target={`#modalDocumentation`}><i class="fa-solid font-15 fa-clock-rotate-left"></i> 
                   historial</button>
                </td>
              
              </tr>}
              
              
              </Fragment>
            )
          })}
  
        </TableInfinityFixed>
       <ModalHistory typedocument={typedocument} id={user.id} types={types} handleClose={handleClose} />
      </div>
    )
  }
  
  const ModalHistory=({typedocument,id,types,handleClose}) => {
      const [documentacion,setDocumentacion]=useState([])
    useEffect(()=>{
      if (typedocument!=null) {
        getData()
      }
    },[typedocument])

   
    const getData=async()=>{
      const response = await request.get(`getDocumentsHistory?id=${id}&type=${typedocument}`)
      if (response && response.statusCode==200) {
      
        response.result.data.forEach(item => {
          const registroEncontrado = types.find(item => item.typeDoc === typedocument);
           item.label=registroEncontrado.label
        });
        setDocumentacion(response.result.data)
      }
      handleClose(null)
    }
    return(
     <div>
       <SimpleModal identifier={'modalDocumentation'} handleCloseFunction={e=>handleClose()}>
        <div className="p-3">
          Historial de documentacion
          <ul >
            {documentacion.map((doc,i)=>{
              return(
                  <li key={i}>
                    <h6 className='text-xs  '>fecha de captura:{moment(doc.created_at).format('DD/MM/YYYY')}</h6>
                    <a className="btn" target="_blank" href={doc.file_url}>{doc.current}{doc.file_name}  <i className="fa-solid fa-arrow-up-right-from-square"></i></a>
                  </li>
                  )
                })}
          </ul>
        </div>

      </SimpleModal>
     </div>
    )
  }
  



