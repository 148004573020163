
import { InputText } from 'primereact/inputtext'
import { InputTextarea } from 'primereact/inputtextarea'
import React, { Fragment, useEffect, useState } from 'react'
import { Divider } from 'primereact/divider';
import Modal from 'react-modal'
import { Checkbox } from 'primereact/checkbox'
import { ToastAlert } from '../../../components/ToastAlert';
import Request from '../../../utils/http'
import { TableInfinityFixed } from '../../../components/TableInfinityFixed';
import { TableEncuesta } from '../TableEncuestas';
const request = new Request()
export const CreationSurvey = () => {
  const [openModal, closeModal] =useState(false)
  const [data,setData] = useState([])


  const [toastAlertProps,setToastAlert]=useState({text:'',type:''})

  useEffect(()=>{
    getData()


  },[])

  const getData = async() =>{
    const respuesta=await request.get('encuestas/getEncuestas')
 
   if(respuesta && respuesta.statusCode==200){

    setData(respuesta.result.data)
   }
  }
  return (
    <>
     <div className="d-flex justify-content-end">
     <ToastAlert {...{toastAlertProps}} />
        <button className="btn btn-dark btn-sm" onClick={e=>closeModal(true)}>crear encuesta</button>
     </div>

    <ModalEncuesta {...{openModal, closeModal,setToastAlert}} />
    <TableEncuesta encuestas={data}/>

    </>
  )
}
const ModalEncuesta =({openModal, closeModal,setToastAlert})=> {
  const [description,setDescription]=useState('')
  const [preguntas,setPreguntas]=useState([])
  const [type,setType]=useState('')
  const [loading,setLoading]=useState(false)

  const handleAddQuestion =()=> {
    let copy = JSON.parse(JSON.stringify(preguntas))
    copy.push({question:'',isMulti:false,options:[] })
    setPreguntas(copy)
  }
  const handleQuestion=(valor,i,bandera)=>{
    setPreguntas(prevItems => {
      const updatedItems = JSON.parse(JSON.stringify(prevItems)) // Crear una copia del array
      updatedItems[i] = { ...updatedItems[i], [bandera]: valor }; // Modificar el objeto en la posición específica
      return updatedItems;
    });
  }
  const handleAddResponse=(position)=> {
    let copy = JSON.parse(JSON.stringify(preguntas))
    copy[position].options.push({title:'',type:type,opciones:[]})
    setPreguntas(copy)
  }

  const handleResponse=(valor,position,questionidx)=>{
    let copy = JSON.parse(JSON.stringify(preguntas))
        copy[questionidx].options[position].title = valor
        setPreguntas(copy)
  }
 
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "10%",
      bottom: "-35%",
      width: "37%",
      height: '88%',
      transform: "translate(-50%, -50%)",
    },
  };
  const handleMulti=(position)=>{
    let copy = JSON.parse(JSON.stringify(preguntas))
    copy[position].isMulti = !copy[position].isMulti 
    setPreguntas(copy)
  }
  const handleSave=async()=>{
    setLoading(true)
    const copy = JSON.parse(JSON.stringify(preguntas))
    let data = {
      description:description,
      preguntas:copy
      }
   
     const response = await request.post('encuestas/addnewencuesta',data)
 
     if (response && response.statusCode === 200) {
       setToastAlert({text:'Guardado con exito',type:'success'},)
     }
    setLoading(false)
  
  } 
  const handleClose=()=>{
    setDescription('')
    setPreguntas([])
    setType('')
    closeModal(false)
  }
    return(
        <Modal isOpen={openModal} style={customStyles} onRequestClose={handleClose} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true} >
          
           <div className="d-flex justify-content-end">
              <button className='btn btn-dark btn-sm' onClick={handleAddQuestion}>agregar pregunta &nbsp; <i className='fa fa-plus font-15'></i></button>
           </div>
            <div className="col-12">
              <label for={`descriptions`}>Descripcion de la encuesta</label>
              <InputTextarea  id={`descriptions`} value={description} onChange={(e) => setDescription(e.target.value)} rows={2} style={{width:'100%'}} />
            </div>

            <Divider />
           {preguntas.map((pregunta,i)=>{
             
             return(
               <Fragment key={i} >
                <h3>Pregunta {i+1}</h3>
                  <div className='row '>
                      <div className="col-12 mt-2">
                          <InputText placeholder='Escribe tu pregunta' style={{width:'100%'}}  id={`pregunta${i}`} value={pregunta.question} onChange={(e) => handleQuestion(e.target.value,i,'question')} />
                      </div>
                      <div className="col-12">
                           <TipoRespuesta {...{setType}}/>
                      </div>
                      <div className="col-12 mt-3 ">
                         {type!=0 && <div className="d-flex justify-content-between">
                              <div className="">
                                <button className='btn btn-info btn-sm mt-2 p-2' onClick={e=>handleAddResponse(i)} >respuesta <i className='fa fa-plus font-15'></i></button>
                              </div>
                              <div >
                                <Checkbox inputId={'multi'} checked={pregunta.isMulti} name="category" onChange={(e) => handleMulti(i)}/>
                                <label htmlFor={'multi'} className="ml-2"> multiples respuestas &nbsp;</label>  
                              </div>
                          </div>}
                      </div>
                  </div>
                  <div className=''> 
                     { pregunta.options.map((line,j) => {
                      return(
                        <div className="d-flex justify-content-between">
                           <RenderResponse key={j} pregunta={line} position={j} questionidx={i} handleResponse={handleResponse} />
                           <button className="btn p-2"><i className='fa fa-trash font-20 text-danger'></i></button>
                        </div>
                      )
                     }  )}
                  </div>
                  <Divider />
              </Fragment>
             
            )
          })}
          <div className="text-end">
           {preguntas.length>0&& <button className='btn btn-dark' onClick={handleSave}><i className={loading?'fa fa-spinner fa-spin':'fa fa-save'}></i>{ loading? ' guardando...':' guardar'} </button>}
          </div>
        </Modal>
    )
}


const RenderResponse=({pregunta,position,handleResponse,questionidx}) => {

  return(
    pregunta.type == 1 ?
       <>
       <input type="text" style={{width:'80%'}} disabled className='form-control mt-2' onChange={e=>handleResponse(e.target.value,position,questionidx)}  placeholder='Aqui responderá el colaborador'/>
       </>
    :
      <div className="d-flex input-group-sm mt-2">  
          <Checkbox checked={true} disabled={true} />
          <input type="text" className='form-control ' onChange={e=>handleResponse(e.target.value,position,questionidx)} placeholder='Escribe respuesta'/>
      </div>
  )
}

const TipoRespuesta=({setType})=>{
  const [tipo,setTipo]=useState(0)

const handleType=(valor) => {
  setType(valor)
  setTipo(valor)
}
  

  return(
    <div className=" input-group-sm">
        <div>
          <label >Tipo de respuestas</label>
            <select className='form-control' style={{borderColor:tipo==0?'red':'',height:'40px'}} onChange={e=>handleType(e.target.value)}>
              <option value="0">Tipo de respuesta</option>
              <option value="1">Cuadro de texto </option>
              <option value="2">seleccionar una opción</option>
            </select>
        </div>
  
    </div>
  )
}

