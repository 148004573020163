
import { TabView, TabPanel } from 'primereact/tabview';
import { DayTable } from './DayTable';
import { useEffect, useState } from 'react';
import Request from '../../../utils/http';
import moment from 'moment-timezone';
import { calcularDistanciaEnMetros } from '../../../Hooks/calcularDistanciaEnMetros';
import { AsistencesReports } from './AsistencesReports';
import { AsistencesPersonal } from './AsistencesPersonal';
const request =new Request()
const AsistenceTable = () => {
    const [numdays, setNumDays] = useState(0)
    const [dataToExport, setDataToExport] = useState([])
    const [dataToExportInasistencias, setDataToExportInasistencias]=useState([])
    const [dataToExportRetardo, setDataToExportRetardos]=useState([])
    const [dataChecks, setDataChecks] = useState([]);
    const baseLatitud = 20.6208046
  const baseLongitud = -87.080208;
   const [typeOfView, setTypeOfView] = useState(0)
    const [days, setDays] = useState([]);
    const [dateDefault, setDateDefault] = useState(moment().format('YYYY-MM-DD'))
    const [dateEnd, setDateEnd] = useState(moment().format('YYYY-MM-DD'))
    const [loading, setLoading] = useState(false)
    const [inofice, setInofice] = useState([])
    const [daysOfMonth, setDaysOfMonth] = useState([]);
    const [daysCopy, setDaysCopy] = useState([]);
    const [dataExcels, setDataExcels] = useState([]);
    const [asistencias, setAsistencias] = useState([])
    const [inasistencias, setInAsistencias] = useState([])
    const [historial, setHistorico] = useState([])
    const [colaborators, setColaborators] = useState([]);
    const changeDate = (date) => {
        setDateDefault(moment(date).format('YYYY-MM-DD'))
        setDateEnd(moment(date).format('YYYY-MM-DD'))
      }
      const getChecksRange = async () => {
        const response = await request.get(`locations/show?date=${dateDefault}`);
    
      };
      const getChecks = async () => {
        setLoading(true)
        if (typeOfView == 2) {
          getChecksRange()
          return;
        }
    
        const response = await request.get(`locations/show?date=${dateDefault}`);
    
        let inOffice = []
        response.result.asistencias.forEach(element => {
          if (element.registros.entradas.length > element.registros.salidas.length) {
            inOffice.push(element)
          }
        });
    
        setInofice(inOffice)
    
        setDaysOfMonth(response.result.data);
        setDays(response.result.s);
        setDaysCopy(response.result.s);
    
        setAsistencias(response.result.asistencias)
        setInAsistencias(response.result.inasistencias)
        setHistorico(response.result.historico)
        let items = []
        response.result.data.forEach((element, i) => {
    
          items.push({ colaborador: '', tipo: '', hora: '', comentarios: element, latitud: '', longitud: '', distanciaOficina: '' })
          response.result.s.forEach((persona) => {
            persona.checs[i].forEach(checkArr => {
              if (checkArr) {
                const distancia = calcularDistanciaEnMetros(baseLatitud, baseLongitud, checkArr.latitude, checkArr.longitude);
                items.push({
                  colaborador: persona.name + ' ' + persona.lastName,
                  tipo: checkArr.type == 1 ? 'Entrada' : 'Salida',
                  hora: moment(checkArr.time).format('DD/MM/YYY HH:mm:ss'),
                  comentarios: checkArr.comments,
                  latitud: checkArr.latitude,
                  longitud: checkArr.longitude,
                  distanciaOficina: distancia
                })
              }
            });
          });
        });
        setDataExcels(items);
        var fechaActual = new Date(dateDefault);
    
        // Restarle 1 día
        fechaActual.setDate(fechaActual.getDate() - 1);
    
        // Obtener el día en número
        var diaNumero = fechaActual.getDate();
        setNumDays(diaNumero)
        setLoading(false)
      };
      const handleModal = (linea) => {

        const arrayConcatenado = linea;
        setDataChecks(arrayConcatenado)
      }
      useEffect(() => {
        getChecks();
        getInfo();
      }, []);
      const getInfo = async () => {
        try {
          const response = await request.get("information/departments/personal", []);
    
    
          if (response && response.result.success === true) {
            let personas = [];
            response.result.datos.personal.forEach((item) => {
              personas.push({
                label: `${item.name} ${item.lastName}`,
                value: item.id,
                email: item.email,
              });
            });
            setColaborators(personas);
          }
        } catch (error) {
          console.log("getData -> error", error);
        }
      };
    return (

        <TabView>
            <TabPanel header="Tabla de asistencia">
                <div className='d-flex justify-content-between mb-2'>
                    <div className='d-flex'>
                        <SelectTypeSection {...{ typeOfView, setTypeOfView }} />
                        <DatesSection {...{ dateDefault, changeDate, setDateEnd, typeOfView, dateEnd }} />
                            <div className='input-group-sm'>
                            <button className="btn btn-dark mt-4 ml-3" onClick={getChecks}>{loading ? 'Buscando...' : 'Buscar'} &nbsp; <i className={`fa ${loading ? 'fa-spin fa-spinner' : 'fa-search'}`}></i></button>
                        </div>
                    </div>
                    <AsistencesReports />


                </div>
                <DayTable {...{ days, handleModal, numdays, dateDefault, setDataToExport, setDataToExportInasistencias, setDataToExportRetardos }} />
            </TabPanel>
            <TabPanel header="Reportes">
               <AsistencesPersonal />
            </TabPanel>
        </TabView>
    );
}

export { AsistenceTable };


const SelectTypeSection = ({ typeOfView, setTypeOfView }) => {
    return (
      <div className='input-group-sm'>
        <label >Tipo de visualizacion</label>
        <select className="form-control" style={{ width: '200px' }} value={typeOfView} onChange={e => setTypeOfView(e.target.value)}>
          <option value="0">Dia</option>
          <option value="1">Mes</option>
          <option value="2">Rango</option>
        </select>
  
      </div>
    )
  }
  const DatesSection = ({ dateDefault, changeDate, setDateEnd, typeOfView, dateEnd }) => {
    return (
      <>
        <div className='input-group-sm'>
          <label> Selecciona fecha inicio   </label>
          <input type="date" className="form-control" style={{ width: '200px' }} value={dateDefault} onChange={e => changeDate(e.target.value)} />
        </div>
        {typeOfView == 2 &&
          <div className='input-group-sm'>
            <label>  Selecciona fecha fin  </label>
            <input type="date" className="form-control" style={{ width: '200px' }} value={dateEnd} onChange={e => setDateEnd(moment(e.target.value).format('YYYY-MM-DD'))} />
          </div>
        }
  
  
      </>
    )
  }
  