import { useEffect, useState } from "react";
import { TableInfinityFixed } from "../../../../components/TableInfinityFixed";
import Request from "../../../../utils/http";
import moment from "moment-timezone";
import { Calendar } from 'primereact/calendar';

import { ModalInformation } from "./ModalInformation";
import { ModalRetardos } from "./ModalRetardos";
import { ModalInasistences } from "./ModalInasistences";
import { buscarPorId } from "../../../../Hooks/buscarEnObjeto";
import { calcularDiferenciaHoras, extractTime, sumarHoras, sumarTiempoHora } from "../../../../Hooks/timeTransform";
import { determinarTipo } from "../../../../Hooks/isObject";
        
const request=new Request()

const header = [
    {label:'Colaborador'},
    {label:'Asistencias'},
    {label:'Retardos'},
    {label:'Inasistencias'},
    {label:'Horas trabajadas'},
    {label:'Fuera de rango '},
]
function AsistencesPersonal() {
    const baseLatitud = 20.6208046
    const baseLongitud = -87.080208;
    const [data,setData]=useState([])
    const [inicio,setInicio]=useState(null)
    const [fin,setFin]=useState(null)
    const [loading,setLoading]=useState(false)
    const [type,setType]=useState(0)
    const [lineas,setLineas]=useState(null)
    const [openModal,setOpenModal]=useState(false)
    const [openModalRetardos,setOpenModalRetardos]=useState(false)
    const [openModalFaltas,setOpenModalFaltas]=useState(false)
    const [colaborador,setColaborador]=useState({
        nombre:'',
        rango:''
    })

    useEffect(()=>{
        let start = moment().format('YYYY-MM-DD')
        let end = moment().format('YYYY-MM-DD')
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() );
        setInicio(currentDate)
        setFin(currentDate)
        getData(start,end)
    },[])

   
    const getData = async(inicio,fin) =>{
        setLoading(true)    
        const response = await request.get(`asistences/getChecksReport?dateStart=${ moment(inicio).format('YYYY-MM-DD')}&dateEnd=${moment(fin).format('YYYY-MM-DD')}`)
        
    
        if (response && response.statusCode === 200) {  
            setData(response.result.data)
            response.result.data.forEach(element => {
                element.finesDeSemana=0
                element.asistencias = 0
                
                element.totalHoras=countHours(element)

                element.checks.forEach(check => {
                    let isfindesemana = esFinDeSemana(check.fecha)
                    if (isfindesemana) {
                        element.finesDeSemana = element.finesDeSemana+1
                    }else{
                        if (check.registros.length>0) {
                            element.asistencias = element.asistencias+1
                        }
                    }
                    
                });
            });
    
        }
        setLoading(false)
    }
    function esFinDeSemana(fecha) {
        // Convertir la fecha a un objeto Date
        let date = new Date(fecha);
    
        // Obtener el día de la semana (0 = Domingo, 6 = Sábado)
        let diaSemana = date.getDay();
    
        // Verificar si es sábado o domingo
        return diaSemana === 5 || diaSemana === 6;
    }
    const handleClick=(linea,nombre,bandera) => {
        let nombreTitle=''
        if (bandera=='checks') {
            
            setOpenModal(true)
            nombreTitle=`Asistencias del ${moment(inicio).format('DD/MM/YYYY')} al ${moment(fin).format('DD/MM/YYYY')}`
            setType(1)
        }else if(bandera=='retardos'){
            setOpenModalRetardos(true)
            nombreTitle=`Retardos del ${moment(inicio).format('DD/MM/YYYY')} al ${moment(fin).format('DD/MM/YYYY')}`
            setType(2)
        }else if(bandera=='faltas'){
            setOpenModalFaltas(true)
            nombreTitle=`Faltas del ${moment(inicio).format('DD/MM/YYYY')} al ${moment(fin).format('DD/MM/YYYY')}`
            setType(3)
        }
        
        
        setLineas(linea)
        setColaborador({ 
            nombre:nombre,
            rango:nombreTitle
        })
    }
    
    const countHours=(checks) => {
   
        let totales = []
      checks.checks.forEach(check => {
           
            let entrada =  buscarPorId(1,check.registros,'type')
            let salidacomer=''
            let regresocomer = ''
            let salida=''
            if (!entrada) { 
              return '00:00:00'
            }else{
                entrada=entrada.time
                let comodin=moment(entrada).format('YYYY-MM-DD')
                if (extractTime(entrada) < '08:46:00') {
                  entrada = `${comodin} 08:45:00`
                }
                 salidacomer =  buscarPorId(2,check.registros,'type')
                if (!salidacomer) {
                    salidacomer  =`${comodin} 14:00:00`
                    regresocomer =`${comodin} 15:00:00`
                } else{
                    regresocomer =  buscarPorId(3,check.registros,type)
                    if (!regresocomer) {
                     regresocomer=sumarTiempoHora(`${comodin} 15:00:00`,1)
                    }
                }
                
                 salida =  buscarPorId(4,check.registros,type)
                if (!salida) {
                    salida=`${comodin} 18:00:00`
                }

            }
      
            let totalAntesDeComida = calcularDiferenciaHoras(extractTime(entrada),extractTime(determinarTipo(salidacomer,'time')))

            let totalComida = calcularDiferenciaHoras(extractTime(salidacomer),extractTime(regresocomer))
            let totalDespuesComida = calcularDiferenciaHoras(extractTime(regresocomer),extractTime(determinarTipo(salida,'time')))
            let total = sumarHoras([totalAntesDeComida,totalDespuesComida])

            totales.push(total)
           
           
    
      });
    
      return sumarHoras(totales);
    
    }

    return ( 
        <>
        <div className="d-flex mb-3">
       <div>
       <Calendar value={inicio} onChange={(e) => setInicio(e.target.value)} />
       <Calendar value={fin} onChange={(e) => setFin(e.target.value)} />
       </div>
                <button className="btn btn-dark ml-2" onClick={(e) => getData(inicio,fin)}>
                    {loading? 'Buscando ...':'Buscar'}
                    {loading? <i className="fa fa-spinner fa-spin font-15"></i>:<i className="fa fa-search font-15"></i>}
                </button>
        </div>
        <TableInfinityFixed columnsParam={header} aditionalClass="table table-striped">
            {data.map((linea,k)=>{
              
                    const {name,lastName,checks,retardos,faltas,finesDeSemana,asistencias,totalHoras}=linea
                    let nombre = `${name} ${lastName}`
                 
                    
                return(
                        <tr key={k}>
                            <td>{nombre} </td>
                            <td onClick={e=>handleClick(checks,nombre,'checks')}>{asistencias}</td>
                            <td onClick={e=>handleClick(retardos,nombre,'retardos')}><strong className="text-dark">{retardos.length}</strong></td>
                            <td onClick={e=>handleClick(faltas,nombre,'faltas')}>{faltas.length - finesDeSemana}</td>
                            <td>{totalHoras}</td>
                            <td>0</td>
                    </tr>
                )
            })}

        </TableInfinityFixed>

            <ModalInformation {...{lineas,type,openModal,setOpenModal,esFinDeSemana,colaborador,setType}} />
            <ModalRetardos isOpen={openModalRetardos} setIsOpen={setOpenModalRetardos} data={lineas} type={type} colaborador={colaborador} />
            <ModalInasistences isOpen={openModalFaltas} setIsOpen={setOpenModalFaltas} data={lineas} type={type} colaborador={colaborador} />
        </>



     );
}

export { AsistencesPersonal };