import React, { useEffect, useState } from 'react'
import {ContactList} from './components/ContactList'
import {CardsAlert} from './components/CardsAlert'
import {Publications} from './components/Publications'
import { Link } from 'react-router-dom'
import { FiCommand, FiHome } from 'react-icons/fi'
import { LuBellRing } from 'react-icons/lu'
import { Apps } from '../apps'
export const TabOne = ({loggedIn,useDataInfo}) => {
  const [currentLocationHome,setcurrentLocationHome]=useState(true)
  const [currentLocationApps,setcurrentLocationApps]=useState(false)
  const [currentLocationMessages,setcurrentLocationMessages]=useState(false)
  const [currentLocationFaq,setcurrentLocationFaq]=useState(false)
  const [currentLocationmessages,setcurrentLocationmessages]=useState(false)
  useEffect(()=>{
    let location = window.location.pathname
   
    switch (location) {
      case '/apps':
        setcurrentLocationHome(false)
        setcurrentLocationApps(true)
        setcurrentLocationMessages(false)
        setcurrentLocationFaq(false)
        break;
      case '/homapage':
        setcurrentLocationHome(true)
        setcurrentLocationApps(false)
        setcurrentLocationMessages(false)
        setcurrentLocationFaq(false)
        break;
      case '/CheckOfTheDays':
        setcurrentLocationHome(false)
        setcurrentLocationApps(true)
        setcurrentLocationMessages(false)
        setcurrentLocationFaq(false)
        break;
      case '/faq':
        setcurrentLocationHome(false)
        setcurrentLocationApps(false)
        setcurrentLocationMessages(false)
        setcurrentLocationFaq(true)
        break;
      case '/messages':
        setcurrentLocationHome(false)
        setcurrentLocationApps(false)
        setcurrentLocationMessages(false)
        setcurrentLocationFaq(false)
        setcurrentLocationmessages(true)
        break;
    
      default:
        setcurrentLocationHome(false)
        setcurrentLocationApps(true)
        setcurrentLocationFaq(false)
        // setcurrentLocationFaq(false)
        break;
    }
   
  },[])
  return (
    <>
      
      {/* <CardsAlert {...{loggedIn}} /> */}
     
     <div className="row">
      <div className="col-md-12  col-xl-4 isMOvile" >
      <ul className="">
 
        <Apps showLayout={false}  />
    </ul>
      </div>
      <div className="col-md-8 col-xl-8 " >
      <Publications {...{useDataInfo}} />
      </div>
     </div>
     
    </>
  )
}
