import React, { useState } from 'react'
import Request from '../../utils/http'
const request = new Request()
export const NavBar = ({toogleElement,title=''}) => {
  const [notification,setNotifications]=useState([])
  

const logout=async() => {
  const response = await request.post('logout')
  if (response && response.statusCode === 200) {
    sessionStorage.clear();
    window.location.reload(true);
  }
}

  function timeagoFunction(dateString) {
    const dateGiven = new Date(dateString);
    const now = new Date();
    
    const seconds = Math.floor((now - dateGiven) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);
  
    if (seconds < 60) {
      return `${seconds} segundos atrás`;
    } else if (minutes < 60) {
      return `${minutes} minutos atrás`;
    } else if (hours < 24) {
      return `${hours} horas atrás`;
    } else if (days < 7) {
      return `${days} días atrás`;
    } else if (weeks < 4) {
      return `${weeks} semanas atrás`;
    } else if (months < 12) {
      return `${months} meses atrás`;
    } else {
      return `${years} años atrás`;
    }
  }
  return (
    <nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl position-sticky blur  shadow-blur mt-4 left-auto top-1 z-index-sticky" id="navbarBlur" navbar-scroll="true">
       
    <div className="container-fluid py-1 px-3">
    <li className="nav-item d-xl-none ps-3 d-flex align-items-center m-3 hidden">
            <a href="#" className="nav-link text-body p-0" id="iconNavbarSidenav" onClick={e=>toogleElement()}>
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line"></i>
                <i className="sidenav-toggler-line"></i>
                <i className="sidenav-toggler-line"></i>
              </div>
            </a>
            
    </li>
    <h6 className="title-header">{title}</h6>

      <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
        <div className="ms-md-auto pe-md-3 d-flex align-items-center">
        <h6 className="font-weight-bolder mb-0"> </h6>
        </div>
        <ul className="navbar-nav  justify-content-end">
         
        
         
          <li className="nav-item dropdown pe-2 d-flex align-items-center ">
            <a href="#" className="nav-link text-body p-0" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
              <i className="fa fa-bell cursor-pointer font-25">
             {notification.length>0&& <span className="notification-count">0</span>}
              </i>
            </a>
            <ul className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4" aria-labelledby="dropdownMenuButton">
              {notification.map((not,i)=>{
                const timeago=timeagoFunction('2024-07-18 16:04:58')
                return(
              <li className="mb-2">
                <a className="dropdown-item border-radius-md" href="#">
                  <div className="d-flex py-1">
                    <div className="my-auto">
                      <img src={not.img} className="avatar avatar-sm  me-3 "/>
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <h6 className="text-sm font-weight-normal mb-1">
                        <span className="font-weight-bold">{not.texto}</span> de {not.usuario}
                      </h6>
                      <p className="text-xs text-secondary mb-0 ">
                        <i className="fa fa-clock me-1"></i>
                        {timeago}
                      </p>
                    </div>
                  </div>
                </a>
              </li>

                )
              })}
              {/* <li className="mb-2">
                <a className="dropdown-item border-radius-md" href="#">
                  <div className="d-flex py-1">
                    <div className="my-auto">
                      <img src="../assets/img/small-logos/logo-spotify.svg" className="avatar avatar-sm bg-gradient-dark  me-3 "/>
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <h6 className="text-sm font-weight-normal mb-1">
                        <span className="font-weight-bold">New album</span> by Travis Scott
                      </h6>
                      <p className="text-xs text-secondary mb-0 ">
                        <i className="fa fa-clock me-1"></i>
                        1 day
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a className="dropdown-item border-radius-md" href="#">
                  <div className="d-flex py-1">
                    <div className="avatar avatar-sm bg-gradient-secondary  me-3  my-auto">
                      <svg width="12px" height="12px" viewBox="0 0 43 36" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                        <title>credit-card</title>
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <g transform="translate(-2169.000000, -745.000000)" fill="#FFFFFF" fillRule="nonzero">
                            <g transform="translate(1716.000000, 291.000000)">
                              <g transform="translate(453.000000, 454.000000)">
                                <path className="color-background" d="M43,10.7482083 L43,3.58333333 C43,1.60354167 41.3964583,0 39.4166667,0 L3.58333333,0 C1.60354167,0 0,1.60354167 0,3.58333333 L0,10.7482083 L43,10.7482083 Z" opacity="0.593633743"></path>
                                <path className="color-background" d="M0,16.125 L0,32.25 C0,34.2297917 1.60354167,35.8333333 3.58333333,35.8333333 L39.4166667,35.8333333 C41.3964583,35.8333333 43,34.2297917 43,32.25 L43,16.125 L0,16.125 Z M19.7083333,26.875 L7.16666667,26.875 L7.16666667,23.2916667 L19.7083333,23.2916667 L19.7083333,26.875 Z M35.8333333,26.875 L28.6666667,26.875 L28.6666667,23.2916667 L35.8333333,23.2916667 L35.8333333,26.875 Z"></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <h6 className="text-sm font-weight-normal mb-1">
                        Payment successfully completed
                      </h6>
                      <p className="text-xs text-secondary mb-0 ">
                        <i className="fa fa-clock me-1"></i>
                        2 days
                      </p>
                    </div>
                  </div>
                </a>
              </li> */}
            </ul>
          </li>
          <li className="nav-item px-3 d-flex align-items-center logout text-not" onClick={logout}>
            
            <i className="fa-solid fa-arrow-right-from-bracket font-15"></i> &nbsp; Cerrar Sesión dd
            
          </li>
        </ul>
        
      </div>
      <h6 className="title-header-movile">{title}</h6>
    </div>
  </nav>
  )
}
