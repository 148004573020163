import { useEffect, useState } from "react";
import moment from 'moment-timezone'
import {Link, useParams} from 'react-router-dom'
import { TableInfinityFixed } from "../../components/TableInfinityFixed";
import { LayoutMovile } from "../Layouts/LayoutMovile";
import { decryptString } from "../../utils/encrypt";
import Request from "../../utils/http";
import {ModalLocation} from './components/ModalLocation'
import {ExpandibleRow} from './components/ExpandibleRow'
import { ToastAlert } from "../../components/ToastAlert";
const request = new Request();
const header = [{ label: "Lugar y Fecha" }, { label: "tipo" }];

export const CheckingTime = () => {
  const { urlToBack } = useParams();
  const [openModal, closeModal] = useState(false);
  const [useData, setUser] = useState(0);
  const [checks, setChecs] = useState([]);
  const [loading,setLoading]=useState(false)
  const [toastAlertProps,setToastAlert]=useState({text:'',type:''})
  const handleOpen = () => {
    closeModal(true);
  };


  useEffect(() => {
    let userstorage = sessionStorage.getItem("user");
    userstorage = JSON.parse(decryptString(userstorage));
    setUser(userstorage.id_personal);
    getData(userstorage.id_personal)
  }, []);

  const handleSubmit = async(checking) => {
  
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async function (position) {
          // Accede a las coordenadas de latitud y longitud
         
          let data = {
            latitud:position.coords.latitude,
            longitud:position.coords.longitude,
            location:JSON.stringify(checking.location),
            type:checking.type,
            comments:checking.comments,
            id_user:useData,
            time:moment().format('YYYY-MM-DD HH:mm:ss')
          }
       
       const response = await request.post('locations/registerlocation',data)
       if(response && response.statusCode==200){
        getData(useData)
        setToastAlert({text:'Guardado con exito',type:'success'},)
        closeModal(false)
        return true
       }
         
        },
        function (error) {
          // Maneja los errores de geolocalización
          alert("Error obteniendo la ubicación: " + error.message);
        }
      );
    } else {
      // El navegador no soporta geolocalización
      alert("Geolocalización no es compatible en este navegador");
    }

  };
  const exitlocation = async(id) => {
    
    let resp = window.confirm('registrar salida?')
    if(!resp){return;}
    
    setLoading(true)
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async function (position) {
          // Accede a las coordenadas de latitud y longitud
         
          let data = {
            latitud:position.coords.latitude,
            longitud:position.coords.longitude,
            type:2,
            id_user:useData,
            time:moment().format('YYYY-MM-DD HH:mm:ss')
          }
        
       const response = await request.post(`locations/updateLocation/${id}`,data)
       if(response && response.statusCode==200){
        getData(useData)
        setLoading(false)
        setToastAlert({text:'Guardado con exito',type:'success'},)
        return true
       }
         
        },
        function (error) {
          // Maneja los errores de geolocalización
          alert("Error obteniendo la ubicación: " + error.message);
        }
      );
    } else {
      // El navegador no soporta geolocalización
      alert("Geolocalización no es compatible en este navegador");
    }

  };
 const getData = async (id) => {
    const response = await request.get(`getlocation/${id}`);
    if (response && response.statusCode == 200) {
   
    let copy = JSON.parse(JSON.stringify(response.result.data))
    copy.forEach((item=>{
      item.open=false
    }))
      setChecs(copy);
    }
  };
  const handleOpenSub=(position)=>{
    const copy = JSON.parse(JSON.stringify(checks))
    
    copy[position]['open'] = !copy[position]['open']
    
    setChecs(copy)
 
}
  return (
    <>
    <ToastAlert {...{toastAlertProps}} />
      <ModalLocation {...{ useData, openModal, handleSubmit,closeModal }} />
      <LayoutMovile showTabs={false}>
      <div className="text-center">
      <h2>Mis visitas del dia</h2>
      </div>
       <div className="d-flex justify-content-between">
         <Link to={`/${urlToBack}`} className='btn btn-sm btn-danger' > <i className='fa fa-reply font-15'></i> </Link>
       
        <button
          onClick={handleOpen}
          className="btn btn-sm btn-dark"
         
        >
          
          <i class="font-15 fa-solid fa-map-location-dot"></i> &nbsp;
        </button>
        
       </div>
        <div className="card p-2 mb-5">
         
          {/* <TableInfinityFixed aditionalClass="table-striped table-check-moville" columnsParam={header} height={'auto'}>
            {checks.map((ck,i)=>{
             
              return(
               <ExpandibleRow loading={loading} handleOpenSub={handleOpenSub} position={i} check={ck} exitlocation={exitlocation} key={i}/>
              )
            })}
          
          </TableInfinityFixed> */}
          {checks.map((line,i)=>{
             const {comments,location,time,timeout,id}=line
             const loc = JSON.parse(location)
            return(

          
              <div className=" col-md-12 col-sm-12 mt-2 col-lg-12 col-xl-12 text-center   shadow"  style={{backgroundColor:'#ffff',marginRight:'10px',borderRadius:'10px',borderRadius:'5px'}} key={i}>
                 <h6 className="  ">{loc.value}</h6>
                <div  className="col-10 d-flex justify-content-between pr-3">
                    <div className="">
                      <h6 className="text-success ">
                         <i class="fa-regular fa-circle-check "></i>{" "}  &nbsp;entrada 
                      </h6>
                      <small className="ml-2 font-15 text-success">{moment(time).format('DD/MM/YYYY HH:mm:ss')}</small>
                    </div>
                    <div className=' input-group-sm'>

                    {timeout ==null ? 
                    <button disabled={loading} className='btn btn-danger font-15 ml-1 mt-3' onClick={e=>exitlocation(id)}>
                         <i class={`${loading?'fa fa-spinner fa-spin':'fa-solid fa-check-double'}`}></i> {!loading?'salir':'Por favor espere..'}
                     </button>: 
                    <>
                    <h6 className=" ">
                      
                    <i class="fa-solid fa-circle-xmark "></i>
                      &nbsp;Salida
                    </h6>
                    <small className="text-xs   font-15" >
                      {moment(timeout).format('DD/MM/YYYY HH:mm:ss')}</small>
                    </>
                    }
                    </div>
                                   
                 </div>
               {comments&& <div className="p-3">
                  <textarea cols="30" rows="3" defaultValue={comments}disabled className="form-control" />
               </div>}
              </div>
                  
            )
          })}
       </div>
      </LayoutMovile>
    </>
  );
};



