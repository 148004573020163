const groupByProperty = (items, key) => items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [
        ...(result[item[key]] || []),
        item,
      ],
    }), 
    {},
  );

export default groupByProperty

// Esta función, llamada groupByProperty, toma una lista de objetos (items) y una clave (key). 
// La función utiliza el método reduce para agrupar los objetos en un nuevo objeto, 
// donde las claves son los valores únicos de la propiedad especificada por key, y 
// los valores son arrays que contienen todos los objetos que comparten ese valor en la propiedad.

// const groupByProperty = (items, key) => ...: Esta línea define una función llamada groupByProperty que toma dos parámetros:

// items: Un array de objetos que se va a agrupar.
// key: La propiedad por la cual se agruparán los objetos.
// items.reduce((result, item) => {...}, {}): Aquí se utiliza el método reduce en el array de items. 
// El reduce toma una función de retorno y un valor inicial (en este caso, un objeto vacío {}).

// (result, item) => ({ ...result, [item[key]]: [...(result[item[key]] || []), item] }): La función de retorno toma dos argumentos,
//  result y item, donde result es el acumulador que se está construyendo y item es el elemento actual en el array.

// { ...result, [item[key]]: [...(result[item[key]] || []), item] }: Aquí se utiliza el spread operator (...) para crear una nueva versión del objeto 
// result. Se agrega una nueva propiedad cuya clave es el valor de item[key] (la propiedad especificada por key en el objeto actual) 
// y cuyo valor es un array que contiene todos los objetos previamente agrupados con ese valor.

// (result[item[key]] || []): Esto asegura que si ya hay objetos agrupados con el mismo valor de item[key], 
// se toma ese array existente; de lo contrario, se crea un array vacío.

// item: Agrega el objeto actual al array.

// La función reduce procesa todos los elementos en el array items y devuelve el objeto resultante,
//  que es un objeto de agrupación donde las claves son los valores únicos de la propiedad especificada por key, y los valores son arrays que contienen los objetos agrupados.

// export default groupByProperty;: Exporta la función groupByProperty para que pueda ser importada y 
// utilizada en otros archivos o módulos de JavaScript.