import { Carousel } from 'react-responsive-carousel';
import { Encuesta } from './Encuesta';
export const PublicationCaption=({post,userDataInfo})=>{
    
    const textPublication = post.caption
    const isEncuesta = post.type?true:false
    
    return(
      <>
          <h6 className="text-xs">
            {textPublication}
          </h6>
    
          <div className="row">
            <div className="col-md-12 mx-auto">
             
             {!isEncuesta ? 
             
              <Carousel showThumbs={false}>
                  {post.imageUrls.map((imageUrl,j) =>{
                  
                  return(
                  <div className="carousel-item active">
                    <img src={imageUrl} key={j} className="d-block w-100 img-post" style={{height:'48vh',width:'35vh'}} alt="..."/>
                  </div>
                    
                  )
                })}
              </Carousel>:
             <Encuesta opciones={post.data} userDataInfo={userDataInfo} id={post.id} answered={post.answered}/>
              }
            </div>
          </div>
      </>
    )
  }