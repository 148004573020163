import moment from "moment-timezone";

function calculateTime(arrTime){
    let tiempo='00:00:00'
    let horas=0
    let minutos=0
    let segundos=0
    arrTime.forEach(element => {
  
    let horas1=tiempo.split(":");
     let horas2=element.split(":");
     let horatotale=new Array();
     
         for(let a=0;a<3;a++){
             horas1[a]=(isNaN(parseInt(horas1[a])))?0:parseInt(horas1[a])
             horas2[a]=(isNaN(parseInt(horas2[a])))?0:parseInt(horas2[a])
             horatotale[a]=(horas1[a]+horas2[a]);
         }
        
        
        horas += horatotale[0]
        minutos+= horatotale[1]
        segundos+=horatotale[2]

        });
        tiempo = ((horas*60)+minutos)
        
        return tiempo/60;


}
function calcularDiferenciaHoras(horaInicio, horaFin) {
    // Convertir las horas a objetos Date
    let [h1, m1, s1] = horaInicio.split(':').map(Number);
    let [h2, m2, s2] = horaFin.split(':').map(Number);

    // Crear objetos Date correspondientes
    let fechaInicio = new Date(0, 0, 0, h1, m1, s1);
    let fechaFin = new Date(0, 0, 0, h2, m2, s2);

    
    // Calcular la diferencia en milisegundos
    let diferenciaMs = fechaFin - fechaInicio;

    // Convertir la diferencia a horas, minutos y segundos
    let segundosTotales = Math.floor(diferenciaMs / 1000);
    let horas = Math.floor(segundosTotales / 3600);
    let minutos = Math.floor((segundosTotales % 3600) / 60);
    let segundos = segundosTotales % 60;
    minutos= minutos>9? minutos:minutos<0?'00':`0${minutos}`
    horas= horas>9? horas:horas<0?'00':`0${horas}`
    segundos= segundos>9? segundos:segundos<0?'00':`0${segundos}`

    // Formatear el resultado
    return `${horas}:${minutos}:${segundos}`;
}

// // Ejemplo de uso
// let horaInicio = "10:28:53";
// let horaFin = "16:15:45";
// let resultado = calcularDiferenciaHoras(horaInicio, horaFin);
// console.log(`La diferencia es: ${resultado}`);

function decimalAHora(decimalParam) {
    let decimal = decimalParam<0?(decimalParam*-1):decimalParam
    let horas = Math.floor(decimal), // Obtenemos la parte entera
    restoHoras = Math.floor(decimal % 1 * 100), // Obtenemos la parde decimal
    decimalMinutos = restoHoras * 60 / 100, // Obtenemos los minutos expresado en decimal
    
    minutos = Math.floor(decimalMinutos), // Obtenemos la parte entera
    restoMins = Math.floor(decimalMinutos % 1 * 100), // Obtenemos la parde decimal
    segundos = Math.floor(restoMins * 60 / 100); // Obtenemos los segundos expresado en entero
   
  
    if (horas>100) {
        horas=('00'+horas).slice(-3)
    }else if(horas>1000){
        horas=('00'+horas).slice(-4)

    }else{
        horas=('00'+horas).slice(-2)
    }


    return `${horas}:${('00'+minutos).slice(-2)}`;
  }

  function calcularRetardo(hora_entrada, hora_entrada_max) {
    // Convertir las horas a objetos Date
    const [h1, m1, s1] = hora_entrada.split(':').map(Number);
    const [h2, m2, s2] = hora_entrada_max.split(':').map(Number);
    
    const entrada = new Date();
    entrada.setHours(h1, m1, s1, 0);
    
    const entradaMax = new Date();
    entradaMax.setHours(h2, m2, s2, 0);
    
    // Calcular la diferencia en milisegundos
    const diferenciaMs = entrada - entradaMax;
    
    // Convertir la diferencia a minutos y horas
    const diferenciaTotalMin = Math.floor(diferenciaMs / 60000);
    const horas = Math.floor(Math.abs(diferenciaTotalMin) / 60);
    const minutos = Math.abs(diferenciaTotalMin) % 60;
    
    // Determinar el estado y devolver el resultado
    if (diferenciaTotalMin > 0) {
        return { tiempo: `+ ${horas}:${minutos} min`, status: 'retardo' ,arr:[horas,minutos] };
    } else {
        return { tiempo: ` - ${horas}:${minutos} min`, status: 'a tiempo',arr:[horas,minutos] };
    }
    // Ejemplos de uso
    // const resultado1 = calcularRetardo("09:29:53", "08:55:59");
    // console.log(resultado1);  // { tiempo: '0 horas : 34 minutos', status: 'retardo' }
    
    // const resultado2 = calcularRetardo("08:50:53", "08:55:59");
    // console.log(resultado2);  // { tiempo: '0 horas : 5 minutos', status: 'a tiempo' }
    
    // const resultado3 = calcularRetardo("10:00:00", "08:00:00");
    // console.log(resultado3);  // { tiempo: '2 horas : 0 minutos', status: 'retardo' }
}
function sumarHoras(horasArray) {
  
    // Función auxiliar para convertir una hora en segundos
    function convertirASegundos(hora) {
        const [h, m, s] = hora.split(':').map(Number);
        return h * 3600 + m * 60 + s;
    }
    
    // Función auxiliar para convertir segundos a formato HH:MM:SS
    function convertirAHora(segundos) {
        const h = Math.floor(segundos / 3600);
        const m = Math.floor((segundos % 3600) / 60);
        const s = segundos % 60;
        
        // Asegurarse de que los valores son de dos dígitos
        const hStr = String(h).padStart(2, '0');
        const mStr = String(m).padStart(2, '0');
        const sStr = String(s).padStart(2, '0');
        
        return `${hStr}:${mStr}:${sStr}`;
    }
    
    // Sumar todas las horas convertidas a segundos
    const totalSegundos = horasArray.reduce((acc, hora) => acc + convertirASegundos(hora), 0);
    
    // Convertir el total de segundos de vuelta a formato HH:MM:SS
    return convertirAHora(totalSegundos);
    // Ejemplo de uso
    // const horas = ['05:45:57', '02:15:06'];
    // const resultado = sumarHoras(horas);
    // console.log(resultado);  // '08:01:03'
}

function sumarTiempoHora(parametroHoras,horasParametro) {

  //sumar tiempo a una fecha en hr
    // Tu fecha y hora específicas en formato de cadena
let fechaHoraStr = parametroHoras;

// Convertir la cadena a un objeto Date
let [fecha, hora] = fechaHoraStr.split(' ');
let [año, mes, dia] = fecha.split('-');
let [horas, minutos, segundos] = hora.split(':');
let fechaHora = new Date(año, mes - 1, dia, horas, minutos, segundos);

// Sumar una hora
fechaHora.setHours(fechaHora.getHours() + horasParametro);

// Formatear y mostrar el resultado
let añoNuevo = fechaHora.getFullYear();
let mesNuevo = String(fechaHora.getMonth() + 1).padStart(2, '0');
let diaNuevo = String(fechaHora.getDate()).padStart(2, '0');
let horasNuevo = String(fechaHora.getHours()).padStart(2, '0');
let minutosNuevo = String(fechaHora.getMinutes()).padStart(2, '0');
let segundosNuevo = String(fechaHora.getSeconds()).padStart(2, '0');

let horaFormateada = `${añoNuevo}-${mesNuevo}-${diaNuevo} ${horasNuevo}:${minutosNuevo}:${segundosNuevo}`;
return horaFormateada;

}


function timeago(dateString) {
    const dateGiven = new Date(dateString);
    const now = new Date();
    
    const seconds = Math.floor((now - dateGiven) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);
  
    if (seconds < 60) {
      return `${seconds} segundos atrás`;
    } else if (minutes < 60) {
      return `${minutes} minutos atrás`;
    } else if (hours < 24) {
      return `${hours} horas atrás`;
    } else if (days < 7) {
      return `${days} días atrás`;
    } else if (weeks < 4) {
      return `${weeks} semanas atrás`;
    } else if (months < 12) {
      return `${months} meses atrás`;
    } else {
      return `${years} años atrás`;
    }
  }
  function extractTime(date){
    return moment(date).format('HH:mm:ss');
  }
  // Ejemplo de uso
//   console.log(timeago('2024-07-18 16:04:58'));

   // Función auxiliar para convertir una hora en segundos
   function convertirASegundos(hora) {
    const [h, m, s] = hora.split(':').map(Number);
    return h * 3600 + m * 60 + s;
}
  export {calculateTime,decimalAHora,calcularDiferenciaHoras,calcularRetardo,sumarHoras,sumarTiempoHora,timeago,extractTime,convertirASegundos}