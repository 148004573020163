import { useEffect, useState } from "react";

import { db } from '../../../../../../../firebaseConfig';
import { collection, query, getDoc,doc,updateDoc,orderBy, limit, startAfter, getDocs ,onSnapshot, serverTimestamp } from 'firebase/firestore';
export const LikesSection=({setShowComents,likes,showComents,countComments=0,userDataInfo})=>{
   
const [likesCount,setLikes]=useState([])
const [liked,setLiked]=useState(false)

function isNumberInArray(number, array=[]) {
    return array.includes(number);
}
function removeNumberFromArray(number, array) {
    const index = array.indexOf(number);
    if (index !== -1) {
        array.splice(index, 1);
        return true; // Indica que el número fue encontrado y eliminado
    }
    return false; // Indica que el número no fue encontrado
}

useEffect(()=>{
    likes && setLikes(likes.likes.usersIds)
    let r = isNumberInArray(userDataInfo.id_personal,likes.likes.usersIds)
    setLiked(r)
},[likes])

const handleLike=async()=>{
    const postRef = doc(db, 'posts', likes.id);
    const postDoc = await getDoc(postRef);



    let copy = JSON.parse(JSON.stringify(likesCount))
    let isinarr=isNumberInArray(userDataInfo.id_personal,copy)
    if (isinarr) {
        setLiked(false)
        removeNumberFromArray(userDataInfo.id_personal,copy)
    }else{
        copy.push(userDataInfo.id_personal)
        setLiked(true)

    }
    setLikes(copy)


    if (postDoc.exists()) {
        // Obtener los datos actuales del post
        let postData = postDoc.data();
        await updateDoc(postRef, {
            'likes.usersIds': copy
          });
    }
}
    return(
      <div className="row align-items-center px-2 mt-4 mb-2">
      <div className="col-sm-6">
        <div className="d-flex">
          <div className="d-flex align-items-center" onClick={handleLike}>
         {liked?
          <i class="fa-solid fa-heart-circle-check text-danger font-25 me-1 cursor-pointer"></i>:
          <i class={`fa-regular fa-heart font-25 me-1 cursor-pointer`}></i>
          }
            <span className="text-sm me-3">{likesCount.length}</span>
          </div>
          <div className="d-flex align-items-center" onClick={e=>setShowComents(!showComents)}  >
            <i className={`${countComments>0?'text-success':'text-dark'} ni ni-chat-round me-1 font-25 cursor-pointer`}></i>
            <span className="text-sm me-3 text-dark"> {countComments} </span>
          </div>
         
        </div>
      </div>
      <div className="col-sm-6 d-none d-sm-block">
        <div className="d-flex align-items-center justify-content-sm-end">
          <div className="d-flex align-items-center">
            <a
              href="javascript:;"
              className="avatar avatar-xs rounded-circle"
              data-toggle="tooltip"
              data-original-title="Jessica Rowland"
            >
            <i className='fa fa-user font-15'></i>
            </a>
            <a
              href="javascript:;"
              className="avatar avatar-xs rounded-circle"
              data-toggle="tooltip"
              data-original-title="Audrey Love"
            >
              <img
                alt="Image placeholder"
                src="../../../assets/img/team-2.jpg"
                className="rounded-circle"
              />
            </a>
            <a
              href="javascript:;"
              className="avatar avatar-xs rounded-circle"
              data-toggle="tooltip"
              data-original-title="Michael Lewis"
            >
              <img
                alt="Image placeholder"
                src="../../../assets/img/team-1.jpg"
                className="rounded-circle"
              />
            </a>
          </div>
          <small className="ps-2 font-weight-bold">
            and 30+ more
          </small>
        </div>
      </div>
      <hr className="horizontal dark my-3" />
    </div>
    )
  }