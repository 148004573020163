import React, { useEffect, useState } from 'react'
import Request from '../../../../utils/http'
import moment from 'moment-timezone'
const request = new Request()
export const TableVacations = () => {
    const [data,setData]=useState([])
    const [festivos,setFestivos]=useState([])
    useEffect(()=>{
        getData()
    },[])

    const getData=async() => {
        const response = await request.get('VacationsController/getVacations')

        let fest=[]
    
    
        if (response && response.statusCode === 200) {
          response.result.festivos.forEach(element =>  fest.push(element.start));
          setData(response.result.data)
          setFestivos(fest)
        }
            
    }

    function esFinDeSemana(fecha) {
        const dia = fecha.getDay();
        return dia === 5 || dia === 6; // 0 es Domingo, 6 es Sábado
      }
      
      function esFestivo(fecha, festivos) {
        const fechaStr = fecha.toISOString().split('T')[0];
        return festivos.includes(fechaStr);
      }
      
      function diasHabilesEntreFechas(fecha1, fecha2, festivos) {
        // Convertir las fechas de formato 'YYYY-MM-DD' a objetos Date
        const date1 = new Date(fecha1);
        const date2 = new Date(fecha2);
      
        // Asegurarse de que date1 es la fecha menor
        const [startDate, endDate] = date1 < date2 ? [date1, date2] : [date2, date1];
      
        let diasHabiles = 0;
        let currentDate = new Date(startDate);
      
        while (currentDate <= endDate) {
            if (!esFinDeSemana(currentDate) && !esFestivo(currentDate, festivos)) {
                diasHabiles++;
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }
      
        // Calcular la siguiente fecha habil más próxima
        let siguienteFechaHabil = new Date(endDate);
        siguienteFechaHabil.setDate(siguienteFechaHabil.getDate() + 1); // Empezar el conteo desde el día siguiente a endDate
      
        while (esFinDeSemana(siguienteFechaHabil) || esFestivo(siguienteFechaHabil, festivos)) {
            siguienteFechaHabil.setDate(siguienteFechaHabil.getDate() + 1);
        }
      
        // Formatear la siguiente fecha habil en 'YYYY-MM-DD'
        const siguienteFechaHabilStr = siguienteFechaHabil.toISOString().split('T')[0];
      
        return {
            diasHabiles: diasHabiles,
            siguienteFechaHabil: siguienteFechaHabilStr
        };
      }
      function verificarEstadoFechas(fechaInicio, fechaFin) {
        const hoy = new Date();
        const inicio = new Date(fechaInicio.split('/').reverse().join('-'));
        const fin = new Date(fechaFin.split('/').reverse().join('-'));
    
        if (hoy > fin) {
            return <span class="badge bg-gradient-dark me-3"> consumido</span>;
        } else if (hoy >= inicio && hoy <= fin) {
            return  <span class="badge bg-gradient-success me-3">en tránsito</span>;
        } else {
            return <span class="badge bg-gradient-danger me-3">pendiente</span>;
        }
    }
  return (
    <>
          <div class="card-body p-3">
                <div class="row">
               
                  <div class="col-12" style={{height:400,overflowY:'scroll'}}>
                    <div class="table-responsive">
                      <table class="table align-items-center mb-0">
                        <tbody>
                            {data.map((vac,i)=>{
                                let resp = diasHabilesEntreFechas(vac.start,vac.end,festivos)
                               
                                return(

                                <tr key={i}>
                                    <td>
                                    <div class="d-flex px-2 py-0">
                                        
                                        {verificarEstadoFechas(vac.start,vac.end)}
                                      
                                        <div class="d-flex flex-column justify-content-center">
                                        <h6 class="mb-0 text-sm">{vac.name} {vac.lastName}</h6>
                                        </div>
                                    </div>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                    <span class="text-xs font-weight-bold text-dark">
                                        Periodo: <br />
                                       {moment(vac.start).format('DD/MM/YYYY')} al {moment(vac.end).format('DD/MM/YYYY')}
                                    </span>
                                    </td>
                                    <td class="align-middle text-center text-sm">
                                    <span class="text-xs font-weight-bold text-dark">
                                      Regreso a oficina: <br /> {moment(resp.siguienteFechaHabil).format('DD/MM/YYYY')}
                                    </span>
                                    </td>
                                </tr>
                                )
                            })}
                         
                         
                         
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
    </>
  )
}
