import React, { useEffect, useState } from 'react'
import { VerticalTabs } from '../../../../components/VerticalTabs'
import { PersonalAndProfesionalInfo } from './PersonalAndProfesionalInfo'
import { TableDocuments } from './TableDocuments'
import { PersonalContract } from './PersonalContract'
import { TableInfinityFixed } from '../../../../components/TableInfinityFixed'
import moment from 'moment-timezone'
import { LoadingData } from '../../../../components/LoadingData'

import Request from '../../../../utils/http'
import { DeletePersonal } from './DeletePersonal'
import { useAppContext } from '../../../../Context/AppContext'
import VACATIONSDAYSPERYEARS from '../../../../utils/vacationsDays'
import { Ribbon } from '../../../../components/Ribbon'
import { ModalAddVaction } from './ModalAddVaction'
import Modal from 'react-modal'
import Toast from '../../../../components/Toast/Toast'
import {checkDays} from '../../../../Hooks/checkDays'
import { ToastAlert } from '../../../../components/ToastAlert'
import { KPIS } from '../../../../components/KPIS'
const request = new Request()

export const AddColaborator = ({user,department, tags,  tagsBoss,getDataAditional,handleClose,newColaborator,setUser,edit,loading}) => {
      const renderTabs = [ 
    {
        icono:'fa fa-user',
        titulo:'Informacion personal y profesional',
        id:'PersonalInformation',
        renderComponent:()=><PersonalAndProfesionalInfo {...{user,department,tags,tagsBoss,getDataAditional,newColaborator,handleClose}} />,
    }, 
]
      const renderTabsUpdate = [ 
    {
        icono:'fa fa-user',
        titulo:'Informacion personal y profesional',
        id:'PersonalInformation',
        renderComponent:()=><PersonalAndProfesionalInfo {...{user,department,tags,tagsBoss,getDataAditional,newColaborator,handleClose}} />,
    },
    {
        icono:'fa-solid fa-folder-open',
        titulo:'Documentacion personal y empresa',
        id:'Container',
        renderComponent:()=><TableDocuments {...{user}} />,
    },
    {
        icono:'fa-solid fa-file-signature',
        titulo:'Contratos de colaborador',
        id:'Container',
        renderComponent:()=> <PersonalContract {...{user,setUser}}/>,
    },
    {
        icono:'fa-solid fa-comments-dollar',
        titulo:'Sueldos',
        id:'Container',
        renderComponent:()=> <Salaries {...{user}}  />,
    },
    {
        icono:'fa-solid fa-plane-departure',
        titulo:'Vacaciones y Permisos',
        id:'Container',
        renderComponent:()=><VacationsColaborator userParam={user}/>,
    },
    {
        icono:'fa-solid fa-triangle-exclamation text-danger',
        titulo:'Baja de colaborador',
        id:'Container',
        renderComponent:()=><DeletePersonal {...{user}} />,
    },
   
]
  return (
    <div>
 
         <button className="btn btn-danger btn-sm" onClick={handleClose}><i className='fa fa-reply font-15'></i> regresar</button>
        
        <VerticalTabs renderTabs={edit?renderTabsUpdate:renderTabs}  />

    </div>
  )
}

const Salaries=({user})=>{
    const [data,setData]=useState([])
    const [loading,setLoading] = useState(false)
    useEffect(() => {
        getSalaries(user.id)
    }, [user]);
    const getSalaries=async(id)=>{
        setLoading(true)
        const response = await request.get(`sueldos/obtenerSueldos/${id}`)
      
        if (response && response.statusCode==200) {
            setData(response.result.data)
          }
          setLoading(false)
       
    }
    return(
        <>
        
        {loading && (
            <table>
                <thead>
                    <tr>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    <LoadingData colspan={2} quantity={data.length} loading={loading} />
                </tbody>
            </table>
        )}

        
        <div className="card p-3">
            <h6>Salarios registrados del colaborador</h6>
            <div class="card-body p-3">
              {data.map((line,i) =>{
                const {sueldo,description,created_at,comments,description_dep}=line
                return(

              <div class="timeline timeline-one-side" key={i}>
                <div class="timeline-block mb-3">
                  <span class="timeline-step">
                  <i class="fa-solid fa-hand-holding-dollar text-dark"></i>
                  </span>
                  <div class="timeline-content">
                    <h6 class="text-dark text-sm font-weight-bold mb-0">${sueldo}, {description}</h6>
                    <h6 class="text-secondary text-xs font-weight-bold mb-0">Departamento: {description_dep}</h6>
                    <p class="text-secondary font-weight-bold text-xs mt-1 mb-0">Fecha de captura: {moment(created_at).format('DD/MM/YYYY')}</p>
                    <p class=" font-weight-bold text-xs mt-1 mb-0 text-danger">Observaciones: {comments==null?'Sin comentarios':comments}</p>
                  </div>
                </div>
               
              </div>
                )
              })}
            </div>
           
        </div>
        </>
    )
}

const VacationsColaborator=( {userParam=null}) => {


  
  const [toastAlertProps,setToastAlert]=useState({text:'',type:''})
  const [userdata,setUserdata]=useState([])
  const [totalDays,setTotalDays]=useState(0)
  const [days,setDays]=useState({programados:0,disponibles:0})
  const [usrp,setUsrP]=useState(null)
  const [dataEdit, setDataEdit] = useState([]);
  const [editVacation, setEditVacation] = useState(false);
  const [isOpen,setIsOpen] = useState(false)
  const [festivosArr,setFestivos]=useState([])
  useEffect(() => {
    if ( userParam !== null ) {
      getDeps(userParam)
    }
    
  },[userParam])
  useEffect(() => {
    userdata.length>0 && calculateDays(userdata)
  },[userdata])

const calculateDays = (vac) => {
  let disponibles=0
  let programados=0

  
  vac.forEach(element => {
    const resultado = diasHabilesEntreFechas(element.start, element.end,festivosArr);
    programados=programados+resultado.diasHabiles
  });
  setDays({programados:programados,disponibles:totalDays-programados});
}
  const getDeps = async(usr) =>{
    
    let ingreso= usr.ingreso
    let dias= timediference('year',new Date(ingreso),new Date())
        setTotalDays(dias.dias)
        getData(usr.id,dias.dias)
        const response= await request.get(`user/GetDeps/${usr.id}`,)
      
        usr.dept=response.result.data
       
        setUsrP(usr)

  }  
    const getData = async(userId,dias=totalDays) => {
      
      const response = await request.get(`personal/getInformationbyidVacation/${userId}`) 
      let programados=0
     
       let fest=[]
      if (response && response.statusCode === 200) {
        response.result.festivos.forEach(element =>  fest.push(element.start));
        setFestivos(fest)
      let copy = response.result.data
     
      await Promise.all(copy.map(async (element) => {
      
        element.periodo = `${moment(element.start).format('DD/MM/YYYY')} al ${moment(element.end).format('DD/MM/YYYY')}`
        switch (element.status) {
          case 0:
            element.autorizacion='en espera'
            break;
          case 1:
            element.autorizacion='en Proceso '
            break;
          case 2:
            element.autorizacion='Autorizado '
            break;
        
          default:
            element.autorizacion='Rechazado'
            break;
        }

        switch (element.taken) {
          case 0:
            element.actualState='Sin tomar'
            break;
          case 1:
            element.actualState='En curso'
            break;
          case 2:
            element.actualState='Terminado'
            break;

          default:
            element.actualState='Terminado'
            break;
        }
      
        let info = await checkDate(element);
        element.open=false
        element.dias=0
        if (element.status==2) {

          var diferenciaEnMilisegundos =  new Date(element.end) -  new Date(element.start);
          var diferenciaEnDias =( diferenciaEnMilisegundos / (1000 * 60 * 60 * 24))+1;   
          programados = programados + (diferenciaEnDias)-(info.fechasFinesSemana.length)-(info.festivos.length)-(info.festivos.length)
          element.dias=(diferenciaEnDias)-(info.fechasFinesSemana.length)-(info.festivos.length)-(info.festivos.length)
        }
      }))
        setDays({programados:programados,disponibles:dias-programados})
     
  
        setUserdata(copy)
      }
  
  }

  const checkDate=async(vacation) => {
    const {start,end} = vacation
    const fechasFinesSemana = checkDays(start, end);
    let linea={
        fechasFinesSemana:fechasFinesSemana,
        disponibles:[],
        disponiblesAntiguedad:[],
        festivos:[],
        nodisponible:[],
        dispauth:[]
    }
    const response = await request.get(`vacationEvents/checkDaysDisponibility?start=${start}&end=${end}`)
    if (response && response.statusCode === 200) {
        linea.disponibles = response.result.disponibles
        linea.disponiblesAntiguedad = response.result.disponiblesAntiguedad
        linea.festivos = response.result.festivos
        linea.nodisponible = response.result.nodisponible
        linea.dispauth = response.result.dispauth        
    }
    return  linea
   
}
  const timediference=(type='year',inicio,fin)=>{
    let fechaInicio = inicio;
    let fechaFin = fin
    
    // Calcular la diferencia en milisegundos
    const diferenciaEnMilisegundos = fechaFin - fechaInicio;

    
    // Calcular la diferencia en años
    let milisegundosEnUnAno=0
    if (type=='year') {
      
      milisegundosEnUnAno = 1000 * 60 * 60 * 24 * 365.25; // Aproximadamente 365.25 días en un año para tener en cuenta años bisiestos
    }else{

      milisegundosEnUnAno = diferenciaEnMilisegundos /1000 * 60 * 60 * 24;
      return  milisegundosEnUnAno          // Aproximadamente 365.25 días en un año para tener en cuenta años bisiestos
    }
    const diferenciaEnAnos = diferenciaEnMilisegundos / milisegundosEnUnAno;

    // Redondear la diferencia si es necesario
    const annios = diferenciaEnAnos < 1 ? 1:Math.round(diferenciaEnAnos);
    // console.log("🚀 ~ file: index.jsx:70 ~ timediference ~ annios:", annios)

    
    const objetoConLabel3 = VACATIONSDAYSPERYEARS.find(item => item.label === annios);

    return objetoConLabel3
  }

  const handleOpen=(position)=>{
    let copy = JSON.parse(JSON.stringify(userdata));
    copy[position]['open'] = !copy[position]['open']
    setUserdata(copy)
  }
  const header=[
    {label:''},
    {label:'Periodo'},
    {label:'Regreso'},
    {label:'Dias'},
    {label:'Actual '},
    {label:'Status'},
    {label:'Eliminar'},
  ]
  const reload=() => {
    getDeps(userParam)
  }

  const handleDelete=async(id) => {
   
  
    const response = await request.delete(`vacationUsrs/deleteVacation/${id}`)
    if (response && response.statusCode==200) {
      
      setToastAlert({text:'eliminado con exito',type:'success'},)
      getData(usrp.id)
    }

  }
  const handleClick = (linea) =>{
    setEditVacation(!editVacation)
    setDataEdit(linea)
    setIsOpen(!isOpen)
   }

   function esFinDeSemana(fecha) {
    const dia = fecha.getDay();
    return dia === 5 || dia === 6; // 0 es Domingo, 6 es Sábado
  }
  
  function esFestivo(fecha, festivos) {
    const fechaStr = fecha.toISOString().split('T')[0];
   
   
    return festivos.includes(fechaStr);
  }
  
  function diasHabilesEntreFechas(fecha1, fecha2, festivos) {
  
  
    // Convertir las fechas de formato 'YYYY-MM-DD' a objetos Date
    const date1 = new Date(fecha1);
    const date2 = new Date(fecha2);
  
    // Asegurarse de que date1 es la fecha menor
    const [startDate, endDate] = date1 < date2 ? [date1, date2] : [date2, date1];
  
    let diasHabiles = 0;
    let currentDate = startDate;
  
    while (currentDate <= endDate) {
       
        if (!esFinDeSemana(currentDate) ) {
          if(!esFestivo(currentDate, festivos))
            {
              diasHabiles++;
            }
        }
            
        currentDate.setDate(currentDate.getDate() + 1);
    }
  
    // Calcular la siguiente fecha habil más próxima
    let siguienteFechaHabil = new Date(endDate);
    siguienteFechaHabil.setDate(siguienteFechaHabil.getDate() + 1); // Empezar el conteo desde el día siguiente a endDate
  
    while (esFinDeSemana(siguienteFechaHabil) || esFestivo(siguienteFechaHabil, festivos)) {
        siguienteFechaHabil.setDate(siguienteFechaHabil.getDate() + 1);
    }
  
    // Formatear la siguiente fecha habil en 'YYYY-MM-DD'
    const siguienteFechaHabilStr = siguienteFechaHabil.toISOString().split('T')[0];
  
    return {
        diasHabiles: diasHabiles,
        siguienteFechaHabil: siguienteFechaHabilStr
    };
  }
  return(
    <>
     <Toast {...{toastAlertProps}} />
    <div className='d-flex justify-content-end p-3'>
       {/* <ModalAddVaction  {...{usrp}} reload={getData}/> */}
       <ModalVacations usrp={usrp} isOpen={isOpen} setIsOpen={setIsOpen} dataEdit={dataEdit} setEditVacation={setEditVacation} edit={editVacation} id={usrp?.id} reload={reload} festivosArr={festivosArr}/>
    </div>
    
    <div className="d-flex justify-content-between p-3">
    <div class="  text-center   "  >
              <div class="col-12 text-center">
                <h6 className="text-center  ">Disponibles este año {moment().format('YYYY')}: </h6>
                <h3 className="text-center text-danger">{totalDays}</h3>
              </div>
            </div>
           <div className=" text-center mr-3  "   >
              <div className="col-12 text-center">
                <h6 className="text-center  ">Disponibles </h6>
                <h3 className="text-center text-success">{days.disponibles}</h3>
             
              </div>
              </div>
              <div
              className="text-center mr-3  "  >
              <div className="col-12 text-center">
                <h6 className="text-center  ">Programados </h6>
                <h3 className="text-center text-success">{days.programados}</h3>
             
              </div>
            </div>
    </div>
    <TableInfinityFixed columnsParam={header} aditionalClass="table-striped" height={'auto'}>
          {userdata.map((day,key) =>{
            const {presentated_day,periodo,autorizacion,actualState,comments,open,dias,id,start,end} = day
          
            const resultado = diasHabilesEntreFechas(start, end,festivosArr);
            return(
              <>
              <tr key={key}>
                <td onClick={e=>handleClick(day)}>
                <i className={`fa fa-chevron-${open?'down':'right'}`}/> &nbsp; 
                </td>
                <td>
                   { day.consumido && <Ribbon text="Consumido" /> }
                  <h6 className="text-xs">{periodo} </h6>
                </td>
                <td className="text-center ">
                  <h6 className="text-xs">{moment(resultado.siguienteFechaHabil).format('DD/MM/YYYY')}</h6>
                </td>
                <td className="text-center ">
                  <h6 className="text-xs">{resultado.diasHabiles}</h6>
                </td>
                <td className="text-center text-success">
                  <h6 className="text-xs">{actualState}</h6>
                </td>
                <td className="text-center text-success">
                  <h6 className="text-xs">{autorizacion}</h6>
                </td>
                <td className="text-center text-success">
                  <button className="btn p-0" onClick={e=>handleDelete(id)}><i className="fa fa-trash text-danger"></i></button>
                </td>
              </tr>
              {open&&<tr>
                <td colSpan={'4'}>
                    <textarea cols="3" rows="2" className='form-control' disabled value={comments}></textarea>
                </td>
              </tr>
              }
              
              
              </>
            )
          })}
         
        </TableInfinityFixed>
    </>
  )
}
// 	Informacion profesional			

const ModalVacations = ({id,reload,edit,dataEdit,setEditVacation,setIsOpen,isOpen,usrp,festivosArr})=>{
  const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "-35%",
        width: "33%",
        height: "85%",
        transform: "translate(-50%, -50%)",
      },
}
  const [vacation, setVacation] = useState({
      start:new Date(),
      end:new Date(),
      type:1,
      comments:'',
      id_empleado:0,
      id:0,
      taken:0,
      status:0,
      dayToComeBack:''
  });
  const [disabledData,setDisabled] = useState(true)
  const [weekendDays,setWeekendDays] = useState(null)
  const [toastAlertProps,setToastAlert]=useState({text:'',type:''})
  useEffect(() => { id > 0 && setVacation({...vacation,id_empleado:id})  },[id])
  useEffect(() => {
      if (edit) {
          // setVacation(dataEdit)
          setDisabled(true)
          let days=dataEdit
          days.dayToComeBack=moment(dataEdit.presentated_day).format('YYYY-MM-DD')
          handleVacation(days)
      }else{
          setDisabled(false)
      }
  },[edit])
  const handleClick=() => {
      setVacation({ start:new Date(),end:new Date(),type:1,comments:'',id_empleado:id,id:0 ,taken:0,status:0 })
      setEditVacation(false)
      setIsOpen(!isOpen)
  }

  const handleDate=async(valor,bandera)=>{
    
      let copy = JSON.parse(JSON.stringify(vacation))
          copy.end = valor     
          let  fecha1=''
          let  fecha2=''
        
          if (bandera=='start') {
             fecha1 = new Date(valor); // Fecha inicial
             fecha2 = new Date(vacation.end); 
          }else{
            fecha1 = new Date(vacation.start); // Fecha inicial
            fecha2 = new Date(valor); 
          }
          
  let retorno =diasHabilesEntreFechas(fecha1,fecha2,festivosArr)
  // setSolicitud({...solicitud,[bandera]:value,dayToComeBack:moment(retorno.siguienteFechaHabil).format('YYYY-MM-DD')})
          fecha2.setDate(fecha2.getDate() + 2);
        let dyacome=fecha2.toISOString()
      
        setVacation({...vacation, end:valor,dayToComeBack:moment(retorno.siguienteFechaHabil).format('YYYY-MM-DD')})
          copy.dayToComeBack=moment(retorno.siguienteFechaHabil).format('YYYY-MM-DD')
      let weekendAppy = await checkDate(copy) 
    
      if (weekendAppy != null) {
          
          setWeekendDays(weekendAppy)
          return ;
      }
  }
  const handleVacation=async(dataEdit)=>{
      setVacation(dataEdit)
      let copy = JSON.parse(JSON.stringify(dataEdit))
           
      let weekendAppy = await checkDate(copy) 
    
      if (weekendAppy != null) {
          
          setWeekendDays(weekendAppy)
          return ;
      }
  }
  
  const handleSubmit = async () => {
    let vacationsDays=JSON.parse(JSON.stringify(vacation))
    let inmmBoss= JSON.parse(usrp.inmBoss)
    
    let dept=JSON.parse(usrp.dept)
   
    if (dept == null) {
      setToastAlert({text:'el departamento al que esta asignado no tiene jefe de area definido.',type:'error'},)
      return;
    }else if (inmmBoss ==null) {
      setToastAlert({text:'colaborador sin jefe inmediato',type:'error'},)
      return;
    }
    vacationsDays.id_inmboss=inmmBoss.value
    vacationsDays.id_workareaBoss=dept.value
 
    const response = await request.post(`events/nuevaSolicitdrrhh/${usrp.id}`,vacationsDays)
      if (response && response.statusCode === 200) {
        setToastAlert({text:'Guardado con exito',type:'success'},)
          reload()
      }
  }
  const checkDate=async(vacation) => {
    
      const {start,end,dayToComeBack} = vacation
      const fechasFinesSemana = checkDays(start, dayToComeBack);
      let linea={
          fechasFinesSemana:fechasFinesSemana,
          disponibles:[],
          disponiblesAntiguedad:[],
          festivos:[],
          nodisponible:[],
          dispauth:[]
      }
      const response = await request.get(`vacationEvents/checkDaysDisponibility?start=${start}&end=${end}`)
      if (response && response.statusCode === 200) {
          linea.disponibles = response.result.disponibles
          linea.disponiblesAntiguedad = response.result.disponiblesAntiguedad
          linea.festivos = response.result.festivos
          linea.nodisponible = response.result.nodisponible
          linea.dispauth = response.result.dispauth        
      }
      return linea
  }

  const handleClose=() => {
      handleClick()
      setWeekendDays(null)
  }
  const handleDelete=async() => {
      const response = await request.delete(`vacationEvents/handleDelete/${vacation.id}`)
      if (response && response.statusCode==200) {
       
          reload()
      }
  }
  function esFinDeSemana(fecha) {
    const dia = fecha.getDay();
    return dia === 5 || dia === 6; // 0 es Domingo, 6 es Sábado
  }
  
  function esFestivo(fecha, festivos) {
    const fechaStr = fecha.toISOString().split('T')[0];
    return festivos.includes(fechaStr);
  }
  
  function diasHabilesEntreFechas(fecha1, fecha2, festivos) {
    // Convertir las fechas de formato 'YYYY-MM-DD' a objetos Date
    const date1 = new Date(fecha1);
    const date2 = new Date(fecha2);
  
    // Asegurarse de que date1 es la fecha menor
    const [startDate, endDate] = date1 < date2 ? [date1, date2] : [date2, date1];
  
    let diasHabiles = 0;
    let currentDate = new Date(startDate);
  
    while (currentDate <= endDate) {
        if (!esFinDeSemana(currentDate) && !esFestivo(currentDate, festivos)) {
            diasHabiles++;
        }
        currentDate.setDate(currentDate.getDate() + 1);
    }
  
    // Calcular la siguiente fecha habil más próxima
    let siguienteFechaHabil = new Date(endDate);
    siguienteFechaHabil.setDate(siguienteFechaHabil.getDate() + 1); // Empezar el conteo desde el día siguiente a endDate
  
    while (esFinDeSemana(siguienteFechaHabil) || esFestivo(siguienteFechaHabil, festivos)) {
        siguienteFechaHabil.setDate(siguienteFechaHabil.getDate() + 1);
    }
  
    // Formatear la siguiente fecha habil en 'YYYY-MM-DD'
    const siguienteFechaHabilStr = siguienteFechaHabil.toISOString().split('T')[0];
  
    return {
        diasHabiles: diasHabiles,
        siguienteFechaHabil: siguienteFechaHabilStr
    };
  }
  return(
    <>
    
    <button onClick={e=>setIsOpen(true)}  className="btn btn-dark btn-sm">Agregar vacaciones/Permisos</button>
    <ToastAlert {...{toastAlertProps}} />
      <Modal isOpen={isOpen} style={customStyles} shouldCloseOnOverlayClick={true}   onRequestClose={handleClose}  shouldCloseOnEsc={true}>
         { vacation.taken == 0 && 
              <div onClick={e=>setDisabled(!disabledData)}>
                  {edit && <button className="btn "> <i className='fa fa-edit' /> Editar </button>  }
              </div>
          }
          {edit &&  <div className='justify-end'>
                      <strong>Estatus</strong> 
                    </div>
          }
              <div className="justify-end ">

                  <select className="form-control" disabled={disabledData} style={{width:'200px'}}  value={vacation.status} onChange={e=>setVacation({...vacation,status:e.target.value})}>
                      <option value="1">Pendiente</option>
                      <option value="2">Aprobado</option>
                  </select>
                 
              </div>
          <div className="row">
              <div className="col-md-12">
                  <strong>Inicio del Periodo</strong>
                  <input type="date" value={vacation.start} disabled={disabledData} onChange={(e) => setVacation({...vacation, start:e.target.value})} className="form-control"/>
              </div>
              <div className="col-md-12">
                  <strong>Termino del Periodo</strong>
                  <input type="date" className="form-control" disabled={disabledData} value={vacation.end} onChange={(e) => handleDate(e.target.value,'termino')} />
              </div>
              <div className="col-md-12">
                <label > Fecha de Presentacion</label>
                <input onChange={e=>setVacation({...vacation,dayToComeBack:e.target.value})} value={vacation.dayToComeBack} type="date" class="form-control" />
              </div>
              <div className="col-md-12">
                  <strong>Tipo de Periodo</strong>
                  <select className='form-control' disabled={disabledData} value={vacation.type} onChange={(e) => setVacation({...vacation, type:e.target.value})}>
                      <option value="0">Selecciona una opcion</option>
                      <option value="1">Vacaciones</option>
                      <option value="2">Permiso con goce de sueldo</option>
                      <option value="3">Permiso sin goce de sueldo</option>
                  </select>
              </div>
            
              <div className="col-md-12">
                  <strong>Comentarios</strong>
                  <textarea className='form-control' disabled={disabledData} cols="20" rows="3" value={vacation.comments||''} onChange={(e) => setVacation({...vacation, comments:e.target.value})}></textarea>
              </div>
              {vacation.status ==2 && (
              <>
                  <div className='col-md-12'>
                      <strong>Solicitud de Prima Vacacional</strong>
                      <input type="date" disabled={vacation.taken>0} value={vacation.payment_request||''} onChange={(e) => setVacation({...vacation, payment_request:e.target.value})} className= 'form-control' />
                  </div>
                  <div className='col-md-12'>
                      <strong>Pago de Prima Vacacional</strong>
                      <input type="date"  disabled={vacation.taken>0}value={vacation.payment_Date||''} onChange={(e) => setVacation({...vacation, payment_Date:e.target.value})}className= 'form-control' />
                  </div>   
              </>
              )}
              { weekendDays && (
              <>
                <div className="col-md-12">
                <strong> El periodo seleccionado tiene las siguientes observaciones:</strong>
                </div>
                <div className="col-md-12 p-3">
                <ul>
                  { weekendDays?.dispauth.length > 0 && 
                          <li>
                             <span className=" text-danger">  Dentro de periodo definido como disponibles bajo autorizacion:</span>
                              { weekendDays.dispauth.map((linea,k)=> <small className="text- ml-3" key={k}>( {moment(linea.start).format('DD/MM/YYYY')} - {moment(linea.end).format('DD/MM/YYYY')} ),</small> )}
                          </li>
                      }
                      {weekendDays.disponiblesAntiguedad.length>0&&
                          <li>
                             <span className=" text-danger">  Dentro de periodo definido como disponibles por antiguedad:</span>
                              { weekendDays.disponiblesAntiguedad.map((linea,k)=> <small className="text- ml-3" key={k}>( {moment(linea.start).format('DD/MM/YYYY')} - {moment(linea.end).format('DD/MM/YYYY')} ),</small> )}
                          </li>
                      }
                  { weekendDays.festivos.length > 0 && 
                          <li>
                               <span className=" text-danger">  {weekendDays.festivos.length}  dias definidos como festivos:</span>
                              { weekendDays.festivos.map((linea,k)=> <small className="te-danger ml-3" key={k}>({moment(linea.start).format('DD/MM/YYYY')}),</small> )}
                          </li>
                  }
                
                  {weekendDays.nodisponible.length>0&&
                          <li>
                             <span className=" text-danger">  Dentro de periodos definidos como no disponibles:</span>
                              { weekendDays.nodisponible.map((linea,k)=> <small className="text- ml-3" key={k}>( {moment(linea.start).format('DD/MM/YYYY')} - {moment(linea.end).format('DD/MM/YYYY')}),</small>  )}
                          </li>
                   }
                     {weekendDays.fechasFinesSemana.length>0&&
                          <li>
                             <span className=" text-danger">  {(weekendDays.fechasFinesSemana.length)} Fines de semana:</span>
                              { weekendDays.fechasFinesSemana.map((linea,k)=>  <small className="text- ml-3" key={k}>( {moment(linea).format('DD/MM/YYYY')} ) ,</small> )}
                          </li>
                      }
                  
                  </ul>
                </div>
              
              
              </> )}
               
              <div className="col-md-12">
                  <div className="d-flex justify-content-between mt-3">
                    {!disabledData &&  <>
                     {edit&& <button className="btn btn-danger" onClick={handleDelete}><i className='fa fa-trash'></i> Eliminar periodo</button>}
                    
                    
                      <button className='btn btn-success ' onClick={handleSubmit}><i className='fa fa-save'/> Guardar</button>
                    </>}
                  </div>
              </div>
          
          </div>
      </Modal>
    </>
  )
}