import React from "react";

export const CheckBox = ({
    typeActive,
    id,
    checked=false,
    handleChange,
    labels=['Activar','Desactivar']

}) => {
  return (
    <div class="form-check form-switch ml-3 ps-0" style={{width:'100%'}}>
      <input
        class="form-check-input ms-auto"
        type="checkbox"
        id={id}
        checked={checked==1?true:false}
        onChange={e=>handleChange(id,e.target.checked)}

      />
      <label
        class="form-check-label text-body ms-3 text-truncate w-80 mb-0"
        for={id}
      >
        {checked?labels[1]:labels[0]}
      </label>
    </div>
  );
};
