import React, { useEffect, useState } from 'react'
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../../../../firebaseConfig';
export const ListOfChats = ({fetchMessages}) => {
    const [chats, setChats] = useState([]);

    useEffect(() => {
      const fetchChats = async () => {
        const chatsCollection = collection(db, 'chats');
        const chatsSnapshot = await getDocs(chatsCollection);
        const chatsList = chatsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setChats(chatsList);
      };
  
      fetchChats();
    }, []);
  return (
    chats.map((chat)=>{
    
      const {nameParticipants} =chat
        return(
            <div  class=" p-2 mt-2 border-radius-lg bg-gradient-info shadow" onClick={e=>fetchMessages(chat)} >
            <div class="d-flex justify-content-between p-2">
                <img alt="Image" src="https://th.bing.com/th/id/OIP.6l0YaZE1vivLP6rUMyeMwgHaFv?rs=1&pid=ImgDetMain" class="avatar round shadow"/>
                <div class="ms-2">
                <div class="text-white justify-content-between align-items-center font-10">
                  Participantes:
                  <ul>
                    <li class="text-white text-xs mb-0 font-10">{nameParticipants.participantOne} </li>
                    <li class="text-white text-xs mb-0 font-10">  {nameParticipants.participantTwo}</li>
                  </ul>
                   
                </div>
                </div>
                <div class="align-end">
                <h6 className='font-15'><i class="fa-solid fa-chevron-right text-white"></i></h6>
                </div>
            </div>
        </div>
        )
    })
  )
}
