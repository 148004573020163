import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from 'react-modal'
import Request from '../../utils/http'
import './styles.css'
import { useNavigate  } from 'react-router-dom';
import { FiHome,FiCommand,FiBookOpen,FiLogIn   } from "react-icons/fi";
import { LuBellRing } from "react-icons/lu";
const request = new Request();
export const BottomMenu = ({ path, label }) => {
  
  const [currentLocationHome,setcurrentLocationHome]=useState(true)
  const [currentLocationApps,setcurrentLocationApps]=useState(false)
  const [currentLocationMessages,setcurrentLocationMessages]=useState(false)
  const [currentLocationFaq,setcurrentLocationFaq]=useState(false)
  const [currentLocationmessages,setcurrentLocationmessages]=useState(false)


  useEffect(()=>{
    let location = window.location.pathname
   
    switch (location) {
      case '/apps':
        setcurrentLocationHome(false)
        setcurrentLocationApps(true)
        setcurrentLocationMessages(false)
        setcurrentLocationFaq(false)
        break;
      case '/homapage':
        setcurrentLocationHome(true)
        setcurrentLocationApps(false)
        setcurrentLocationMessages(false)
        setcurrentLocationFaq(false)
        break;
      case '/CheckOfTheDays':
        setcurrentLocationHome(false)
        setcurrentLocationApps(true)
        setcurrentLocationMessages(false)
        setcurrentLocationFaq(false)
        break;
      case '/faq':
        setcurrentLocationHome(false)
        setcurrentLocationApps(false)
        setcurrentLocationMessages(false)
        setcurrentLocationFaq(true)
        break;
      case '/messages':
        setcurrentLocationHome(false)
        setcurrentLocationApps(false)
        setcurrentLocationMessages(false)
        setcurrentLocationFaq(false)
        setcurrentLocationmessages(true)
        break;
    
      default:
        setcurrentLocationHome(false)
        setcurrentLocationApps(true)
        setcurrentLocationFaq(false)
        // setcurrentLocationFaq(false)
        break;
    }
   
  },[])

  
  return (
    <nav className="bottom-navbar rounded ">
    <ul className="m-2">
      <li>
       
          <Link  className="text-not " to="/homapage"> 
          {/* <i className="fa-light fa-house font-30"></i>  */}
          <FiHome size={'35px'} color={currentLocationHome?'#006298':''} title='home' />
          </Link>
       
      </li>
      <li>
       
          <Link  className="text-not" to="/apps">
          <FiCommand size={'35px'}color={currentLocationApps?'#006298':''}title='Apss'  />
            {/* <i className="fa-solid fa-shapes text-not font-30"></i> */}
         </Link>
       
      </li>
    
        <li>
        
            <Link   to="/messages">{currentLocationMessages&&'#006298'} <i className="fa-solid fa-comments text-not font-30"></i></Link>
        
        </li> 
      
      <li>
       
          <Link   className="text-not" to="/NotificationGeo">
             {/* <i className="fa-solid fa-circle-question text-not font-30">            </i> */}
             {/* <FiBookOpen size={'35px'} color={currentLocationFaq?'#006298':''} /> */}
             <LuBellRing size={'40px'} color={'red'} />
            </Link>
       
      </li>
      <li>
       
         <ModalCloseSesion  />
       
      </li>
     
      {/* Agrega más elementos de navegación según sea necesario */}
    </ul>
  </nav>
  );
};

const ModalCloseSesion=()=>{
  const navigate = useNavigate ();
  const [open,setIsOpen]=useState(false)
  const logout=async() => {
    const response = await request.post('logout')
    if (response && response.statusCode === 200) {
      sessionStorage.clear();
   
      navigate('/');
    }
  }
  const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "-35%",
        width: "83%",
        height: "21%",
        transform: "translate(-50%, -50%)",
      },
}
  return(
    <>
    
    <span  className=" p-1" onClick={e=>setIsOpen(true)}>
       {/* <i class="fa-solid fa-power-off text-not font-30"></i> */}
       <FiLogIn size={'35px'} color='#000000' />
    </span>
    <Modal isOpen={open} style={customStyles} shouldCloseOnEsc={true} onRequestClose={e=>setIsOpen(false)} shouldCloseOnOverlayClick={true} >
    <div className="text-center">
    <h2>¿Cerrar Sesión?</h2>
    </div>
    <div className="d-flex input-group-sm justify-content-center">
      <button className="btn btn-sm btn-not" onClick={logout}>Si,Cerrar Sesion</button>
      <button className="btn ml-2 btn-sm btn-outline-secondary" onClick={e=>setIsOpen(false)}>Cancelar</button>
    </div>

    </Modal>
    </>
  )
}

