import React, { useState } from 'react';
import { LayoutMovile } from "../Layouts/LayoutMovile";

function Pruebas() {

    return ( 
    <LayoutMovile>



    </LayoutMovile> );
}

export { Pruebas };



  