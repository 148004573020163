import moment from "moment-timezone";

export const checkDays=(dateOne='2024-03-25',dateTwo='2024-03-31') => {
  
  
   
    let fechaInicio = new Date(dateOne);
    let fechaFin = new Date(dateTwo);
     fechaFin.setDate(fechaFin.getDate() + 1)
   
     let fecha = new Date(fechaInicio)
     fecha.setDate(fecha.getDate() + 1)
    const finesDeSemana = [];

    // Iterar sobre cada día
    for (fecha; fecha <= fechaFin; fecha.setDate(fecha.getDate() + 1)) {
        
        
        if (fecha.getDay() === 0 || fecha.getDay() === 6) {
            finesDeSemana.push(moment(new Date(fecha)).format('YYYY-MM-DD'));
        }
    }

    return finesDeSemana; 
}

