
export const sustractDays = (fecha,type='resta',days=1) => {
    
    let fechaObj = new Date(fecha);
    if (type=='suma') {
        
        fechaObj.setDate(fechaObj.getDate() + days);
    } else {
        
        fechaObj.setDate(fechaObj.getDate() - days);
    }
    
    let nuevaFecha = fechaObj.toISOString().split('T')[0];
    return nuevaFecha;
}
