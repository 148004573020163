import React,{useEffect,useState} from 'react'

export const UserInfo = ({user}) => {
 

  return (
    <>
 
   
    
    
    <div className="d-flex ">
    <div class="col-sm-3">
       <div class="avatar avatar-xl position-relative" data-bs-toggle="modal" data-bs-target={`#UserPhotoModal`} >
         <img
           src={user?.img}
           alt="profile_image"
           class=" border-radius-lg mt-1 shadow-sm"
           style={{width:'80px',height:'90px',}}
         />
         {/* <span><i className='fa fa-edit font-15 '> </i></span> */}
       </div>
     </div>
     <div class="col-sm-9">
       <div class="h-100 ml-2"  >
         <h1 class="mb-1 text-xs text-white font-15 text-upercase">{user.name} {user.lastName}</h1>
         <p class="mb-0 font-weight-bold text-white text-upercase text-xs"> {user.puesto}</p>
       </div>
     </div>
    
    </div>
    </>
  )
}
