import React, { useEffect, useState } from 'react'
import { Layout } from '../../Layouts/Layout'
import Request from '../../../utils/http';
import { TableInfinityFixed } from '../../../components/TableInfinityFixed';
import { TabsPuestos } from './TabsPuestos';
const request = new Request()



const header=[
  {label:'#',classColumn:'text-xs'},
  {label:'Departamento',classColumn:'text-xs'},
  {label:'Puestos',classColumn:'text-xs'}
]

export const Puestos = () => {
  const [showDetails,setShowDetails]=useState(false)
  const [loading,setisloading]=useState(false)
    const [data,setData] = useState([])
    const [colaborators,setColaborators]=useState([])
    const [dataDetails,setDataDetails]=useState(false)
    const [idx,setIdx]=useState(null)
    useEffect(() => {
      getInfo()
    },[])
    const getInfo = async () => {
      setisloading(true)
      try {
        const response = await request.get("information/departments/personal", []);

        if (response && response.result.success === true) {
          setData(response && response.result.datos.Department);
          let personas=[]
          response.result.datos.personal.forEach((item)=>{
            personas.push({
              label:`${item.name} ${item.lastName}`,
              value:item.id,
              active:item.active,
              edit:false,
              Sueldo:0,  
              Posicion: 0,
              comments:''
            })
          })
          setColaborators(personas)
        }
      } catch (error) {
        console.log("getData -> error", error);
      }
      setisloading(false)
    };

    const reload=async() => {
      const response = await request.get("information/departments/personal", []);
      if (response && response.result.success === true) {
        setData(response && response.result.datos.Department);
        setDataDetails(response.result.datos.Department[idx])
      }
    }
    
    const handleDetails=(dataParam,i)=>{
      setShowDetails(true)
      setDataDetails(dataParam)
      setIdx(i)
    }
    const closeDetails=()=>{
      setShowDetails(false)
      setDataDetails(false)
    }
  return (
    <Layout title='Gestión de Puestos de trabajo'>
     
        { !showDetails ? <DepartmentTable data={data} handleDetails={handleDetails} /> : <TabsPuestos colaborators={colaborators} puestos={dataDetails} closeDetails={closeDetails} reload={reload}/> }
    </Layout>
  )
}
const DepartmentTable=({data,handleDetails})=>{
  return(
    <div className="p-3 card puestoscontainer">
      <TableInfinityFixed columnsParam={header} aditionalClass='table-striped clickeable' >
        {data.map((puesto,i)=>{
          const {description,groups}=puesto
          return(
            <tr key={i} onClick={e=>handleDetails(puesto,i)}>
              <td><h6 className='text-xs'>{i+1}</h6></td>
              <td><h6 className='text-xs'>{description}</h6></td>
              <td><h6 className='text-xs'>{groups.length}</h6></td>
            </tr>
          )
        })}
      </TableInfinityFixed>
    </div>
  )
}
