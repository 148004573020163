import React, { useEffect, useState } from "react";
import { Layout } from "../../Layouts/Layout";
import { AsistenceTable } from "../AsistenceTable";


export const CheckingPersonal = () => {


  return (
    <Layout>
      <AsistenceTable />    
    </Layout>
  );
};
