import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Request from '../../../../utils/http'
import Select from 'react-select';
import { db, storage } from '../../../../firebaseConfig';
import { collection, addDoc, doc, updateDoc, serverTimestamp, arrayUnion } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { ToastAlert } from "../../../../components/ToastAlert";
import { SpeedDial } from 'primereact/speeddial';
import AddEncuesta from "./AddEncuesta";
const request = new Request();
export const AddPost = ({useData,userName}) => {
 
  const [isOpen, setIsOpen] = useState(false);
  const [optionsView,setDeps] = useState([])
  const [files, setFiles] = useState([]);
  const [caption, setCaption] = useState('')
  const [visibility, setVisibility] = useState([])
  const [toastAlertProps,setToastAlert]=useState({text:'',type:''})
  const [loading,setLoading]=useState(false)
  const [addEncuesta,setAddEncuesta]=useState(false)

  const items = [
    {
        label: 'publicacion',
        icon: 'fa-brands fa-instagram font-25 text-white',
        command: () => setIsOpen(true)
        
    },
    {
      label: 'Add',
      icon: 'fa-solid fa-square-poll-horizontal font-25 text-white',
      command: () => setAddEncuesta(true)
  },

];



  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleRemoveFile = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter(file => file !== fileToRemove));
  };
  const handleDropzoneClick = () => {
    document.getElementById('file-input').click();
  };
  useEffect(()=>{
    getDepts()
  },[])

  const getDepts = async() =>{
    const response = await request.get("information/departments", []);
    if (response && response.statusCode === 200) {
      let deps=[
        {label:'Todos',value:'0'},
        {label:'Mi departamento',value:'0'},
      ]
      response.result.datos.Department.forEach(element => {
          deps.push({ label:element.description,value:element.iddepartment })
      });
      setDeps(deps)
    }
    
  }

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault();
    if (files.length === 0 || !caption) {
      alert("Completa los campos");
      return;
    }

    try {
      const imageUrls = [];
       for (const file of files) {
         // Subir la imagen a Firebase Storage
         const storageRef = ref(storage, `posts/${useData.id_personal}/${file.name}`);
         await uploadBytes(storageRef, file);
         const imageUrl = await getDownloadURL(storageRef);
         imageUrls.push(imageUrl);
       }

       // Crear una nueva publicación en la colección "posts"
       const postRef = await addDoc(collection(db, 'posts'), {
         userId: useData.id_personal,
         postedBy: useData.personalData.name + ' ' + useData.personalData.lastName,
         imageUrls: imageUrls, // Array de URLs de las imágenes
         caption: caption,
         timestamp: serverTimestamp(),
         visibility:visibility,
         likes: {
             likeNumber:0,
             usersIds:[],
             comments: [],
           }
        
      });
  


      // Limpiar los campos del formulario
      setFiles([]);
      setCaption('');
      let data = {
        title:useData.personalData.name + ' '  + ' a creado una nueva publicacion en sil83.com',
        body:caption
      }
      const response = await request.post('send-notification',data)
      console.log(response);
    } catch (e) {
      console.error("Error agregando la publicación: ", e);
    }
    setLoading(false)
    setToastAlert({text:'Guardado con exito',type:'success'},)
  };

  const handleClose=() => {
     setIsOpen(false)
     setFiles([]);
     setCaption('');
  }

  return (
    <>
    <ToastAlert {...{toastAlertProps}} />
    <AddEncuesta {...{addEncuesta,setAddEncuesta,useData,setToastAlert}} />
      {/* <button className="btn btn-primary floating-btn-publications" onClick={(e) => setIsOpen(true)}  >
        <i className="fa-solid fa-plus font-25"></i>
      </button> */}
    <div className="floating-btn-publications">
      <SpeedDial buttonClassName="btn-rounded" model={items} direction="left" style={{ top: 'calc(50% - 2rem)', right: 0 }} />
    </div>
               
      <Modal isOpen={isOpen} onRequestClose={e=>setIsOpen(false)} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true}>
        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-lg-9 col-12 mx-auto">
              <div className="card card-body mt-4">
                <h6 className="mb-0">Nueva publicacion</h6>
               
                <hr className="horizontal dark my-3" />
              
                {/* <input type="text" placeholder="  Titulo de la publicacion" value={titlePublication} onChange={e=>setTitlePublication(e.target.value)} className="form-control" id="projectName" /> */}
                <textarea className="form-control mt-1" placeholder="Descripción de la publicación" value={caption} onChange={e=>setCaption(e.target.value)}></textarea>
                <div className="row ">
                  <div className="col-12 col-md-6">
                    <div className="form-group">
                      <label>¿Quién puede ver la publicación?</label>
                     <Select options={optionsView} value={visibility} onChange={value=>setVisibility(value)} isMulti={true}/>
                    
                    </div>
                  </div>
                </div>
             
           
                <label className="mt-1 form-label">Adjuntar Imagenes </label>
                <div className="dropzone-container" onClick={handleDropzoneClick}>
                  <input 
                    id="file-input"
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    className="file-input"
                  />
                  <div className="d-flex preview-container">
                    {files.map((file, index) => (
                      <div key={index} className="preview-item">
                        <img 
                          src={URL.createObjectURL(file)}
                          alt={`preview ${index}`}
                          className="preview-image"
                        />
                        <button onClick={() => handleRemoveFile(file)} className="remove-button">
                          &times;
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-4">
                  <button type="button" name="button" className="btn btn-light m-0" onClick={handleClose}>
                    Cancelar
                  </button>
                  <button type="button" disabled={loading} name="button" onClick={(e) => handleSubmit(e)}className="btn bg-gradient-dark m-0 ms-2"> 
                  <i className={loading?'fa fa-spinner font-20 fa-spin':'fa fa-upload'}></i> {loading?' Publicando ...':'Publicar'} </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};